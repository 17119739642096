export const Industries = {
	"01": "Crop and animal production, hunting and related service activities",
	"02": "Forestry and logging",
	"03": "Fishing and aquaculture",
	"05": "Mining of coal and lignite",
	"06": "Extraction of crude petroleum and natural gas",
	"07": "Mining of metal ores",
	"08": "Other mining and quarrying",
	"09": "Mining support service activities",
	10: "Manufacture of food products",
	11: "Manufacture of beverages",
	12: "Manufacture of tobacco products",
	13: "Manufacture of textiles",
	14: "Manufacture of wearing apparel",
	15: "Manufacture of leather and related products",
	16: "Manufacture of wood and of products of wood and cork, except furniture",
	17: "Manufacture of paper and paper products",
	18: "Printing and reproduction of recorded media",
	19: "Manufacture of coke and refined petroleum products",
	20: "Manufacture of chemicals and chemical products",
	21: "Manufacture of basic pharmaceutical products and pharmaceutical preparations",
	22: "Manufacture of rubber and plastic products",
	23: "Manufacture of other non-metallic mineral products",
	24: "Manufacture of basic metals",
	25: "Manufacture of fabricated metal products, except machinery and equipment",
	26: "Manufacture of computer, electronic and optical products",
	27: "Manufacture of electrical equipment",
	28: "Manufacture of machinery and equipment n.e.c.",
	29: "Manufacture of motor vehicles, trailers and semi-trailers",
	30: "Manufacture of other transport equipment",
	31: "Manufacture of furniture",
	32: "Other manufacturing",
	33: "Repair and installation of machinery and equipment",
	35: "Electricity, gas, steam and air conditioning supply",
	36: "Water collection, treatment and supply",
	37: "Sewerage",
	38: "Waste collection, treatment and disposal activities; materials recovery",
	39: "Remediation activities and other waste management services",
	41: "Construction of buildings",
	42: "Civil engineering",
	43: "Specialised construction activities",
	45: "Wholesale and retail trade and repair of motor vehicles and motorcycles",
	46: "Wholesale trade, except of motor vehicles and motorcycles",
	47: "Retail trade, except of motor vehicles and motorcycles",
	49: "Land transport and transport via pipelines",
	50: "Water transport",
	51: "Air transport",
	52: "Warehousing and support activities for transportation",
	53: "Postal and courier activities",
	55: "Accommodation",
	56: "Food and beverage service activities",
	58: "Publishing activities",
	59: "Motion picture, video and television programme production, sound recording and music publishing activities",
	60: "Programming and broadcasting activities",
	61: "Telecommunications",
	62: "Computer programming, consultancy and related activities",
	63: "Information service activities",
	64: "Financial service activities, except insurance and pension funding",
	65: "Insurance, reinsurance and pension funding, except compulsory social security",
	66: "Activities auxiliary to financial services and insurance activities",
	68: "Real estate activities",
	69: "Legal and accounting activities",
	70: "Activities of head offices; management consultancy activities",
	71: "Architectural and engineering activities; technical testing and analysis",
	72: "Scientific research and development",
	73: "Advertising and market research",
	74: "Other professional, scientific and technical activities",
	75: "Veterinary activities",
	77: "Rental and leasing activities",
	78: "Employment activities",
	79: "Travel agency, tour operator and other reservation service and related activities",
	80: "Security and investigation activities",
	81: "Services to buildings and landscape activities",
	82: "Office administrative, office support and other business support activities",
	84: "Public administration and defence; compulsory social security",
	85: "Education",
	86: "Human health activities",
	87: "Residential care activities",
	88: "Social work activities without accommodation",
	90: "Creative, arts and entertainment activities",
	91: "Libraries, archives, museums and other cultural activities",
	92: "Gambling and betting activities",
	93: "Sports activities and amusement and recreation activities",
	94: "Activities of membership organisations",
	95: "Repair of computers and personal and household goods",
	96: "Other personal service activities",
	97: "Activities of households as employers of domestic personnel",
	98: "Undifferentiated goods- and services-producing activities of private households for own use",
	99: "Activities of extraterritorial organisations and bodies",
};

export const Countries = {
	ARG: "Argentina",
	AUS: "Australia",
	AUT: "Austria",
	BEL: "Belgium",
	BGR: "Bulgaria",
	BRA: "Brazil",
	BRN: "Brunei Darussalam",
	KHM: "Cambodia",
	CAN: "Canada",
	CHL: "Chile",
	CHN: "China (People's Republic of)",
	TWN: "Chinese Taipei",
	COL: "Colombia",
	CRI: "Costa Rica",
	HRV: "Croatia",
	CYP: "Cyprus",
	CZE: "Czech Republic",
	DNK: "Denmark",
	EST: "Estonia",
	FIN: "Finland",
	FRA: "France",
	DEU: "Germany",
	GRC: "Greece",
	HKG: "Hong Kong, China",
	HUN: "Hungary",
	IDN: "Indonesia",
	IND: "India",
	IRL: "Ireland",
	ISL: "Iceland",
	ISR: "Israel",
	ITA: "Italy",
	JPN: "Japan",
	KAZ: "Kazakhstan",
	KOR: "Korea",
	LAO: "Lao People’s Democratic Rep.",
	LTU: "Lithuania",
	LUX: "Luxembourg",
	LVA: "Latvia",
	MAR: "Morocco",
	MEX: "Mexico",
	MLT: "Malta",
	MMR: "Myanmar",
	MYS: "Malaysia",
	NLD: "Netherlands",
	NOR: "Norway",
	NZL: "New Zealand",
	PER: "Peru",
	PHL: "Philippines",
	POL: "Poland",
	PRT: "Portugal",
	ROU: "Romania",
	RUS: "Russia",
	SAU: "Saudi Arabia",
	SGP: "Singapore",
	SVK: "Slovak Republic",
	SVN: "Slovenia",
	ZAF: "South Africa",
	ESP: "Spain",
	SWE: "Sweden",
	CHE: "Switzerland",
	THA: "Thailand",
	TUN: "Tunisia",
	TUR: "Turkey",
	GBR: "United Kingdom",
	USA: "United States",
	VNM: "Viet Nam",
};

export const Regions = {
	"-": "-- no region --",
	UKC: "North East (England)",
	UKD: "North West (England)",
	UKE: "Yorkshire and The Humber",
	UKF: "East Midlands (England)",
	UKG: "West Midlands (England)",
	UKH: "East of England",
	UKI: "London",
	UKJ: "South East (England)",
	UKK: "South West (England)",
	UKL: "Wales",
	UKM: "Scotland",
};

export const Multipliers = [
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1656058049110336,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.1069375272406663,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 2.2834051118196195,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1.0272800586734598,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 1.0779131213714714,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.0715003011504745,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 1.3778996694440817,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.1469679329675517,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.2822593685741401,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.1978516765369869,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.4231738328255723,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.1238174268667769,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.0582004301573766,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.1077605408592226,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.0925672598582645,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.1431646386032461,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.0721575353770403,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.0847568355660229,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.0864106219546397,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.263761599433271,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.070878556823328,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.0693750173956567,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.1059179347063157,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.0911854524551485,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.0860684457344505,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.061618246997584,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.2553919696614375,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.1105707565475273,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.2893345198032604,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.3492710093167617,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4600047486721155,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5224510398627795,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 4.135835506781865,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8060306963827926,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.722809908711095,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.434585830068888,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.601624952087138,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.309498372145751,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.1592646179713433,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2515436478214212,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.1545988203817965,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.1536686873369386,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5348414162602202,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.381783069074456,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3558756722434553,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.1934678471275952,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.1138573810876906,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.1128280118293155,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.4662823765052542,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.5645916438223448,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.9669303296438931,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.0693495728331817,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6025722563433404,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.20044819924522,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.2814987936901816,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3673055964694252,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.3507440349422741,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1200021410217333,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.560950655441393,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5020726632844559,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.318771065567286,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6574684515717073,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.0226363925537516,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.3861606891491673,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.278796843931504,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3234127370310191,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5697421446559525,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.2609620183261407,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.2889342997205033,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.396569796104963,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.2786382908719587,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.07000629756058,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.1781025659882673,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.1502480136285018,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.138651969990081,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.1508607556678438,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.3946566874368753,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.3359275223086893,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.2288734547191689,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.4275734467601449,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.1635611481258357,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2839086619760471,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.101006406449738,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKC",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.2502254634041827,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.388216021839723,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 9.434143775311824,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 2.582261172094608,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 2.2066788940997206,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.268824483391289,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 2.0854877408133436,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.1896940153223392,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.2138056730290612,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.1870837704475996,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.1571292835674918,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.2698092954655844,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.1079767265365283,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.1384165908021713,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.1402619135458698,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.0268508628801896,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.0971738673307418,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.141802073347222,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.1546985671066745,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.242270405932046,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.0688104360046986,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.1662428758270462,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.3402497743290613,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.2299430258426487,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.2541991301711362,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.1298785842175212,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.150576682772178,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.2336059727703843,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.1839856500614525,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.2809710336031492,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4873137919998953,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 4.603746281843017,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.9090203264601455,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.006785340028852,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.9623819597285683,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6021762259557084,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4746723250391938,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.736150494277796,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.2325193729173751,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2391178510607943,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.221753613164771,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.284495247419326,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.8599453010683364,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.463342733732009,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3933199651152361,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2499737472922035,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.2150157118432188,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.2456570961126554,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7773587840432135,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.705095047491154,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.2987543110134367,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1721191223531962,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5929544716930315,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.8245242885990076,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3733546067121412,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5536763518817995,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5824008956053541,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1939647452661797,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.3245173025641184,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5832090943277404,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5705638441479932,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.2486737018801395,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7344963114943786,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5888504335914422,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5609191078564248,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.5324745087059493,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.475573757267893,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.47273568727216,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.499367692354155,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.691376776473609,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.5324203873427673,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.1668014834988736,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3149661542205815,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2428403358978597,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2791196160994662,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.2936670092822649,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6659040582685236,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.657591824630188,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.47011596463515,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5100880711375306,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.1908357663662568,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2085785091166494,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.2190523154492774,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKD",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1608632703271962,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.2969190255175596,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 2.9592160297751766,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 1.6063057419042002,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.1572048594606898,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 2.8685394408396396,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.1149630884193653,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.2099131381313626,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.1874197728768703,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.5122678417640205,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 3.383526805609736,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.0759494135893302,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.1153807453027225,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.1525936051712495,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.01972278257032,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.1073160885939592,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.1543397237354953,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.1229077763724322,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.1223982578389144,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.0620404018020613,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.1178650022683723,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.2787544051991557,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.2043637715645332,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1005476014333024,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.123723138644513,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 2.037525512545728,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.0982508803473867,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.241508262789613,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.2197967980991695,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.450749011053911,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.9326656995630611,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.66525891752863,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.9768277330963249,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.0204391437932125,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.537647565621957,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5961329269275462,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4556251355395442,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.1991972025541482,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2066672419114564,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2335318156519188,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.2656444818310375,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 3.281234357471824,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5408687221261892,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3135598456200142,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2663629318828413,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.304278435621531,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.2372234486343607,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7549717102395317,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.913808947714788,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.8755296343603511,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.145477810263218,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.538614657249138,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 2.11424668709673,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3122942508355937,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.390227863098163,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4328162838794922,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.189470419059843,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.432322329345884,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.492479078619587,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5237192997245117,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.870713746042092,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.407226584258628,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.525228000030988,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.3391278714205586,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4094090171043552,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4307905802487133,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4453062035931203,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4224568293595536,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.5257609856229908,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4114117475443013,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.1420358983867445,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.25551538402193,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2370847169543198,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2392597885864853,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.2402007977396272,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 2.121830669109382,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.532722033764824,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.4072045956111254,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.3823574047339084,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.1918708741265511,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4130087328065573,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.2003515975010628,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKE",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1018763834055223,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.0872328471774968,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 2.919465336208704,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 2.8503305631634523,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.0762566641078881,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 3.1785259262491103,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.1128169844788114,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.5276247287855236,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.1689740947964782,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.1587056185695224,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.1443552835220594,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.0917474873675217,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.098164785891831,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.1876029593063886,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.0578902089328077,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.0964825659000423,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.1942676812917847,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.1005496434924584,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.1164015758964174,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.118094216469517,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.1026991286199233,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.1475944336689363,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.1227182209404258,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.0833103598232512,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.16530957210623,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.1126683506271482,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.227334165297606,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.3517897965011145,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.189936522365009,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4151202785012587,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8375948665758068,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.05668620141823,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8812851941142181,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 30.202176780847736,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5200161616060757,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4429318859265954,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.517484986089073,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.1763410234457843,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.1546088318307381,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2124484969183857,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.1734909119407126,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 2.002428258017276,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.2155021390637737,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.1682166508582805,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.2142388986447483,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.2246391414627564,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.554160965120683,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 2.0073037628218553,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.771076544841332,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.2940626172783947,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4040393108837679,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.3286463179589234,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5332188439146202,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.8395881757859849,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5014908540208927,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1324290293782668,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.7297205575182997,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.4149766585105095,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.367206304771952,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.4228403837720758,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6031468184621591,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.3868389370682688,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.4816024016297291,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3611420178025504,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.3733086754108565,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.370122768463785,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6061905545061181,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7038712776971396,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6051961350516566,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.1606965834570437,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2400125175002472,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2237599004113402,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2294260626701083,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.206736010522451,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 2.7936488816493967,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5104574300411997,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.4514603736904352,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.380152461846583,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.2307818380174862,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.1072435948998507,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.1670300031666299,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKF",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1166274291927083,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.281188782048904,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 13.956947204988499,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 7.571749935596045,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.203278914573134,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 3.524786611511712,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.2361951073130688,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.1469449296590122,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.228202503931918,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7920998854772983,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.2211607171904757,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.142307969723002,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.2175741718864335,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.1404266029048524,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.0345936444791597,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.1716407144595706,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 2.3620952426904704,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.1263560696780688,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.1075582241606483,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.0644113123125691,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.0896237838502394,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.1751510265493041,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.167044701978531,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1284774838419007,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.0910945292097647,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4152652865236872,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.285240090487893,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.261017408049049,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.3139248622024753,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4386630351116747,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8708102575021701,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.1765137756907262,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.063099226334273,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 4.820906937635928,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5253125723416898,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5934424294220035,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.577137033906951,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.189900642350449,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.190122638274002,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2510143093742139,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.2202698072427527,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 2.0110733334751343,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3231017084229642,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.1941751179145486,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.4226276807632767,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.2303096861168699,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6670133136689955,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.5648099196927816,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6924846398602487,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1686515452982982,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5753655186710616,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 2.160960193172703,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4342379655122663,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.2990725176337454,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5530891915397016,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1785311130266405,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5704358841530437,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5311149124136594,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.4891044912488898,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.1704479882369583,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.4122619178589426,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6946560812052907,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.3289563535312015,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4215311342390426,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4800868024509517,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.559410518753805,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5707595498325952,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3739178958087688,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4822550289507186,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.1825556026822452,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2753336369526944,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2429183029431123,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2340491499290607,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.2483094120703189,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7212556016642664,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6123577062643895,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.3691752389547522,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5964541379595167,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.1517051903860533,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.0955509935917407,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.14206220804458,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKG",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1426147171289753,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.1335100855914957,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 3.0271085731036833,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 1.5372717901802049,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.3216388897199307,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 3.3084337386098235,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.479224404747396,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.541731286111036,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.2210970169432644,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.5554734209196757,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 3.0781366384037643,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.5625102767342942,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.1506731436008855,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.1890425218107128,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.1271720270285044,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.1220372395641096,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.1852369381128067,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.1930059595282558,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.193853123261899,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.1728799152745113,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.324624222243995,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.1893444511518791,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.156043401855796,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.3287875127090985,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1546698983093648,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.3182477432583997,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.3438571578238363,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.3243945261751324,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.25549911409685,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.24958695160346,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.685370079157387,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 2.0025759398950864,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.2472174386806887,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.293829665376499,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.112307387224523,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5730084868383436,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5950000567529425,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.486468669164405,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.2247200070682713,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.260209065454901,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2694675679462857,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.2941798307244816,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5172622409196963,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.3657948732990355,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3877952095779784,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3031781277307695,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.4260843618836514,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.4786951456461552,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.2633981548858035,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.5933290793725767,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 2.5867448756351594,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1980627827026433,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5451783632285434,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.886378685624457,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 2.313266484973602,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3365954080264442,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.430336626014835,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2515462024633572,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8415114966250006,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5553525363867409,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.4622971777071625,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.3059701836447324,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8003384399162088,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.526702882404094,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5554000123674037,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6247053020184734,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.410493739848758,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.691585231912983,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5664974449221092,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3935193976362084,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7685545871044566,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.2556450072219596,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3630527411521256,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3431304936654516,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2714551898105624,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3133523230991329,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7614259839931738,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6474613678936258,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6515176195859302,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6758796338455004,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.2345461208779216,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.169318641759978,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.2192932327836692,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKH",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 12.464527899501624,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 3.047159433188177,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 64.0748536923862,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 1.2872932329222049,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 2.379695169988899,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 2.7268921879899084,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.4287002564168347,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.3630788658131319,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 5.141783557468473,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.565052979136861,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6536383238593122,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.2745330970062079,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.4747396957555425,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.262675987418205,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 18.626835008891767,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 2.4834512887017817,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 2.822033542982333,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 2.2462351885347993,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 2.2982526838381934,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.853389830479329,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 2.077157518759348,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.950108100976706,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 2.449663505516455,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 2.2828188073070232,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 4.656981251388091,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 2.174502223718113,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 2.501250911828021,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5672948691994117,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.8671772732688052,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 13.651518156071315,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.9905369448461796,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.647440459101552,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 15.716507573200726,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6469777669287275,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 2.0461920664556117,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.9682764630063923,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 2.0518960191805298,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4545231633349018,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.42592761160775,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.2984248657950757,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.8147526294164549,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.199548908622064,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.9476331687151958,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4709223152716098,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.3081838357668314,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.258364884719717,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.3242932629758188,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.294292835401155,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.2784091909772042,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.2902412747475454,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5874578514837296,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.3942626297059626,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3284606570065223,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5384102599972802,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.3321844234399305,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2189024831686255,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5588846658811573,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5440699025251134,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7920020042284552,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.9296147209478047,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.4224988375363616,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6498031267306277,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 3.360875059292129,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 2.0163539939496657,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7545622976701454,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.610754821987204,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5862838821094079,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7654534096555197,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6756195054616212,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.239934577051917,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.518715441113702,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3941891852618835,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6635620965966311,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.383873986972886,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.451575986360912,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.894605723843882,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7447517700887858,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 2.1008505140373535,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.213304043239842,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2974338813307016,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.3244333087387732,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKI",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1534968579113587,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.1109669404384397,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 2.5218316570817674,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 1.595704756680643,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.3152983361161594,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 4.761344292100826,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6269003989861635,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.4933197890795296,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.882818638664559,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6615274270627798,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.5385238985829317,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.2201777496719406,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.2956217474665876,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.168924369724687,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.0272971101104142,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.1903061111071678,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.2260892740613016,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.3413007873719849,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.249926356945598,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5947255332446635,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.156904264771027,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.11916052257545,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.2123551538380288,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1921880609616091,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.1953877141039433,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.452708189892789,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.3511350319109035,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.2108631607067544,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.291941102283476,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5848053523920904,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 2.532444569376573,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.0987878542011895,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.4594814202116644,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.7934805622155627,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6638742461628104,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5620816681336356,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.628846161039835,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.2845832492418277,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2759329045855854,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.307644311862032,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4195998163134538,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.1929310397471609,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.3553852187192903,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4783490297991109,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2928857332447878,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.3163688447756294,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.2194460227924053,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.3403625114400712,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.3648580688869043,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 2.6385257540987856,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1447105400835922,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.390268130234952,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.9977168463378665,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.762409142598944,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.4303654029714437,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4123056997510302,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2326439627745347,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.9094518117409378,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5824740596568538,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6605925985144971,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.4112250348056905,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.620864984020697,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5073057291739755,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5533285171065698,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6106299999969442,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6488525837422523,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.555752898985552,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.8666734057203644,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6195037373597296,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6219227788056754,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.292308121915379,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3159011034162809,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.34158259995248,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2709621237947193,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3572868927339174,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5936563547971072,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.794687900076942,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 2.1587510943498938,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.4743705610485482,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.238343227121457,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2012121528881745,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.188112029924053,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKJ",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.1062264430543944,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.1455129767765384,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 1.8025145596577428,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 2.300528425271911,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.1203063218332945,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 2.556241983474274,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.268716089536652,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.1909090039972752,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.9810244897612037,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.9516423844542659,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.4428889133286917,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.1080258178252684,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.1710999296737354,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.162599591723225,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.1497712746112414,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.172654243709657,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.1223969023370528,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.2764160796851352,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.3594958076607588,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.1322820912315004,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.1250295695658805,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.164921366619728,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1339708039577152,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.150486213624849,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.1185606560611276,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.4425954283525144,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.3245322842532126,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.3267412159824998,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4889705952146093,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 2.5529324222675154,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 3.456275540815632,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 3.4059295798795928,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.2353274857693863,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.536709816275707,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6653760401135695,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.470243501721857,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.2053291160895137,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2692697413665148,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.240863381122939,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.2674914011162113,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.248809695028255,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5561907999320297,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5024189733880824,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.247715617211168,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.14650669523164,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.198404778458792,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.3651512559111905,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.4380830825166422,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.535029650395237,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1904173773333622,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4389851692473192,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.411127166778423,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4062885352009846,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3689321638659608,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5000810700826945,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1422564334051033,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.4779675631251212,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.655975203667796,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5192186858353014,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.522077840799023,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6854427025360006,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.4494315172638736,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.3492791363259293,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4518119619311378,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5694093128075808,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6992754043908662,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4606264997540537,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.57933671635825,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4512686422271295,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.195825270323199,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2907478776999606,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2622023689959732,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2255425058853513,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.309469621812676,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5847068025767648,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.3706573561106696,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.985957305016061,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6195242633826787,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.2150866982655402,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5906398625298757,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.1939717064548525,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKK",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.071465944565856,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.111091554313359,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 2.3559095379084276,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1.0430942673087547,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 2.4267897234592253,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.0942491795178662,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 1.6129339961519849,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.137782157203513,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.137029783745304,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.3029145718551944,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6269064746883208,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.1384332813328097,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.0713880921198315,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.0843875456755818,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.1362879771209373,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.16944405442859,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.0848738210185425,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.1388430751694734,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.0922941181937569,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.118483807189325,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.065292319398364,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.1055477007668506,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.0822537382047053,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.1504762656927565,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1985049919599748,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.0804627485848013,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.1270353146946521,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.2016085346520022,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.1460195804820594,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.1682868268725606,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6736926045327274,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6123751998108231,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6402234709912644,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8726008992440624,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 5.816124993528652,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.4942090513075488,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.3353510362718937,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.404257552290493,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.1593860897727093,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2751038283263068,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.1693945246843291,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.1906185785318866,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.3203356166745441,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 2.567972768349835,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5406691642100343,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2033400593126224,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.0991538741395457,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.1587206388681104,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.8338587243651858,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.4019954045006067,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.183488014663191,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1204808699699444,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.682294964747032,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.4185835404041347,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3994274689847523,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.1302127681088485,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.588813375442545,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1650641938765354,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.4295270659322072,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.408021299773064,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.399629963107233,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7406811201731645,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.805688183135916,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.546297704093973,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.2577260648418958,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.351295871170258,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.3589167318748034,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4729306045068884,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4537999164793927,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4534968294583765,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.3378553626254535,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.0680843265968971,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2005019000306403,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.178025402707347,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.1461354562118387,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.1475808243753323,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.3349523238986152,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.3308883567968093,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.3857259436547216,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.3278967085850522,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.1421283225361938,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4300174743399436,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.1420719198895588,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKL",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "01",
		MULT: 1.098984986220935,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "02",
		MULT: 1.0820743895180411,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "03",
		MULT: 2.062576878542404,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "05",
		MULT: 1.1637215640200669,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "06",
		MULT: 1.0499930859546427,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "07",
		MULT: 1,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "08",
		MULT: 1.1924375250296004,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: "09",
		MULT: 1.2724667337277566,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.2318713350377635,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.122122149960689,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.2082419288916029,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.2619059391693541,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.3829642016054369,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.090157751078873,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.123773007990076,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.3203489959089425,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.2255600040808676,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.181304250897154,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.1998550238906294,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.2344190720387849,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.2974762435971292,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.2978586639063088,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.133715444631864,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.1356121086811706,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.3166994963353458,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.1683814748838561,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.3856381909480633,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.230246604186082,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.7369055291445998,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.2708262014667677,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.1786168810600473,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.723089884851949,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.9599567385323207,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.1941485988926184,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.0801253841292993,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.148819026765215,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.4883801009431568,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4893340192500963,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.532379114164705,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.238494720371488,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.3225980791936789,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.224851420381907,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.254254970031543,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.1724693205507117,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.587189282147762,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4599457476858955,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2549930248568049,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.1789595439481708,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.2702829574408057,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.488781017674515,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.443900449171673,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.905297926216752,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.1299490234098184,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.496004008553288,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.4329182719098201,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.307314549664845,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3168632885111442,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.423918896724496,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2150173502074764,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6221970493065303,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8189732351913954,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.3556647693640336,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.3611123761174202,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.089828128065811,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6084595748246584,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.3899349907976184,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3986402489095633,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.750184006615671,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.834227663534318,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4792493817291072,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3756099401921473,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.477332381115938,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.1099641572705363,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2928926313644284,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2101431058874603,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2261615231195442,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.1817175831326376,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6377644132946478,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.3144960315400127,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.4582905067770289,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5660643016386364,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.2313506339444373,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.201965647691817,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.1846272377405278,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "UKM",
		YEAR: 2018,
		INDUSTRY: 99,
		MULT: 1,
	},

	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.4314351122357287,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.4314351122357287,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.3907827044854908,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.3746468844023374,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3746468844023376,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.3621667673979243,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.3621667673979243,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.670258210964411,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6436419073057102,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6436419073057098,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6436419073057105,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6310934411142075,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6310934411142075,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6310934411142075,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.4964617827728746,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5764503887180612,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.576450388718062,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.606912036740707,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.56521730906523,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6523319029747898,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.501574126542793,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.465648657873275,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.4149179422571496,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4516513444818353,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5923113040289572,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.4442610431025205,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5083753958464472,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5362305877921336,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.474674527247316,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.4542874207796963,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.454287420779696,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.4542874207796967,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4517446181426534,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.531575131646881,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5315751316468809,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.531575131646881,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5315751316468809,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.4808122864037343,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4808122864037334,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4808122864037336,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4863499639219864,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4863499639219862,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.486349963921986,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5714146979829626,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6556314778448415,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6888387793281374,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.62755593186393,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6208267629635174,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.707459548705804,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7074595487058033,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7871974090478244,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7871974090478244,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.787197409047824,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7336251135260945,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.8574023654464968,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.8574023654464973,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.611177508280222,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.611177508280222,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6111775082802222,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2096178311357997,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.685594081710497,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.685594081710497,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6855940817104973,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.685594081710497,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6855940817104973,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6855940817104962,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6855940817104966,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4093386286404506,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.40933862864045,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.40933862864045,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4093386286404503,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.40933862864045,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.40933862864045,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4196277236737116,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3160882852708935,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.507656839146924,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.507656839146924,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5076568391469245,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.820754162250125,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.820754162250125,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.820754162250125,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.8207541622501249,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5728052426673416,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5728052426673416,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5728052426673416,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ARG",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.781044532178609,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.7810445321786086,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7697836343435245,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5138600646047795,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.5138600646047793,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.616500290310484,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6165002903104848,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 2.0425729814963165,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8657105303975805,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.86571053039758,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.86571053039758,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7847929845633772,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.784792984563377,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7847929845633772,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.7890837690142811,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.870141539617041,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.8701415396170409,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.736984704421743,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7054461209398393,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.841765051427409,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.8010518412776066,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.791023834266594,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.7469610331789125,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.7002108369856137,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.653874481675071,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.7870223762196964,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.788505886900866,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7088256390740977,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.818295997109172,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.7814801911830451,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.7814801911830451,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.7814801911830451,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.595753938770818,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.841855418622816,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.841855418622816,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.841855418622817,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8418554186228169,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.8732901874816856,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8732901874816863,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.8732901874816863,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.712694729180123,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.712694729180123,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.7126947291801229,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.6932889336699306,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6934849719312308,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.82676867910848,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.8126182368761337,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7118279895780817,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7485642258842458,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.748564225884245,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.792526658994301,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.792526658994302,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.792526658994302,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.8066466911553385,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.8752787171505167,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.8752787171505167,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.648764104784517,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6487641047845178,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6487641047845176,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5651049113150894,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.9483954548629872,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.948395454862988,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.9483954548629872,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.948395454862988,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.9483954548629876,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.948395454862988,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.9483954548629867,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.882517897475616,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8825178974756165,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.882517897475616,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.882517897475616,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.8825178974756165,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.882517897475616,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6366030078532987,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.5442730402164233,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5607985494437402,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5607985494437406,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5607985494437404,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.719231677762652,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.719231677762652,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7192316777626515,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7192316777626515,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5518874371738751,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5518874371738751,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5518874371738751,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "AUS",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6219622553212,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6219622553212,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.759920857856694,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.471268431778109,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4712684317781102,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6005574525598405,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6005574525598405,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.559516456148952,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8247551701431548,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8247551701431557,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8247551701431552,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7596572853089198,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.75965728530892,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7596572853089194,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6773652470518734,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7324396741642252,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7324396741642238,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.71231872231184,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.69564802311646,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6405341326418517,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6316725568962989,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.643745351332353,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6578413625773116,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.598499715689108,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.682157977672586,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5519317841749998,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6103657291377187,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.616699162579277,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5974958004567683,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.643600833919856,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.643600833919857,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6436008339198565,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5647819791604634,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8785043632187652,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8785043632187652,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.878504363218765,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8785043632187648,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.741634414840985,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7416344148409857,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.741634414840986,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6736787616953703,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6736787616953697,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.67367876169537,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.572737734679878,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.8504541379316064,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.672657744689125,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5839045481542269,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5134697109222073,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.568461266790561,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5684612667905615,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.8128443387378128,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.812844338737814,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.8128443387378137,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6225085340819203,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6726086410187535,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6726086410187535,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.8159643977556852,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.8159643977556856,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.8159643977556856,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5580652394209755,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8868106786748,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8868106786748,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8868106786747991,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8868106786748,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8868106786748,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8868106786748,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8868106786747991,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6145389914785722,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6145389914785733,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6145389914785726,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.614538991478573,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6145389914785724,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6145389914785724,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5002884960710323,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2894354066797853,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.477797675004094,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4777976750040935,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.477797675004094,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.588857180878178,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5888571808781782,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.5888571808781775,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.588857180878178,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5712056128161056,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5712056128161058,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5712056128161058,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "AUT",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.9174128457118416,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.917412845711842,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.668460997173676,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.8974456102416088,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.8974456102416093,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.995237985749704,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.9657703672595768,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.9657703672595768,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.9657703672595763,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.860427755833896,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8604277558338955,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8604277558338955,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.805628032964576,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.821371904985957,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.8213719049859567,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7127007678896395,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.741703755820251,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 2.142138039456364,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.7570488036984662,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.7672876737886667,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.8274282791330143,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.884214628784542,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.749658482242739,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.7304299955667215,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.7342551355266635,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7491603590206612,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.8372104051464624,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.7912900723887593,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.791290072388759,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.7912900723887597,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.712725762997835,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 2.037887082753291,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 2.037887082753292,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 2.037887082753291,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 2.037887082753292,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.891421034256699,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8914210342566984,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.891421034256699,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.8741106301833472,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.874110630183348,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.8741106301833477,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.679260489760484,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7353921400699597,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.8103140031572185,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.8401172087923208,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7428432376567418,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.8788468577006308,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.8788468577006308,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.9404275074503925,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.940427507450392,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.9404275074503912,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6408098169066752,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.830111618922234,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.8301116189222344,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.9068323688326323,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.9068323688326323,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.9068323688326332,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4860472144859516,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 2.0482141685158397,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 2.0482141685158384,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 2.0482141685158384,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.0482141685158384,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.048214168515839,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 2.048214168515839,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 2.048214168515839,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.8554855292372103,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8554855292372108,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.8554855292372108,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.8554855292372103,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.8554855292372103,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.8554855292372103,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5440585245312486,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2915941318821955,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6765994870945884,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6765994870945884,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6765994870945882,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.9875979729827755,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.987597972982776,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.9875979729827764,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.987597972982776,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.932582480593295,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.9325824805932952,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.9325824805932947,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "BEL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6417378545084205,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.64173785450842,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.6165076438521664,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.317796313105437,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3177963131054369,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4715166594520972,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4715166594520968,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.9227596380934469,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.767310469239851,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.767310469239851,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7673104692398514,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.745088722285766,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.745088722285766,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.745088722285766,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6012044042291764,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.679859897007536,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.679859897007537,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.705468328351373,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.595561083834921,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.652865622458684,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5601853252101636,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5763411619866097,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.667057201618474,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5245026065682772,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.624474806942781,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.587208159674194,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5870314639137109,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6778231635771803,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6213233754773984,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5870738003242006,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5870738003242006,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5870738003242,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5362495091444726,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5324364649339397,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.53243646493394,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5324364649339393,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5324364649339397,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7964034123375725,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7964034123375725,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7964034123375736,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5468417540913917,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5468417540913912,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5468417540913917,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.556968785602292,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5506113744864471,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7017263049063232,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7201387568284725,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6149916994845543,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6422505053414305,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6422505053414296,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.5561290225172466,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.556129022517248,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.5561290225172473,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4836214230571747,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5897454891296012,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.589745489129601,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.517309928157074,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.517309928157074,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.517309928157074,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4340266637010923,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.9144543008667656,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.9144543008667647,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.9144543008667656,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.9144543008667652,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.914454300866766,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.9144543008667656,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.914454300866766,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.747918616029655,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7479186160296545,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7479186160296551,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7479186160296551,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7479186160296545,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.747918616029655,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.3741022371477507,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3266251681556904,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.623749595933374,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.623749595933374,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6237495959333734,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.9301665200636025,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.9301665200636031,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.9301665200636025,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.930166520063603,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.71287973854854,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.71287973854854,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.71287973854854,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "BGR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.4024382684469383,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.4024382684469383,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.1251967442450803,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5752928078699484,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.5752928078699484,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5108293557531298,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5108293557531307,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5580430306026345,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7279883376319125,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.727988337631912,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7279883376319125,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6551044491906906,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6551044491906903,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.65510444919069,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5288095721265726,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6123972725273406,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.612397272527341,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.621046907022909,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.643406768978554,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6882137875161,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5895390289459002,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.616687281915148,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6575214677405807,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5523754129727283,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.679607434232307,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6051250320610162,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6300853984300312,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6295545926291173,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5494559693586092,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5137337930737351,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5137337930737351,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5137337930737356,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4672282593587695,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.436643866709156,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.436643866709156,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.4366438667091568,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.4366438667091566,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.51305089418636,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5130508941863596,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5130508941863594,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4778604257319785,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4778604257319785,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4778604257319785,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4458088777283158,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5182465948151591,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6358313785683607,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.632901305861961,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5460922400115806,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5830673275388625,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5830673275388634,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7371252948143114,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.737125294814312,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.737125294814312,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7499351674113075,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.510874207929105,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5108742079291044,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5878549203737071,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5878549203737078,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5878549203737071,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1847434219596642,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.554101716171516,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.554101716171516,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.554101716171516,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.554101716171516,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5541017161715156,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5541017161715154,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5541017161715165,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4897069254134978,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4897069254134985,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4897069254134978,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4897069254134987,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4897069254134985,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4897069254134985,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.45352594283485,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.326059187994672,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5212199271463127,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5212199271463127,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5212199271463127,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6135530436719157,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6135530436719154,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6135530436719154,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6135530436719157,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.622255084845266,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.622255084845266,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.622255084845266,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "BRA",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6072299156560077,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.607229915656008,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.496364462725971,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.3578812838205667,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3578812838205667,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4910858610738,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4910858610738,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.4658671692279726,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7419176911196603,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7419176911196603,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.74191769111966,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.602485414663121,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.602485414663121,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6024854146631216,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5467231849888456,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5640732601562837,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5640732601562846,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5169177072422255,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5964219543036746,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4526270946068556,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5373841368165804,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6294649291123091,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5457011027189425,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.718379212892278,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5345323066294676,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5560517707897796,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5681407828031602,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5113577998722303,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6530538827506136,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6530538827506136,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6530538827506138,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5199774639325554,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.4456198521065546,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.4456198521065549,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.4456198521065542,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.4456198521065546,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7927575076810185,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7927575076810185,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7927575076810178,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.510069070175278,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5100690701752788,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5100690701752786,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.520671971000645,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.490937576883923,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5286222706809385,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4375094816962717,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5100247391386412,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7217245430504438,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.721724543050444,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.362878030777424,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.3628780307774244,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.3628780307774244,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.487541315701587,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5872545391237942,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.587254539123795,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3564526894783666,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3564526894783668,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.3564526894783664,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.317875585759477,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.728121178609584,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.7281211786095845,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7281211786095845,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7281211786095847,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7281211786095851,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7281211786095847,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7281211786095845,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.820022641326981,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8200226413269815,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.820022641326981,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.820022641326981,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.8200226413269815,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.8200226413269815,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.487903897625299,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.6174022871900782,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6361657472908475,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.636165747290848,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6361657472908475,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.813926268274364,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.813926268274364,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.813926268274364,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.813926268274364,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4282760723796817,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4282760723796817,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4282760723796817,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "BRN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6209808441976503,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6209808441976514,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4819952513304058,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5779233860859414,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.577923386085942,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4354483661812674,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4354483661812676,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5072159908165923,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7342492347226302,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7342492347226304,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7342492347226306,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6926404960035075,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6926404960035082,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6926404960035075,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6031049673322093,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6117731738249568,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.611773173824957,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6610779348041005,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6005496763879736,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.652440915682778,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5754218551688497,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.588823938137714,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6072409067611133,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5143305371474534,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5773893352563235,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5436034434876322,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.544051966743369,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5891052873288094,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5473767090407746,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5747640154593183,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5747640154593183,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5747640154593183,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.453215912490624,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5830611008208215,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5830611008208213,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5830611008208215,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5830611008208222,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6161872368009575,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6161872368009569,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.616187236800957,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5946480151006952,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5946480151006943,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5946480151006943,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5008398114517176,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4869113794230082,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.52460821956007,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.553616118686936,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4660260079760297,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6721004544679536,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6721004544679543,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7013825381900685,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7013825381900676,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7013825381900676,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.541514128385996,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5663252378558157,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5663252378558157,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.671825152086488,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.671825152086488,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6718251520864875,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4224141810868531,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6130604813164706,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6130604813164706,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6130604813164708,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6130604813164704,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6130604813164708,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6130604813164715,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6130604813164704,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.5852446058726362,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5852446058726366,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.5852446058726362,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5852446058726366,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.5852446058726373,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.5852446058726364,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6212498532419524,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.323972630911122,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4438644338869069,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4438644338869069,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4438644338869062,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6371913421085356,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6371913421085356,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6371913421085358,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6371913421085351,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5739065558113376,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.573906555811338,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.573906555811338,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CAN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.7152527676034652,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.715252767603465,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.237954374783666,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.8668842109308137,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.866884210930813,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.7762728926400968,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.7762728926400977,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.2484762472615047,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8500155216479603,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8500155216479608,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8500155216479608,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.8157149423428165,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8157149423428167,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.815714942342817,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5539405453669943,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7390297782338968,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.739029778233896,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.703306159517843,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.659056720558187,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.639960273115277,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.65924720686673,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.687649879136326,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6842841655372829,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5471530692833493,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6670632233046956,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.746239037796882,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.754028909964949,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7172044400837472,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7996744170431511,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.7809647260009962,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.7809647260009962,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.7809647260009964,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 2.0019464156516227,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.9562380162979291,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.956238016297928,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.956238016297929,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.9562380162979296,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7675083960236462,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.767508396023647,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7675083960236466,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6491885322497926,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6491885322497928,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6491885322497928,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.770719166207414,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.8150009185776537,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.835809566310467,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.9023730517979291,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.671193531905055,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7720332285619058,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7720332285619058,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.900288903728995,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.9002889037289936,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.9002889037289947,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5768180671462877,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6468916956414066,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.646891695641408,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.8398716786447904,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.8398716786447904,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.8398716786447904,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5804618653933449,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 2.0959340624678764,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 2.095934062467877,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 2.095934062467877,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.0959340624678764,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.0959340624678773,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 2.0959340624678764,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 2.095934062467877,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.88444497011368,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.88444497011368,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.88444497011368,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.88444497011368,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.88444497011368,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.88444497011368,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.562788962463631,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.633624532227544,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4453979400364678,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4453979400364676,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4453979400364676,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.8738567792862104,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.87385677928621,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.8738567792862104,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.8738567792862104,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.6505176765889853,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6505176765889855,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6505176765889855,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CHE",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5712380578087903,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5712380578087908,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7146671589855589,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.843095492673717,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.8430954926737173,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.586947742337495,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.586947742337495,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7693794352201526,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6983722568566535,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.698372256856653,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6983722568566533,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.8632394596315407,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8632394596315416,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8632394596315407,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.7186885410052806,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.694238414570431,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.694238414570431,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.8562577723377631,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7168512477366735,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.769376287513567,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6485117225174486,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.7380057084983231,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6201863285720763,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.617766304963554,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4877394208214567,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6877045561835502,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6629813057727725,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.711942706115421,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6100775312678337,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.610077531267833,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6100775312678333,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5864485143523213,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6012951207917596,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6012951207917596,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6012951207917598,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6012951207917585,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6188196557885837,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6188196557885837,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.618819655788583,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6615844296482347,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6615844296482347,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6615844296482345,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.61073197000669,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6102415474204403,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7752173523490828,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5313267201087224,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5017394672157487,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6034086625240684,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6034086625240682,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.82156143049082,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.8215614304908203,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.8215614304908203,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.8128214605870057,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5856241835613814,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5856241835613816,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.666187113156172,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6661871131561716,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6661871131561723,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3838805716609608,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6507972121244818,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6507972121244825,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6507972121244825,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6507972121244825,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6507972121244825,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6507972121244816,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.650797212124483,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4880243244447064,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4880243244447062,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4880243244447058,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.488024324444705,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4880243244447051,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4880243244447051,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4029277466325327,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3509812498014795,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4761599652389998,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4761599652390005,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4761599652390007,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.4758205962176465,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.4758205962176465,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.4758205962176467,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.475820596217647,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4484083354084478,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4484083354084474,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4484083354084478,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CHL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.602677987555685,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6026779875556845,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5845581008861331,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.617611889640075,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.6176118896400746,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5897112014808563,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5897112014808563,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5709341946995556,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7750241093560744,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7750241093560744,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.775024109356074,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.9618043213494711,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.961804321349472,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.9618043213494711,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.606239085005143,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.708192897754154,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7081928977541538,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6697240450620796,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.572095772348474,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6665052779399612,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5862986209760923,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5473452677645323,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5778083277932768,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.527713148602462,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5743473638630614,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.558633877496687,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5770685371102886,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.585117903928972,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5599768327106054,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6352128389905056,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6352128389905058,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6352128389905054,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.55781983594368,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.712009159234018,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.712009159234018,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.712009159234018,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.7120091592340188,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5894747492941592,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5894747492941588,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5894747492941583,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5788500301932584,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5788500301932584,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5788500301932586,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4967639035335665,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5124636043546815,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.653226564819548,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5386898336994577,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6026143886238668,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7201836040158112,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.72018360401581,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6557641377058,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6557641377057992,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.655764137705799,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.64554191180521,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4827711005107322,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.4827711005107316,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3074560904012047,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3074560904012047,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.307456090401205,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.375493619031548,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6841383278747064,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6841383278747062,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6841383278747062,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6841383278747066,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6841383278747055,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6841383278747066,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6841383278747055,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6810011336070316,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.681001133607031,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6810011336070314,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6810011336070314,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6810011336070312,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6810011336070318,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4263273851863114,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3198918019251578,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3523454328810391,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.352345432881039,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.352345432881039,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5476005760616554,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5476005760616558,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.5476005760616556,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5476005760616562,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.3835838659918496,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.38358386599185,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.38358386599185,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CHN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.4255012509460572,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.4255012509460572,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.427920895200672,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4371148242690468,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4371148242690477,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4105691427376794,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4105691427376796,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6390276243350217,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6679070184177065,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6679070184177065,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.667907018417707,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6282416415191932,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.628241641519193,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6282416415191925,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5618359238000843,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.674557994745027,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.674557994745028,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.52847418848789,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.585224454805534,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4772597940252297,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6116352961941005,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.3766439690121224,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6351239516510914,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5345170724513413,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5572167723125707,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5287289308555645,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6715427404728704,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5421363795866845,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5556461110617623,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.492303953489741,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.4923039534897418,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.492303953489741,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4771828257152386,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5907756491945024,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5907756491945029,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5907756491945018,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5907756491945018,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5574304142915207,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5574304142915198,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5574304142915203,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4959011125244766,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4959011125244763,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4959011125244763,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4143438562660924,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.636986267648219,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5163513009414264,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5333028541844635,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.607767811161484,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.560551097511581,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.56055109751158,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.746620351303237,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7466203513032377,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.746620351303237,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6363730980023417,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5624835991855988,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5624835991855983,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6580592256474511,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6580592256474511,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6580592256474511,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2532546066079842,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5843774902064962,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5843774902064969,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5843774902064964,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.5843774902064964,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5843774902064964,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5843774902064958,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5843774902064969,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4156502494776673,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4156502494776677,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4156502494776677,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4156502494776666,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.415650249477667,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.415650249477667,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6723170643461986,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.5025424850740725,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6455058832069238,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6455058832069245,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6455058832069238,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.555761609819726,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.555761609819726,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.555761609819726,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5557616098197264,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.538014460148072,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5380144601480723,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5380144601480723,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "COL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5991714109861912,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5991714109861914,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.6686442787427735,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.6251220527731869,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.625122052773186,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5458455802056243,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5458455802056252,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7547128133648344,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7547128133648342,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7547128133648338,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.664350075286142,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6643500752861424,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6643500752861424,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5537787713919218,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6824746868435998,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6824746868436002,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.614734811666826,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7346133677483504,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7051519082276163,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.718239453916497,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6339032888424043,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.614218809023626,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.508636045626683,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6272984346016983,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6640395000483703,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6589267213156789,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5215802341424578,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5934479951364573,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6857893741213796,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6857893741213792,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6857893741213796,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5177034680293564,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.561684579211856,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5616845792118563,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5616845792118568,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5616845792118563,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7441922422961211,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7441922422961216,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7441922422961216,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6233126668613531,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6233126668613531,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6233126668613538,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.518928821796254,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6474309744592268,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7189105915334013,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5879135105536222,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7231239018171258,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.722463578099301,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7224635780993014,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.9848081385870815,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.9848081385870835,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.9848081385870835,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.73749268345968,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4220121589343142,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.422012158934314,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6820959813433507,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6820959813433507,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.682095981343351,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4300655052151947,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6733597560311455,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6733597560311455,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6733597560311455,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6733597560311462,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6733597560311466,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6733597560311462,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6733597560311455,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.5512587360870511,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5512587360870498,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.5512587360870504,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.55125873608705,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.551258736087051,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.55125873608705,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.522923362312815,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3455925488825051,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4295602739225408,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4295602739225408,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4295602739225408,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.654045507603417,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.654045507603417,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6540455076034173,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.654045507603417,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.675816526655372,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6758165266553715,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6758165266553715,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CRI",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6262899854579391,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6262899854579398,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4798170001030837,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.7128892766634274,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.712889276663427,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6914839829016644,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6914839829016648,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.8414530646824716,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.759724017655371,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.75972401765537,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.759724017655371,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.72861975074503,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7286197507450305,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7286197507450305,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5650852776392885,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6485143196859484,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6485143196859484,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.289320619697447,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6335140214210393,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5306741511274984,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6450496514745083,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5440518309592015,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6500500387204324,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5966829634154716,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4399590943645393,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.521274880629493,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6124025304802914,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6466540987472174,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5504523141240685,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5236930042331722,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5236930042331724,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5236930042331729,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5843040526535426,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.699045051657394,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.699045051657395,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6990450516573952,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6990450516573945,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6962971066156958,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6962971066156958,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6962971066156958,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5706200050445394,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5706200050445396,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5706200050445398,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.6523682301707827,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.910347116572532,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.716545409521635,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7719738751760183,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6630735363466091,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.635449973075354,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.635449973075355,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.895182600062024,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.895182600062024,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.895182600062023,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4948201392850498,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.7845010058413036,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.784501005841303,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 2.036050413437468,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 2.036050413437468,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 2.0360504134374677,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3060037269994105,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6775103731983456,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6775103731983452,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6775103731983456,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6775103731983458,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6775103731983465,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6775103731983452,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6775103731983452,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.628188100392402,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6281881003924012,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6281881003924012,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6281881003924012,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6281881003924012,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6281881003924024,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.2954281558110057,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3221133933330222,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.498309983391896,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4983099833918967,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4983099833918965,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7711280093346615,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7711280093346615,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.771128009334662,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7711280093346615,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4578240225485737,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.457824022548574,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.457824022548574,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CYP",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.740759250142042,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.7407592501420426,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7408767990015055,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5722260782238675,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.5722260782238682,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.638963023072305,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6389630230723045,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6262237890852953,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8423224396386508,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.842322439638651,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.842322439638652,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.832876440126838,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8328764401268371,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.832876440126837,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.660340029866508,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7567098481287151,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.756709848128715,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7913257360358503,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6873174583114137,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.867419591519864,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6644564168518412,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.60930523454827,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.730942666510188,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.609286567648423,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.818247884371804,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6114984012052234,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.7217369901289026,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6573380275470817,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6028812870552087,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.7102372370442462,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.710237237044247,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.710237237044247,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.671034753731356,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8735408692531397,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8735408692531397,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8735408692531403,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.87354086925314,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.9854720851880836,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.9854720851880827,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.9854720851880836,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.7673066118280998,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.7673066118280996,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.7673066118280998,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5659258662747544,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7335083960259738,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7809144404466868,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6630800271793988,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.511793332600727,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.764788317430288,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.764788317430288,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7796949885111686,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7796949885111673,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.779694988511168,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.506411236920559,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6076978674338598,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.60769786743386,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.717620805259112,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7176208052591122,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.7176208052591126,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.717855887024147,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 2.1259704006896816,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 2.125970400689682,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 2.1259704006896816,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.1259704006896816,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.125970400689682,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 2.125970400689681,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 2.1259704006896825,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 2.1063376429485063,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 2.1063376429485072,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 2.1063376429485072,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 2.1063376429485063,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 2.1063376429485072,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 2.106337642948507,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6102836235972309,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3590468538082163,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5447068502161423,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5447068502161418,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.544706850216142,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.873847638491008,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.8738476384910083,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.873847638491008,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.873847638491008,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.6669882444028428,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6669882444028428,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6669882444028423,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "CZE",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.737041720930692,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.737041720930692,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.467868805430754,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.796306318203047,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.7963063182030472,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4991662177909744,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.499166217790974,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.4522645689793887,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.830172368347616,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.830172368347616,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.830172368347616,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.731177851877013,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7311778518770131,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7311778518770131,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6743031462089684,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7585874752727726,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7585874752727726,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.793382560131842,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6953597799575404,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.697207146225461,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6795728686221592,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6954951499263071,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.676188460946188,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5798129329021784,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5883170446717565,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.608324346556786,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.602450796488624,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5765764276686955,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6501806060444433,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6350835794433,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6350835794433003,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6350835794433003,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5919916682751725,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.822451079429668,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8224510794296689,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8224510794296689,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8224510794296684,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5711932605899492,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5711932605899497,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5711932605899497,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5595315538198808,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5595315538198808,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5595315538198806,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.6124301199874638,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.639366106283413,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.601317459474336,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6458567043266643,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.58345011960398,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.649501913582928,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6495019135829274,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.760135357874471,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7601353578744707,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7601353578744712,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5591207464313035,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5409802747651704,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5409802747651706,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.8424708883065728,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.8424708883065728,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.8424708883065728,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.454631363420644,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8103163310787584,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.810316331078759,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8103163310787591,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.810316331078759,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.810316331078759,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8103163310787596,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.810316331078759,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6610259186859746,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6610259186859744,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.661025918685975,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.661025918685975,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6610259186859748,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.661025918685975,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.520070587224799,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.293476900133694,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4786189238445726,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.478618923844573,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4786189238445733,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.595284177402513,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5952841774025133,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.5952841774025126,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5952841774025135,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4520040452431455,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4520040452431455,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4520040452431455,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "DEU",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.79700239652504,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.7970023965250403,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5454240871263685,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.2837543501152744,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.2837543501152748,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5463507120150872,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5463507120150868,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6163901178837845,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8245618611757728,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8245618611757737,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.824561861175774,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7359639035672907,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7359639035672911,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7359639035672918,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5982733614691775,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7271905334309905,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.72719053343099,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5535363007454508,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6170916183682156,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5086985926731973,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5635005313928272,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.54610728354351,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5582693516684694,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5540437097098756,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5447731648740124,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5353581276800727,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6896733999994165,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5164263351444245,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5612979382861525,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6293299428410222,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.629329942841022,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6293299428410222,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.635544272944325,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.9028466700426356,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.9028466700426356,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.9028466700426343,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.9028466700426352,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7661118314116693,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7661118314116695,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7661118314116693,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6564415352312012,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.656441535231202,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6564415352312016,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.6506796164730184,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6146585917693144,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5686373201778705,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.829481465224656,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.736532517165308,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7147178186153214,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.714717818615323,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7151589827176237,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.715158982717624,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7151589827176243,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7068678332579037,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.7629239473564808,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.762923947356481,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5820722788282575,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5820722788282575,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.582072278828259,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5462440881811912,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.753763196379941,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.753763196379941,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7537631963799405,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.753763196379941,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7537631963799414,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.753763196379941,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7537631963799407,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7669854833654055,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7669854833654053,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7669854833654046,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7669854833654046,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7669854833654044,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7669854833654048,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.545501305288833,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4237813338952523,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4612039185543002,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4612039185543,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4612039185543002,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6812272451239152,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6812272451239156,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.681227245123915,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6812272451239156,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5694815906244866,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.569481590624486,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5694815906244863,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "DNK",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5845116457024122,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.584511645702413,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5299331222446089,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.233716534608851,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.2337165346088508,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6028660158533703,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6028660158533712,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7430099982483684,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.824237733059005,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8242377330590047,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8242377330590045,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6617751188737202,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.661775118873721,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6617751188737204,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6129422742878152,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.716155240425093,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7161552404250942,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6342152786422377,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.678674365607179,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6159181434346856,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6462937964468811,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.649028074052454,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6985959821434666,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.617462912143039,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6319066759895249,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6483440014937585,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.618862135581287,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6542376839001491,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7616644041718696,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5875660789617283,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5875660789617283,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5875660789617283,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.539786663666267,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.788075888304436,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7880758883044343,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.788075888304435,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.7880758883044348,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7040148138832494,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7040148138832487,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.704014813883248,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6177867941461397,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.61778679414614,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6177867941461397,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5534419886397688,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6974350440285504,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.8168151484258757,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6366063568815536,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5486025479507448,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6247674493578306,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6247674493578317,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7645277111868571,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7645277111868567,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.764527711186856,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6702284538256171,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.748109234795796,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.7481092347957956,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.675978040051005,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6759780400510036,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.675978040051004,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.322670941483478,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8289159313011616,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8289159313011616,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8289159313011616,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8289159313011616,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.828915931301162,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8289159313011616,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8289159313011616,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7111660821927088,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7111660821927073,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7111660821927075,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.711166082192708,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7111660821927082,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.711166082192708,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4604662731679463,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2908662631339145,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4756431531561691,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.475643153156169,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4756431531561691,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6569467202499142,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6569467202499142,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6569467202499144,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6569467202499142,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4790688371232348,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4790688371232348,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4790688371232352,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ESP",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6558564666896554,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6558564666896554,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.660108007998702,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4536084764869528,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4536084764869528,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4108674385389326,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.410867438538933,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.449709571317189,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7620301029267988,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7620301029267988,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7620301029267988,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7065079926918252,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7065079926918256,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7065079926918256,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6490447454133812,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6743847493067276,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.674384749306728,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6976104841917403,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6238542352635206,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7234292695798654,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6065425960965816,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.588696730440585,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.806420193016617,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5782225523237448,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6323939407582395,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5855280634784013,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6210651865563326,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.590604911425135,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.61593386967328,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.691186698221929,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.691186698221929,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6911866982219288,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5289673558731258,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.739504679100904,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7395046791009046,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.739504679100904,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.7395046791009048,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6801669012030171,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.680166901203017,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.680166901203017,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6108136759744756,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.610813675974475,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6108136759744756,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5760834977650509,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.8907535662990915,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.822553474847961,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.620306594390528,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5566675481297771,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6968801834438447,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6968801834438445,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6721532205170857,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6721532205170844,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6721532205170846,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5375707504990266,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5841798826789495,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.584179882678949,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.579329715759129,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5793297157591284,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5793297157591282,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4996416588914556,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8148907249565056,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.814890724956506,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8148907249565056,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8148907249565056,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.814890724956506,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.814890724956506,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.814890724956506,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6896474549102587,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6896474549102591,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6896474549102596,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.689647454910259,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6896474549102587,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.689647454910259,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4760987234337666,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.396217240232475,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4891783627907669,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.489178362790767,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4891783627907669,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.745025187361787,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7450251873617877,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7450251873617872,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7450251873617868,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.7040568375827412,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.7040568375827412,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.7040568375827412,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "EST",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5618906230795275,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5618906230795275,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.388491665954784,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.6967384381471595,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.6967384381471595,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6452503106034748,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6452503106034746,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7706564848474324,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8079828405067595,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.80798284050676,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.80798284050676,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6604208845264217,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6604208845264223,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6604208845264226,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.686235800341058,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.684791525548354,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6847915255483543,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.664077915335734,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6371441591334497,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5029594759728326,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.606480530913212,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.617180907874714,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.705351946189816,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.599410649481173,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.7720679793540357,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5966056267482374,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6320095046902845,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5607337707150537,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7856965263379427,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6177807473517416,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6177807473517412,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6177807473517416,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6608639937889358,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6685736962392828,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6685736962392832,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6685736962392834,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6685736962392832,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6753307535189073,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6753307535189066,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.675330753518907,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6724337086659766,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6724337086659762,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6724337086659755,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5496147109197376,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.768423102062439,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6692438810558723,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6932996317011626,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6729550313378707,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.75517896759449,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7551789675944909,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7608921995412938,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.760892199541293,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7608921995412938,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.55643295159293,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6928531554989343,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6928531554989346,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.665858539319839,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6658585393198395,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.665858539319839,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4353111998433778,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.724334856573467,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.724334856573467,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7243348565734673,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7243348565734673,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7243348565734675,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7243348565734673,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.724334856573468,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.641112582524021,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6411125825240205,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6411125825240205,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.64111258252402,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.64111258252402,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6411125825240196,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6399425830807997,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.384764169915362,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4827153083091849,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4827153083091853,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4827153083091853,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6494122294981124,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.649412229498112,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6494122294981122,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.649412229498112,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5666111125788005,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5666111125788005,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5666111125788005,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "FIN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.7120262662209378,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.7120262662209378,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.8177311212336136,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4577790344034791,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4577790344034791,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.731918684040738,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.7319186840407377,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.211063859170734,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.9174048948144329,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.9174048948144335,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.9174048948144324,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.893677861085352,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.893677861085352,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.893677861085352,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.787841958414167,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.8396097106982736,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.839609710698273,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7669594197945884,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7685841144244787,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.8687169649825195,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.7914174910296294,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.824781091895226,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.8452669480936863,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.7241887728703138,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.753054250958294,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.7689489561529674,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.7960156396557632,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.8179344522401644,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.8131268393818107,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.729121606606579,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.729121606606579,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.729121606606579,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.592414458466244,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.89839633392966,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8983963339296608,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.89839633392966,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.89839633392966,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.832277452422527,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8322774524225265,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.832277452422526,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.8185417526644927,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.8185417526644931,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.8185417526644936,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.668147033015843,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.9929740361767885,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7040662267851163,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7979956255237872,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.519410804426501,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7659432564512476,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.765943256451247,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.813045565409405,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.813045565409404,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.8130455654094049,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7410262509314711,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6889284718554205,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6889284718554198,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.940809126258093,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.9408091262580929,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.940809126258093,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3935121297321196,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 2.063462282752318,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 2.063462282752318,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 2.0634622827523184,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.063462282752318,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.063462282752318,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 2.063462282752317,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 2.063462282752318,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.823176324380384,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8231763243803836,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.8231763243803831,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.8231763243803831,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.823176324380383,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.8231763243803831,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5551413295343015,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4147461204342084,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4756098599521177,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4756098599521177,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4756098599521177,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7618536685384032,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.761853668538403,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7618536685384032,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.761853668538403,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.7076728609720564,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.707672860972056,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.7076728609720564,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "FRA",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.705273675492119,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.705273675492119,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7875395684161914,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5257314094697945,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.5257314094697954,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6829230098615655,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6829230098615653,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.8979704290394384,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.815551628211275,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.815551628211275,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8155516282112745,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6338575592850468,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6338575592850462,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6338575592850464,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6002634188774985,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6390957574516207,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6390957574516205,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7303799648143978,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6889732562478792,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6201650927329825,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.648269582405992,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.691717258912875,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6924361657084903,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.58502234012975,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4986456537088602,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6625025887139622,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6070991666971446,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6388987758006774,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6748505724460223,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.588753093586174,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.588753093586174,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5887530935861744,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6387861518629805,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7382933614809073,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7382933614809082,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.7382933614809075,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.7382933614809082,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7575885252145125,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7575885252145118,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7575885252145125,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6477939243395847,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6477939243395845,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6477939243395847,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.678005224213081,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7255377155724352,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.690906592967638,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.690702111833056,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4988456223902222,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.66612665289954,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6661266528995389,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7884076140777616,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.788407614077762,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7884076140777614,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5616894170918072,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.736499536839096,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.7364995368390956,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.8567730815646912,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.856773081564691,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.856773081564692,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4013210026029517,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8560746120222864,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8560746120222864,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8560746120222864,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8560746120222864,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8560746120222864,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8560746120222864,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8560746120222855,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7894886813789646,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7894886813789646,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7894886813789648,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.789488681378964,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.789488681378964,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.789488681378964,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.614847869536873,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4707190576139304,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6129151847867194,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.612915184786719,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6129151847867196,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7381716586928015,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.738171658692801,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7381716586928015,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.738171658692801,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5401461057463834,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5401461057463837,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5401461057463837,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "GBR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.479350128366617,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.4793501283666166,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.2879135508538433,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4780136578851983,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4780136578851994,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.560610754069978,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5606107540699783,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5847203427195378,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.642257617252465,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6422576172524652,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6422576172524643,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.667945287029542,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6679452870295428,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6679452870295433,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.597855112356667,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6198976473783882,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6198976473783873,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6903821349737658,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.502059588720137,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6920046123982306,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5353672757201895,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5044049480125878,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5938039682401437,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5275944887068813,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5929619524632668,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5446184579976696,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.547699455554056,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5306796146148045,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4459489722327394,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.492695328502173,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.4926953285021725,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.492695328502173,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.374367507410097,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.439650185735931,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.4396501857359318,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.4396501857359314,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.4396501857359318,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6497003180621432,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.649700318062144,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6497003180621432,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4161220622551054,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.416122062255105,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.416122062255105,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.6062994579072671,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4417230828057632,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7100548161690543,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4477469448268037,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4888596710579984,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5629757989588384,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5629757989588384,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6814346958233972,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6814346958233968,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.681434695823396,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4887474983956317,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4931618334452352,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.4931618334452352,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4173087982751584,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.4173087982751589,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4173087982751582,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.176158225665454,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5098138686758429,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5098138686758429,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5098138686758429,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.509813868675843,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.509813868675843,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.509813868675843,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5098138686758429,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6736993683145494,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6736993683145491,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6736993683145496,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.673699368314549,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.673699368314549,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6736993683145494,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.2762920462100142,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.1568314134677784,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.389366608731241,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.389366608731241,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3893666087312406,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5757318134767229,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5757318134767229,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.5757318134767229,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5757318134767233,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.533714407922846,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5337144079228455,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5337144079228455,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "GRC",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.646833731877125,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.646833731877126,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4894189517828356,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6581970350290351,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7700172098922715,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7700172098922715,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7700172098922713,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.9151515115074609,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.91515151150746,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.9151515115074604,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.7753411199777027,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.9050098286083368,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.905009828608337,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7434470884014803,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7916626364878503,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7972197442493345,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.7499939797324413,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6990817829226856,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.69448801323194,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.7368632090631215,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.7133111902883045,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.713716435545576,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7237923068311456,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7501115860557208,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.8371704214173064,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.837170421417307,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.837170421417307,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6450922000070969,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6747053246890764,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.674705324689076,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6747053246890762,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6747053246890768,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.621619923038447,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6216199230384472,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6216199230384465,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5963239116110153,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.596323911611016,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5963239116110153,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.578192488045422,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.728209803491744,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6053667746200235,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7652674028908335,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6950901998182055,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5550675306824555,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.555067530682455,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.700313467092539,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7003134670925395,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7003134670925393,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6277996109171426,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.1771960329129274,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.1771960329129263,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5763714038900176,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5763714038900176,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5763714038900176,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5023101101709524,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.263516707428345,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.2635167074283455,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.2635167074283449,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.2635167074283449,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.263516707428345,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.263516707428345,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.263516707428345,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6645116011404526,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6645116011404515,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6645116011404528,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.664511601140452,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6645116011404515,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.664511601140452,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.2796911518861758,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3156678596253943,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.417268689080096,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4172686890800958,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4172686890800958,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.712422420328919,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.712422420328919,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.712422420328919,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7124224203289184,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.610002517416797,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.610002517416797,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.610002517416797,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "HKG",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5552271455887992,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5552271455887992,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.6514986921985797,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5459844419086357,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.5459844419086353,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5226704953526002,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.522670495352601,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5623363494405758,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6855154914435986,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6855154914435986,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6855154914435986,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7562025311567102,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7562025311567098,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.75620253115671,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5902853952553644,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.694114715204283,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6941147152042837,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6645938939879674,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.708079440948194,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.582134085539194,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5774417115838353,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.594609897062908,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5909619768296164,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5418130000488517,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4694516555288937,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.605855976189632,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6662603973817844,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.4637926003949244,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7162739490088752,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.600112119230792,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6001121192307917,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6001121192307917,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.3631979236493694,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5866485655924278,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5866485655924287,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5866485655924287,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5866485655924285,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.610713188131923,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6107131881319232,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6107131881319223,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6016112586489195,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6016112586489202,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6016112586489202,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4769184163921771,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.3599596735711466,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.645067767582357,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5878075836685497,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2661400953817894,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.600473376189152,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6004733761891516,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.769269668550998,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.769269668550998,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7692696685509977,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5336886720758474,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5246167752721422,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5246167752721431,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.501472846420512,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5014728464205118,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5014728464205127,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4825511748694251,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6859668642009258,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6859668642009265,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6859668642009262,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.685966864200927,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.685966864200927,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6859668642009265,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6859668642009265,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7936895169719824,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7936895169719815,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7936895169719824,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.793689516971982,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7936895169719824,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7936895169719822,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5305294586134848,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3078761582282443,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4757506458938592,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4757506458938596,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4757506458938594,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6502394398508622,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.650239439850862,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6502394398508622,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6502394398508622,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.688446543836936,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.688446543836936,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6884465438369354,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "HRV",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5539509434902283,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5539509434902286,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5497114565757433,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.551129446378689,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.551129446378689,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4829994586801676,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4829994586801682,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.4648011657224032,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.748091345879741,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.748091345879741,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7480913458797416,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6457288505747831,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6457288505747831,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6457288505747836,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5784684637239397,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6680445855862285,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6680445855862285,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6767417759528842,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5580367830269697,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6452289919732244,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.57891651294493,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.568806594081031,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.605143671587174,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.542365272627976,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5901449393950098,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5876275889203613,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5755477003089196,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5838373541688398,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6091198269625795,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5277661824970332,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5277661824970332,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5277661824970337,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6336388265182096,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6374334723321755,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.637433472332175,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6374334723321748,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6374334723321748,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5816544923710651,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5816544923710651,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5816544923710651,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5880897736673525,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.588089773667352,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5880897736673518,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.491959716914392,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7743592540228057,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5354076718623817,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5132906620494988,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4514402316121655,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.716457541858018,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7164575418580188,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7590215105961873,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7590215105961868,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7590215105961866,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5292640539539597,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.50238292121364,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5023829212136408,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6087459616205542,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6087459616205542,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6087459616205535,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.482671334677326,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5765550437803386,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5765550437803384,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.576555043780338,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.5765550437803384,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5765550437803386,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5765550437803388,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.576555043780339,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.579482909342821,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.579482909342821,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.579482909342821,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.579482909342821,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.579482909342821,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.579482909342821,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4180258363268037,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.302688186638545,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.448526718368767,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4485267183687671,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4485267183687671,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.670658174363504,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6706581743635045,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6706581743635045,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.670658174363504,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5165126239644868,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.516512623964487,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5165126239644868,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "HUN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.2927412358714343,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.2927412358714343,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.11973742464978,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4082468479007673,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4082468479007682,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.2603238953857323,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.260323895385732,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.3053833452076986,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.5610440954388611,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.5610440954388607,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.5610440954388616,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7114625672543544,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7114625672543549,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7114625672543542,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5277393236242285,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5550007975690403,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5550007975690403,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5989995088728628,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5349210994411653,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5606531696443782,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5381754029720685,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.479456528794974,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5014260605663456,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4540431985295408,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4532584155633268,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.4310655718746137,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5056326033725425,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.3525883245738348,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.3749570543943503,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.4497844337465786,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.4497844337465782,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.449784433746578,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4692605800319307,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.421349765192529,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.4213497651925286,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.4213497651925282,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.4213497651925284,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.420626993163563,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.420626993163563,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4206269931635636,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.2960029792480563,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.296002979248056,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2960029792480563,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.275084855595746,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4456293023006184,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.3371267840041643,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3475167058010755,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3431869995383507,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5503258883525728,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5503258883525723,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.521625601269876,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.5216256012698748,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.5216256012698752,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.243345221411517,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.3858121874545268,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.3858121874545264,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.1626061844036395,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.1626061844036395,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.1626061844036402,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.263372794391333,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.3283527155810502,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.3283527155810502,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.3283527155810506,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.3283527155810506,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.3283527155810508,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.3283527155810504,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.3283527155810506,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.26635201735636,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.2663520173563594,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.2663520173563596,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.2663520173563598,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.2663520173563594,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.2663520173563594,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.3917854093406683,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3067381405269112,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5056432227903065,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5056432227903065,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5056432227903058,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.3543373143838744,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.3543373143838744,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.3543373143838742,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.3543373143838744,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.273535420228182,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2735354202281814,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.2735354202281814,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "IDN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.2606192941560523,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.2606192941560517,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.1789260845806244,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4776220568198122,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4776220568198126,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4494108151032108,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4494108151032108,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.330506112274433,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6283537015099954,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6283537015099945,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6283537015099947,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6229108343678644,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.622910834367865,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6229108343678649,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.4842955783097294,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5934854931456657,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5934854931456657,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.633867123492077,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5149717255692663,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4192861636479324,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5839772852198235,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.4828101636851312,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5611853146605654,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4933140630652766,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.7778456847510584,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5352116829180116,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5648213962552362,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.4732376934622915,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4478476244454512,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.652283629433166,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.652283629433166,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6522836294331655,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.559245647277895,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5957370343594657,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5957370343594652,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5957370343594652,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5957370343594657,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.4679391030149815,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.467939103014981,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4679391030149809,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.3313850914675798,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.3313850914675796,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.3313850914675798,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4739538784772463,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5110549181545196,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7614192494207697,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5999188984085748,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3307024167275836,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5461607259384564,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.546160725938455,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7447650947203013,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7447650947203008,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7447650947202995,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.628069802727101,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.675597857920713,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6755978579207118,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4851468125112124,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.4851468125112128,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4851468125112128,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2775027911221275,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.726914727304001,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.726914727304001,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7269147273040009,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.726914727304001,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7269147273040013,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7269147273040004,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7269147273040004,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6332860922514645,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6332860922514645,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.633286092251464,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.633286092251464,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.633286092251464,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6332860922514645,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.3460019465146558,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4402837309180316,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.382508770324005,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.382508770324005,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.382508770324005,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7892109716923124,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7892109716923124,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7892109716923126,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7892109716923124,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4368481097284884,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4368481097284886,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4368481097284886,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "IND",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.7764624058363334,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.7764624058363332,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7116619624097076,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.9011484842109176,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.901148484210918,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6544967544501834,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.654496754450183,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7513326771756788,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8904852653215485,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.890485265321548,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8904852653215485,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.769336237647663,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.769336237647663,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7693362376476631,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.7811453010893108,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7719894414348747,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.771989441434875,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.387186555546651,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7409398100236024,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.9287374397421704,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5950304417954229,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.603744097875259,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.8068064361311416,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.7556537498732236,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.661812030563981,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.7515860876100655,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4636513678802534,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7430746038100784,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7574584811295666,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5586352580560798,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.55863525805608,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.55863525805608,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.8185634721500352,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8393215433809484,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.839321543380948,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.839321543380948,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.839321543380948,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.8266880470038276,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8266880470038285,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.826688047003828,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.770812818746355,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.770812818746355,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.7708128187463552,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4791934322473312,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 2.013305237891856,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.661691577895331,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.934952282560608,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5683075034855791,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.650337302222397,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.650337302222396,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7593966160356351,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.759396616035635,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7593966160356351,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.740683639361645,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 2.096183337933613,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 2.096183337933613,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.914132141673475,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.9141321416734736,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.914132141673475,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5016302555323378,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8328120891419344,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8328120891419335,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8328120891419344,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8328120891419344,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8328120891419344,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.832812089141934,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8328120891419335,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.794122660323523,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7941226603235227,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7941226603235223,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.794122660323522,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.794122660323522,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.794122660323522,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5613187203146863,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2802535155798775,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3734548717169517,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.373454871716952,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.373454871716952,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7077336198268414,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7077336198268416,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7077336198268418,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7077336198268416,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.830826019716198,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.830826019716198,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.830826019716198,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "IRL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.683804290271807,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6838042902718071,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.49223071213321,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.827098274045536,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.827098274045536,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5615327947229602,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5615327947229607,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.779608727439741,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.675783885060426,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6757838850604263,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6757838850604256,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7352987703103784,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7352987703103788,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7352987703103788,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.58005271414034,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6114643742039223,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.611464374203922,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.3137275972629792,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5699556446566654,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.8685500083336803,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.56175008806121,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.583707164745337,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.666695654504606,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4850244981021574,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.597549233441469,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.721843296540213,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5361413274277411,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5733088951568417,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5315500129429511,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6299132465312884,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6299132465312889,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6299132465312882,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.426203953945008,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5717068948012816,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.571706894801282,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5717068948012818,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5717068948012811,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.638508262233927,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6385082622339266,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6385082622339264,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4923830265797684,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4923830265797686,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4923830265797688,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5903891391050806,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7005597973146522,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6828053770404594,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.470099913740122,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4530803388660165,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6587168269918942,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6587168269918935,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.754585855989118,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7545858559891188,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7545858559891183,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.514209739555247,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.538682945287816,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.538682945287816,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.572731462818104,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.572731462818104,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5727314628181042,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.464834280822572,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.730195224085947,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.730195224085947,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7301952240859473,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7301952240859473,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7301952240859468,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.730195224085948,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.730195224085946,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.8190870306927307,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.819087030692731,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.8190870306927311,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.8190870306927311,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.8190870306927311,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.8190870306927316,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4795439179512788,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.387517101335298,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.590937279605818,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5909372796058177,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.590937279605818,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6006896774737651,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.600689677473765,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6006896774737651,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.600689677473765,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.6166717744518724,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6166717744518724,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6166717744518726,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ISL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6429649196032907,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6429649196032905,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5993210329959573,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.301623473808924,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3016234738089243,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4378144982888257,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.437814498288826,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5172303725709826,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8401551501082751,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.840155150108276,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8401551501082751,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.756114347395685,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7561143473956853,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7561143473956846,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5618682956849912,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7116870166469913,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7116870166469909,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6071431597764865,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6664327830301506,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7670204573746406,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5944451756065854,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6333598782050918,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6624921577022038,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5795147702096488,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.7270980759274104,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5518861707885172,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.7953522046149992,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6895866745725523,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7784811501963886,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.71475629557509,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.7147562955750908,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.714756295575091,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5361069048882778,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7808661304176914,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7808661304176914,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.7808661304176916,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.7808661304176914,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7595674548734266,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.759567454873427,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7595674548734277,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.650977026448043,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.650977026448043,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.650977026448043,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.636220376330778,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6049835503476428,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5581699942083374,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.989053901802524,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7073205493466432,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.8683784897485365,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.8683784897485367,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.820547437830134,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.8205474378301345,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.8205474378301345,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5503256298430228,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.3778702353350092,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.3778702353350094,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.76739683259902,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7673968325990206,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.767396832599021,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.354556745016573,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.9750317054154296,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.9750317054154296,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.9750317054154296,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.9750317054154292,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.97503170541543,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.9750317054154287,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.9750317054154287,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.358891673749324,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.3588916737493235,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.3588916737493235,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.3588916737493235,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.358891673749324,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.3588916737493235,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6713774648121271,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.398783351281849,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5958372801400835,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5958372801400835,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5958372801400835,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.439617785776699,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.4396177857766992,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.4396177857766992,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.439617785776699,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.9493895153039105,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.94938951530391,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.9493895153039105,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ISR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5294720248531262,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5294720248531275,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5062337748798742,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.7847345009576383,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.7847345009576383,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.184673276398942,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.1846732763989418,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.1846689475040495,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8606442019223048,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8606442019223053,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8606442019223053,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.8564354202233555,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.856435420223356,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8564354202233555,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6391414640403952,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.761645735087867,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7616457350878671,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.8176725618806533,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6386895569803868,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.70373440288511,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.649296667427366,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6240327374819752,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.725328411357945,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5947683803006631,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.620796881445188,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.650536892090462,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.64595291205663,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.72447929549398,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7110677130895957,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.702429930966965,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.702429930966965,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.7024299309669653,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6417482916018769,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7793241054918127,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7793241054918123,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.779324105491812,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.779324105491812,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.827961036775143,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.827961036775143,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.827961036775144,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6764111596805429,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6764111596805436,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.676411159680543,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5493570500846912,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.671794646520855,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.8424324279401263,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6755383005945472,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.59545142103314,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.652814700112934,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.652814700112934,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.8974771846318648,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.897477184631864,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.8974771846318643,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6072697347863087,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.719381283759568,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.719381283759568,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6549221217747223,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.654922121774722,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6549221217747228,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2781351343386584,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.732405579781274,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.7324055797812736,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.732405579781274,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.732405579781274,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7324055797812732,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7324055797812747,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7324055797812743,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7675104202610443,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7675104202610443,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7675104202610443,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7675104202610437,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7675104202610437,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.767510420261044,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5800304611071485,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3318759897350934,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5984439012745455,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5984439012745455,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5984439012745455,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.8098454818546583,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.8098454818546583,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.8098454818546583,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.8098454818546577,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.523941998646582,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.523941998646582,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5239419986465816,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ITA",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5883064392616797,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5883064392616797,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5384210192509882,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.3791587516294377,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3791587516294377,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5875413781316523,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5875413781316523,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.646350314321882,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6611358464065318,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6611358464065322,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6611358464065318,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.684927681782459,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6849276817824594,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.684927681782459,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.540337424692802,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.59304140368665,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5930414036866494,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6557278780070306,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5819812671625535,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.53999401886301,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5265949713296971,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.4783149927809145,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5145404929107371,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4275328609860023,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5352127273154847,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5113520082393823,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.436357039047315,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.49070130080389,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5340945698885544,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5147265719202738,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5147265719202738,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.514726571920274,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.720656276185695,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.2704834030192569,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.2704834030192562,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.2704834030192558,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.270483403019257,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5407998997419703,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5407998997419703,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.54079989974197,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.485144802430854,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.485144802430854,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4851448024308544,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.394455393510036,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5497358560958072,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6081780585495646,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5470315336394591,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2931739432823666,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6482358343378969,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.648235834337897,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.77263847240509,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7726384724050892,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.772638472405089,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7268672645384378,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6755782260403238,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6755782260403231,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5762397630109908,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.57623976301099,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5762397630109903,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3163279529783614,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.600252853987035,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.600252853987035,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6002528539870349,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.600252853987035,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6002528539870349,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.600252853987035,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.600252853987035,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.5067947344332795,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5067947344332795,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.5067947344332795,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5067947344332788,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.5067947344332788,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.5067947344332793,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4872030211605325,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3084928997137162,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.42988468927253,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4298846892725297,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.42988468927253,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5652006254666604,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5652006254666604,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.5652006254666604,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5652006254666604,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5711514612153523,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5711514612153523,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5711514612153523,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "JPN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.4959636483698775,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.495963648369878,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.163476027106151,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.3745888162541553,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3745888162541549,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4225174247213297,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4225174247213304,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.490217542136665,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.569080540083565,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.5690805400835657,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.5690805400835657,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6280220332894348,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.628022033289435,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6280220332894348,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5282309977622173,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.611052054661094,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6110520546610938,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5690493059706283,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.4852889565101253,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.2600760548730197,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5434320302225173,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.4526731374590491,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.4720109130136034,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.479637871462344,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.9628680202326625,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.4478426124939288,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4519089813582362,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.2796583498378504,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4921381481286633,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.4302967159203726,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.430296715920372,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.430296715920372,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4764559255576328,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.498224133931602,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.4982241339316018,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.498224133931602,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.4982241339316014,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.709704898897826,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7097048988978258,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7097048988978267,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4554176978990436,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4554176978990436,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4554176978990434,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.444023736438495,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.879142968980516,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.3677227170880228,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3604838712456622,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3390905942236988,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.4579675127994287,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.457967512799428,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.5051568543994032,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.505156854399403,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.5051568543994025,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4705142363720836,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.3474621950062544,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.3474621950062546,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3952008075983386,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3952008075983386,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.3952008075983395,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3344111462582473,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.685769381496615,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6857693814966146,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6857693814966146,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.685769381496615,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6857693814966157,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6857693814966144,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.685769381496614,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.5262566704742466,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5262566704742464,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.5262566704742464,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5262566704742464,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.526256670474246,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.526256670474246,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.893966155825097,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.7112790919400662,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.7133792806569734,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.7133792806569732,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.7133792806569736,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.695653757131946,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6956537571319463,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.695653757131946,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6956537571319463,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5487758192307126,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5487758192307126,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5487758192307126,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "KAZ",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.2055829624028218,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.2055829624028218,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.0664281431893832,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.3239158713038428,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.3239158713038433,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.3141234682226004,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.5810369405788958,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.5810369405788969,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.5810369405788964,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6970141211862413,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6970141211862406,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6970141211862413,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.3482497826035842,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.575779393516228,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5757793935162276,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6883299375803469,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.403046842899062,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.2307427136164557,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5202000833641545,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.3808960680458988,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.3783086802145932,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4042025886327012,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.473914727728651,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5154597846304094,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.3610200829790322,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.3914990316511318,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.3541145480266583,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5847860209611226,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5847860209611222,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.584786020961122,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6697866861332908,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6626910569904882,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6626910569904876,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.662691056990488,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6626910569904882,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.46750628099519,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4675062809951902,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4675062809951902,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4137433731932187,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.413743373193218,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4137433731932183,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4207545529257022,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4844448757131516,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5848045375558186,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4101598648542466,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.49673516737983,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5484508836123658,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5484508836123667,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.519600211260111,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.5196002112601108,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.5196002112601112,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5050713605111317,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5089058153676391,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.508905815367639,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.225578989217704,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.2255789892177038,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.2255789892177045,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4260249949862174,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5275455406976646,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5275455406976646,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5275455406976644,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.5275455406976644,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5275455406976652,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.527545540697664,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.527545540697664,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4709191807846829,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4709191807846835,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4709191807846829,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.470919180784683,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.470919180784683,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.470919180784683,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.555030128555805,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.354651489069891,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2181888944483186,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2181888944483186,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.2181888944483188,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.432601075345333,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.4326010753453335,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.432601075345333,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.432601075345333,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.339071533949907,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.3390715339499073,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.3390715339499073,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "KHM",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.608080855570975,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.608080855570975,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.6623215272904988,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.68656768434279,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.68656768434279,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.629854878052283,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6298548780522826,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6031303601112632,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8270412802919476,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8270412802919471,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.827041280291948,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.8535227403458807,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8535227403458805,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.85352274034588,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6541755507558265,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6950461834996975,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6950461834996975,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7638159076894189,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6907801937053195,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.758911013508894,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6247473909394294,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6357296390471152,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.7078748967970234,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.574352538604601,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5046229389607886,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.604291520004463,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5774289284199188,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5981461946499018,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6673812118094644,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6483636611737007,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6483636611737005,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6483636611737007,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6647103297601638,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5580979034225566,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5580979034225566,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5580979034225568,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5580979034225566,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5956770904727642,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5956770904727648,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5956770904727646,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6136981278341551,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.613698127834155,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.613698127834154,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4859288000901016,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.750489725935093,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6438327556206789,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7172287616433524,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6310778414523497,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7922258930067203,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.792225893006721,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7388132855033152,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7388132855033156,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.738813285503315,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7285998182268414,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5551656872144988,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5551656872144983,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.608319432355221,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6083194323552212,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6083194323552212,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5037038856632785,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.652227466528357,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6522274665283572,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6522274665283567,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6522274665283572,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6522274665283574,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6522274665283574,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6522274665283565,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.598105115339221,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.5981051153392214,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.598105115339222,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.5981051153392212,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.5981051153392216,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.598105115339221,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.406674257640233,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4624938845622537,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5209728636607869,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5209728636607869,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5209728636607864,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7055049261009834,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7055049261009834,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7055049261009831,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.705504926100983,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5698638034267185,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5698638034267187,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5698638034267185,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "KOR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.399243719310703,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.3992437193107037,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.2801637307781688,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.3935137677302234,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3935137677302232,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4440377712436143,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4440377712436143,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.4491540722597158,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6943186510683892,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6943186510683892,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.69431865106839,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7644469722713494,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7644469722713496,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7644469722713496,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5834217984012469,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.577288338570492,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.577288338570493,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.549120752688326,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5172963686564245,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5210807299881552,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6112667313602878,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5169746668729465,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.4946213252974356,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4882527464917108,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5096143416787906,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.4913072918976065,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4221022027899966,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5685272384414897,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4497633781234365,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6090980425842694,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6090980425842694,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6090980425842694,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4145739200002871,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.4648743077040762,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.464874307704076,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.4648743077040751,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.464874307704076,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.675800808828816,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6758008088288163,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.675800808828816,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5584122922226071,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.558412292222607,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5584122922226074,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5290040392677262,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4781747499323186,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.3004327122271333,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4730631630175854,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3673612924682317,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7498748350106623,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7498748350106617,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6271780443816304,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6271780443816308,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.62717804438163,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.418261328751434,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.8002064419502268,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.8002064419502268,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.739388907240609,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7393889072406097,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.7393889072406097,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5169221767804189,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.778200122051007,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.7782001220510069,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7782001220510073,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7782001220510073,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7782001220510064,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.778200122051007,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7782001220510075,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6964842907214952,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6964842907214952,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6964842907214943,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6964842907214954,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6964842907214952,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6964842907214952,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5690046612070956,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.503783186361558,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.484432632584299,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.484432632584299,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4844326325842994,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.481167427520097,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.481167427520097,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.481167427520097,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.481167427520097,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4811917912469836,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4811917912469834,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4811917912469834,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "LAO",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5817799916806248,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5817799916806248,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5380854775741066,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4981117895084195,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4981117895084195,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.54096795236357,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.540967952363569,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.478698478257643,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6373555987220252,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6373555987220254,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6373555987220247,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.526310124834408,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.526310124834408,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.5263101248344078,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5256495762399775,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5397696240635876,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5397696240635876,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7132998209740724,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6037133495811988,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4279722861850628,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.4812126137015482,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.533348629467454,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.524609342455806,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4934425185839322,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4600649760557898,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5655012763292442,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5088899574641057,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5374344476278767,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.501177000600523,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.4674715742445927,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.4674715742445936,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.4674715742445932,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6338537100266135,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5556134619924584,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.555613461992459,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5556134619924589,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5556134619924584,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5435055389112038,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5435055389112036,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5435055389112031,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4121495089312857,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4121495089312857,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.412149508931286,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5438378946546945,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4957787832133995,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.851589161204445,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5768218618108698,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5374117146238455,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.520292955906082,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5202929559060827,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.741918008975872,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.741918008975872,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7419180089758723,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.638835459055119,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.551311616832958,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.551311616832958,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6363872216981246,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6363872216981246,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6363872216981257,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4042456735424749,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6823574979710545,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6823574979710547,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.682357497971055,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6823574979710545,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6823574979710552,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.682357497971055,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6823574979710547,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6514959302516825,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6514959302516816,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6514959302516816,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6514959302516825,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6514959302516825,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6514959302516816,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.424748101472623,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.255170211927046,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.367007405138355,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.367007405138355,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3670074051383552,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5092577056310643,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.509257705631064,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.509257705631064,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.509257705631064,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4645456686967893,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4645456686967897,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4645456686967897,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "LTU",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.8377972887866487,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.8377972887866487,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.954780863738534,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.9547808637385329,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.885940428763524,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8602413524223755,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8602413524223755,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8602413524223764,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 2.216881992131765,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 2.216881992131765,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 2.216881992131766,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.8787750720719656,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.9734147118281389,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.973414711828139,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.879931340094216,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 2.0474537935300963,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.9748776879042345,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.827218589405158,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.7212613412913482,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.649563100488421,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6836723799242066,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.827675108892308,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.745648448821978,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7947029127470555,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7960429990744329,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.763086270446905,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.7630862704469044,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.7630862704469048,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.862572206813066,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8239965944104504,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8239965944104493,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.82399659441045,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8239965944104493,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 2.0067982255011603,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 2.0067982255011603,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 2.0067982255011603,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 2.190898081488291,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 2.190898081488291,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 2.19089808148829,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.735535394643728,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.844017688402932,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.9561679028940744,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.8725403375108567,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7896644386119198,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7814255191499329,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7814255191499315,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 2.2252962203842457,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 2.2252962203842457,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 2.2252962203842457,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 2.118324953997765,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 2.2823088216794365,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 2.2823088216794374,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 2.2971421593038857,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 2.2971421593038848,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 2.2971421593038848,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.6905105453432168,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 2.131057622820076,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 2.131057622820076,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 2.131057622820076,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.1310576228200757,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.1310576228200766,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 2.1310576228200766,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 2.131057622820076,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 2.1485458715434853,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 2.1485458715434858,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 2.1485458715434858,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 2.148545871543485,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 2.1485458715434858,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 2.1485458715434858,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.6609160271897925,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4211314791882317,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5717604416598914,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5717604416598914,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5717604416598914,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.9986127675020608,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.9986127675020608,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.9986127675020608,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.99861276750206,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.834722760828491,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.8347227608284908,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.8347227608284908,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "LUX",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.573633993690907,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5736339936909065,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.431595585341088,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.473106771599708,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4731067715997077,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.498760140896666,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.498760140896666,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7207732777216664,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6990854179681636,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.699085417968165,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6990854179681645,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.509437337938202,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.5094373379382025,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.5094373379382016,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.594408997591104,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.624943625631046,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6249436256310468,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5525824537639816,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5926959532161318,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.793783256573415,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5365116164609915,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5578591911570123,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.8485330552169887,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.544052657821575,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.517447854185996,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5875946753605117,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.532859868007076,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6003410682039123,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6785691175189932,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5009797536745892,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.50097975367459,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5009797536745892,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6001764661972264,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.603927793759494,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6039277937594922,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6039277937594931,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6039277937594931,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7588434079865314,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7588434079865312,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7588434079865314,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5842406973084808,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5842406973084808,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5842406973084802,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5519168709076228,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.602866162613528,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7637256802222845,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5305362016627633,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5437693313281629,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6177803762885727,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6177803762885723,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.5882644414217713,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.588264441421771,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.588264441421771,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4900016604655957,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.3954364984134144,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.3954364984134144,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5707882988349249,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5707882988349249,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5707882988349249,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3553219722505885,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.7374196824930228,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.7374196824930224,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7374196824930226,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7374196824930224,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7374196824930224,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7374196824930224,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7374196824930228,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.664656567544146,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6646565675441465,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6646565675441465,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6646565675441465,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6646565675441474,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6646565675441465,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4423647645751243,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3352135017768687,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3945780169136786,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.394578016913678,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3945780169136786,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5871167414982768,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.587116741498277,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.5871167414982774,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5871167414982776,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5945051844165752,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5945051844165756,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.594505184416576,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "LVA",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.3918405979133883,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.3918405979133877,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.2763767058339677,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.126142244799637,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.126142244799637,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.256126454830794,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.256126454830794,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.3811403467236083,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.613770985117176,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.613770985117176,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.613770985117175,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6787450290777752,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.678745029077775,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6787450290777752,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.4581205327349958,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5388918665816775,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.538891866581678,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5669526330558214,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.4701877447006717,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4251765395191456,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5696527612087068,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.4290334559507438,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.4308406896710808,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4617400830296097,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5226797367960143,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.4569776172113031,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4809840971281223,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5127127933770776,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.416600568744976,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.4439717165582142,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.4439717165582142,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.4439717165582142,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.3937266500415335,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.336290441010218,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.336290441010218,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.336290441010219,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.3362904410102183,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.4133037914130129,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4133037914130142,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4133037914130138,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.356705569606278,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.3567055696062784,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.3567055696062778,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4680352731648016,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.539118856817946,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6953526851434193,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4648376330903237,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3496015648883195,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5034362635936884,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5034362635936878,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6785454948014102,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6785454948014102,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6785454948014096,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.400323875449056,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.2364355218567142,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.2364355218567142,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4345354932928855,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.434535493292886,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.434535493292886,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2047651009371194,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.301150829454324,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.3011508294543237,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.301150829454324,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.301150829454324,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.301150829454324,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.301150829454324,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.301150829454323,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3266214050085927,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.3266214050085925,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.326621405008593,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.326621405008593,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3266214050085934,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.3266214050085927,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.3811490188511402,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2124096190830445,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.2990988611334566,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.2990988611334562,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.299098861133456,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.4293872037926971,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.4293872037926971,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.4293872037926971,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.4293872037926976,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.3642501228727175,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.364250122872718,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.364250122872718,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "MAR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.397944752557422,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.397944752557423,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.459050426470033,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.3718935142526094,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3718935142526103,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.397985799950202,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.397985799950202,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5149765027550128,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.5908183702894731,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.590818370289473,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.5908183702894727,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6368396350020509,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6368396350020509,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6368396350020509,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.510375471751212,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.646128235416849,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6461282354168494,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6199129122512226,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5939106672346337,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6161947148937508,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5745668867630187,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5794958899110825,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5369025256969138,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.540066110899081,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.539782155250216,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.545791907348795,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.506043747860943,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5250316059974731,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5159662359517698,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.544489136844564,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5444891368445643,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5444891368445643,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.3465937165174506,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.398365731935716,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.3983657319357152,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.3983657319357157,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.3983657319357157,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.400771321150748,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4007713211507482,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4007713211507486,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.300994734240773,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.3009947342407735,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.3009947342407728,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.3593466662303797,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.483709059530988,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.604749530421131,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4475847755578068,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5342647763944024,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.461745305648645,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.461745305648645,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6549452112235477,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6549452112235468,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6549452112235463,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.387317830397154,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6167742909572138,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6167742909572138,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.575453398999637,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5754533989996378,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.5754533989996369,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1534944347595135,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.401734835740855,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.401734835740855,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.4017348357408546,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.4017348357408552,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.401734835740855,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.4017348357408546,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.4017348357408546,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3098975387316325,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.3098975387316318,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.3098975387316318,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.3098975387316312,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3098975387316314,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.3098975387316318,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.482735893591724,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.1941142924067245,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4238829592419098,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.42388295924191,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.42388295924191,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5232718910271876,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5232718910271874,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.523271891027187,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5232718910271872,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.2791623700500332,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2791623700500332,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.2791623700500332,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "MEX",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6942697761776906,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6942697761776906,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7717119304660125,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.8545125826307125,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.854512582630713,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5684784654360877,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5684784654360866,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.527900882595487,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7579351222183583,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.757935122218358,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7579351222183577,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.9099394925051905,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.9099394925051905,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.9099394925051905,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6383711373820855,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7492909725777943,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7492909725777954,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.549732369966576,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.8503760029643517,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.8781506929369949,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6086357617544935,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.7707269161669386,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.2588708480815585,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.6319085205936066,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.596173504958875,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6262063273782454,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.654671030059334,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5091067296100753,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5598796636106098,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.680825787462816,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6808257874628163,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6808257874628163,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.7463941973067154,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8364006376240152,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.836400637624015,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8364006376240152,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8364006376240147,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.8094650368406375,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8094650368406373,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.8094650368406373,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6156672943889585,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6156672943889585,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6156672943889585,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.7987935026358757,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 2.2116549083805546,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.9567890474101997,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7510824287839897,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7634427030266635,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.8333054168723,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.8333054168723009,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 2.0513490832962518,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 2.051349083296252,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 2.05134908329625,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.866038934370268,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 2.1154455675548687,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 2.115445567554868,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 2.3090586993388995,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 2.309058699338898,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 2.309058699338898,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.7097816966842276,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 2.408459783067857,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 2.408459783067857,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 2.408459783067857,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 2.408459783067857,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 2.408459783067857,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 2.4084597830678565,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 2.408459783067857,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 2.126558393918383,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 2.126558393918382,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 2.126558393918382,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 2.126558393918381,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 2.126558393918382,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 2.1265583939183816,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.8396592349660736,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3866962139939836,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6353755889055306,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6353755889055304,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6353755889055304,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 2.2780197591477003,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 2.2780197591477003,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 2.2780197591477003,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 2.2780197591477,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.7205281900256937,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.720528190025694,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.720528190025694,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "MLT",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.566243525534594,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5662435255345943,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.3516446142127556,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.5735865222347296,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.57358652223473,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5670875362958212,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5670875362958216,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5775822280646576,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.655727146436858,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.655727146436859,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6557271464368588,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7980002941949058,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.798000294194906,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7980002941949065,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5565464405600284,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6380503360950656,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6380503360950656,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.607853996945504,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5996708754070998,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5878372721907557,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5740585595969068,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5503453247200292,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5746605498132895,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.502257309896185,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.576712257559137,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5407606277458723,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5245059129859202,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5596846041825525,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4968734792625706,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.615261129797092,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.615261129797093,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.615261129797093,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5586016279171253,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5851458780021028,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5851458780021028,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5851458780021015,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5851458780021015,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5630350431607791,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5630350431607791,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5630350431607791,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5603536066253834,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5603536066253825,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.560353606625383,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.496671994296751,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4675997534333094,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.526770154714962,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5622072047630755,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.515874939874454,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6085162329910765,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.608516232991076,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7030846511504785,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7030846511504791,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.70308465115048,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.541978766823941,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.652603071186672,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6526030711866722,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.676408491751192,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.676408491751192,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.676408491751193,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.565167626743349,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6862961307982784,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.686296130798279,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6862961307982782,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6862961307982784,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.686296130798278,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.686296130798279,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.686296130798278,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6153658797415753,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.615365879741574,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6153658797415749,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6153658797415749,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6153658797415746,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6153658797415749,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5626045390712846,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.5281074380606163,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5693968896126107,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5693968896126111,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5693968896126107,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6431402517158262,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6431402517158264,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.643140251715827,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6431402517158262,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.633647119530886,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.633647119530886,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.633647119530886,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "MMR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.4642415563206204,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.4642415563206206,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.586445556806058,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.254155239909572,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.2541552399095717,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5214423188526485,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5214423188526487,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5151196799786495,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.892577913935839,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.892577913935839,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8925779139358396,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7702930584497398,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7702930584497392,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7702930584497392,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6419748686939335,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6570595850634895,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6570595850634895,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.604158606366578,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6141610940995292,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5794550906136653,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6360947587580288,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.620589713784255,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6176679455724017,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5905383846937624,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6230246968984352,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6185011101751126,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6259632134207849,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5947429266552546,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6545312028178285,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.59624532569483,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.59624532569483,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5962453256948304,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5439518642772894,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6283259927948923,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6283259927948923,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.628325992794893,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6283259927948932,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6013179078098174,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.601317907809818,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6013179078098174,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5769516418898406,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.576951641889841,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5769516418898404,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.605739547823394,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6571919254490914,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6425524576262864,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5952121873452083,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.602262275957046,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.759115486350197,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7591154863501977,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7036774928027048,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7036774928027052,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7036774928027043,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6290490028246587,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.667006115438895,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6670061154388949,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.7079161769429905,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7079161769429907,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.70791617694299,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4844197401105874,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6313852064233665,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.631385206423367,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6313852064233665,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6313852064233665,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.631385206423366,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6313852064233656,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6313852064233665,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.8949054161200607,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8949054161200607,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.8949054161200607,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.894905416120062,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.8949054161200625,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.8949054161200616,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5416979817859713,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.37033181528806,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6783348703905294,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6783348703905296,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.67833487039053,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.8234825007483129,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.823482500748312,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.823482500748312,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.823482500748312,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.592987864323394,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5929878643233946,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5929878643233946,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "MYS",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.7800774543891515,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.7800774543891518,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.559896979595281,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4116654168623046,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4116654168623053,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.671503617327475,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.671503617327475,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6733022454182007,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.926218205328024,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.926218205328024,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.926218205328024,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.819376958352297,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8193769583522972,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8193769583522963,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.726902481448834,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.866314058800648,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.8663140588006488,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.750668942556005,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.7810305201896175,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.819875275032328,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.745150031062818,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.7753279898576044,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.7562669568182427,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.702220737747426,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 2.0658022939655853,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.8640038324389332,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.730460482928615,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.7037561517288633,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.817919824241944,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.735043231516351,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.7350432315163509,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.735043231516351,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.67175444868235,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.858961770167565,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8589617701675631,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8589617701675636,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8589617701675631,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.8256267812767475,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8256267812767475,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.8256267812767464,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.8240954528321935,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.8240954528321929,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.824095452832193,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.812812504173459,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.828736825831996,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.738038209739583,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.7703745184403696,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.9483237190963187,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7865089664291132,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7865089664291127,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 2.11386658076143,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 2.11386658076143,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 2.113866580761429,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6796240549500434,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.9174977651137428,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.9174977651137437,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.763113811122991,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7631138111229916,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.763113811122992,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.7135460660912043,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.9794988856411049,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.9794988856411044,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.9794988856411044,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.9794988856411049,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.979498885641105,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.979498885641105,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.979498885641105,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.8564579199538715,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8564579199538715,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.856457919953872,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.8564579199538724,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.856457919953872,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.856457919953872,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.7418024426255083,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.5492993540500772,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4956585254315118,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4956585254315111,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.495658525431512,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.832570464511136,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.832570464511136,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.832570464511136,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.832570464511136,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.7412356143138423,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.7412356143138423,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.7412356143138423,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "NLD",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6443495403280877,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6443495403280872,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.6282763905021214,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.1362284252521329,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.1362284252521329,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5757028868330247,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.5757028868330247,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6059246366685704,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7679636396761875,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7679636396761882,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7679636396761875,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.634017954883099,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6340179548830989,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6340179548830984,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5628736757228865,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6420027339488084,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6420027339488084,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5757257653588206,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5751223722636316,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4578627747594903,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5724567172240924,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5825528534900724,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5469206725322866,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5159349131764994,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5269580145451227,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5746393427673202,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5712785381556122,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5712421401054162,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.559351315049606,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5482067343415518,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.548206734341552,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.548206734341552,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.290532039623106,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7671785103626874,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7671785103626874,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.7671785103626871,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.767178510362687,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7232106334303992,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7232106334303985,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7232106334303996,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5398826689498135,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5398826689498133,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5398826689498135,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4757944904767986,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5842638129780384,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.7267624495999578,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6866373925001217,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.525200129405043,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6879551504107575,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6879551504107568,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6094375983956029,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6094375983956035,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6094375983956029,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.56546784248842,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6270431904807623,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6270431904807627,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4889886706905366,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.4889886706905366,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4889886706905366,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5238126633037306,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.661604218943019,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.661604218943019,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6616042189430191,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.661604218943019,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.661604218943019,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6616042189430182,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.661604218943019,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6248050046339115,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6248050046339113,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6248050046339115,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6248050046339122,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6248050046339122,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6248050046339115,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4942971812619836,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2840943227007182,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3041132351382714,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.3041132351382718,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3041132351382716,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6268654760918142,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6268654760918142,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6268654760918146,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.626865476091814,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5682676928253874,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5682676928253874,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5682676928253874,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "NOR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.713620036232657,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.713620036232657,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7195277953527313,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.79112048803095,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.7911204880309506,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.2053878641611704,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.2053878641611704,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.990020237028947,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8557556545742748,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.855755654574275,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8557556545742748,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.8454827325523355,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.845482732552335,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8454827325523344,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.7136393929126252,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.730768421000095,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7307684210000958,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7777095433671095,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6891094529602475,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7970092193825895,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6503891459593727,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.7240913508939235,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.716702938188458,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.6548295501467076,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.7864997811659733,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.634257236838649,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6257067750890497,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.505005469732371,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6230483148784636,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6990215519560576,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6990215519560576,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.699021551956058,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.64349179884229,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8819066802937643,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8819066802937652,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8819066802937647,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8819066802937652,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.8611682358948596,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.86116823589486,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.8611682358948605,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.7449886256719196,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.7449886256719198,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.7449886256719191,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5930845301815957,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7605860786536165,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.718003421460226,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.546510251657684,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.6412181745440704,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7451849686377687,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7451849686377685,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.9180058671551907,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.9180058671551896,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.91800586715519,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6802135367925166,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.7014622702422155,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.7014622702422153,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.729461973221326,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.729461973221326,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.7294619732213263,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5298612190838907,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8168913602197967,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8168913602197976,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8168913602197976,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8168913602197976,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.816891360219796,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8168913602197967,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8168913602197976,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.871111204803636,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.8711112048036351,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.871111204803636,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.8711112048036351,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.8711112048036351,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.8711112048036351,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.7174347372436558,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.5366102966528343,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6221293712194862,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6221293712194864,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.622129371219486,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.8170940809064111,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.8170940809064111,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.817094080906411,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.817094080906411,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.777897994740921,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.7778979947409213,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.7778979947409213,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "NZL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.2391060354097994,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.239106035409798,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.3804315084133836,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4336512329950142,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.433651232995013,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.38334023769657,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.3833402376965698,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.1504527610919688,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.5939134544653115,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.593913454465312,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.5939134544653122,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.629966956375529,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6299669563755297,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6299669563755297,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.4230453065722193,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.4763585596485398,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.4763585596485398,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6148553448339868,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5570227783908137,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4830986639725936,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5192350765152176,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.447019797028641,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.669703169501236,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5243474085291655,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.402133667014999,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.4641200552202334,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4569073985797318,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.3673485319319787,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.3451457334619177,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.525266658864978,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5252666588649784,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.525266658864978,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.3922815581092531,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.396521277739663,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.3965212777396618,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.3965212777396618,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.3965212777396625,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.4266114233445435,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.4266114233445435,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.4266114233445435,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4025916966878578,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.402591696687858,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4025916966878578,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5068262685342202,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5418826963464478,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6401863383174238,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5725725627892253,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5192171551532232,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.4967479882206256,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.4967479882206256,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6931467071181316,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6931467071181323,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.693146707118132,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7133117535560565,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4915163971358516,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.491516397135852,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4204609405552338,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.420460940555234,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4204609405552344,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.1989043730049818,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6432753674568668,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6432753674568663,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6432753674568668,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6432753674568668,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6432753674568672,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6432753674568672,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6432753674568663,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4080168049870148,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4080168049870143,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4080168049870148,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4080168049870143,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4080168049870143,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4080168049870143,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4763686623192829,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.167969131850035,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4029477453018977,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4029477453018968,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.402947745301897,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.612106335578432,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6121063355784315,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6121063355784315,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6121063355784313,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.3412569053386056,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.341256905338606,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.341256905338606,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "PER",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.378777369830832,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.3787773698308317,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5297981290858782,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.248056946479964,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.248056946479965,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.342655296157357,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.3426552961573577,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5858097070591572,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6413603223597166,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.641360322359716,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6413603223597162,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6517093632528057,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6517093632528057,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6517093632528053,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5817501638214313,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.663247734794702,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6632477347947017,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5504239438037652,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6052971497826984,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.691922559134254,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.615259120989869,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.612178468024904,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.573220900581503,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5545699271373543,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5831113054424135,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.567725120132677,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5737725110310825,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6062450860117337,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6561805069600266,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.575293612099876,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5752936120998766,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5752936120998766,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.2835463390370845,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.3594472354187193,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.3594472354187197,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.35944723541872,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.3594472354187197,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5059418528536297,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5059418528536297,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.505941852853629,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4937138360454036,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4937138360454034,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.493713836045404,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4232702603610194,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4550102490540042,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6202614942977305,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.513845958582421,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4563169566334315,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6833363174793632,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.683336317479363,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6493581760032296,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6493581760032303,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.649358176003231,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4001393709898622,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5071401491702106,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.5071401491702103,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.497148006451654,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.4971480064516542,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4971480064516545,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.299430339847336,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5303728531778051,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.530372853177805,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5303728531778045,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.5303728531778051,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5303728531778054,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5303728531778054,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5303728531778047,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4758424618337975,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4758424618337975,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4758424618337982,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.475842461833798,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4758424618337984,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4758424618337975,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.3974609243829526,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2335010300040063,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.468417941366165,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.468417941366166,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.468417941366166,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6340616795232856,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6340616795232858,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6340616795232856,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6340616795232856,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4852460391292492,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4852460391292497,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4852460391292492,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "PHL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6592236773343594,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.65922367733436,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5545561903192209,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.392241951042518,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.3922419510425177,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.608699578809109,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.608699578809108,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.1075545720643962,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.790025358469757,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.790025358469756,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7900253584697567,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7647639119888816,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7647639119888818,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7647639119888812,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6333153603063355,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7495880970487814,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7495880970487816,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6983739455585072,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6847806349757115,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6685695316902027,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6354595790210489,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6415395553599137,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6997647218743737,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.619246940009811,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6339570226193552,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6956113499943282,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.642853100586647,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6175013100608029,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.7062251971517832,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.625277161349051,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.625277161349051,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.625277161349051,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.625082977132973,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.602881729875424,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.602881729875424,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6028817298754234,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6028817298754232,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6793684397090354,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.679368439709036,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.679368439709035,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5927978880118745,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5927978880118745,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.592797888011874,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.551941389508444,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7122794272604454,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.755531998206745,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6588085607985406,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5603467863237466,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7117239815833187,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7117239815833185,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7853093460019474,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7853093460019474,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7853093460019465,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7675828077286455,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6572578026151998,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6572578026152005,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.634267990769325,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6342679907693256,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6342679907693247,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5855031665846466,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.864540085646284,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8645400856462844,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8645400856462837,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.864540085646284,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8645400856462844,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8645400856462844,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8645400856462844,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7724892018825285,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7724892018825291,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.772489201882529,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7724892018825282,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.772489201882529,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7724892018825291,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.3620369883851096,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3713037911564223,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6021157118609313,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.6021157118609313,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6021157118609313,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.8012468656188256,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.8012468656188256,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.801246865618826,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.801246865618826,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5785567978390975,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5785567978390973,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5785567978390973,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "POL",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.657445229654465,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6574452296544646,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4620988917150224,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5483700034969663,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.548370003496967,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.753640097510597,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.798377266987252,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7983772669872526,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.798377266987253,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7273316520549005,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7273316520548998,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7273316520549005,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6133301216323652,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7256417380577422,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7256417380577425,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5190578673909088,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6804201954398486,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6247029323425866,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6161930505211546,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.616339633016634,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.8362951648608137,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5361301251407269,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.7974488759826317,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.647412756971303,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5890176567783496,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.612851831727384,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6305637262595,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6198036549618615,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6198036549618615,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6198036549618622,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.459326194253432,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8960757722204051,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.896075772220405,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8960757722204051,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.896075772220406,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6622532813759394,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6622532813759394,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.66225328137594,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.616215983785959,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6162159837859589,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6162159837859589,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.588666676573227,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.761840994051575,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6569143894282563,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.632821422509459,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.527257479948084,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6239733883301457,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.623973388330146,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.8237944928387573,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.8237944928387575,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.823794492838758,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.773095846629699,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.696858124183263,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6968581241832643,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6375861729703842,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6375861729703838,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.637586172970385,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2796361662911238,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.889040944343271,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8890409443432712,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8890409443432707,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8890409443432712,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8890409443432703,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8890409443432707,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8890409443432707,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6931141088071784,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6931141088071786,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.693114108807179,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6931141088071786,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.693114108807179,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6931141088071786,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4219198618640825,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2469118376959898,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5940013165613094,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5940013165613092,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5940013165613094,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7713024319257966,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7713024319257968,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7713024319257968,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.771302431925797,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.6089354779063794,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6089354779063794,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6089354779063791,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "PRT",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6796144865351972,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6796144865351976,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7293835335217695,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.620049105775199,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.6200491057751996,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.7030417765120949,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.7030417765120942,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7320605366042368,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.657004345426702,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6570043454267016,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6570043454267016,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.691771537249964,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6917715372499642,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6917715372499642,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.756491071125032,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7541731933125868,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7541731933125877,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7044117344699614,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5904736100367447,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.8023464416099595,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.69063238170825,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6127138624234,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5914901046212473,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.6103542621794165,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6667286275747215,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.768466845046435,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5758745534667458,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.636071020627074,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6423021140561262,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6164235545212278,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.616423554521227,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6164235545212278,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5789466546075963,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.8711895108981067,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8711895108981067,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8711895108981067,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8711895108981067,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.6119239245653227,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.611923924565323,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6119239245653223,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6644532232600708,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.6644532232600708,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6644532232600708,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.550715286751031,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4981568200046491,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.8990206655634685,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.8478986523395209,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.8475522551394488,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.684799725613518,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6847997256135188,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7169239743668026,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7169239743668032,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7169239743668026,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.755799684179019,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6271013385037385,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6271013385037378,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.501072881796872,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.501072881796872,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.501072881796872,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.368358548796317,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.839698004130488,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.839698004130488,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.839698004130488,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.839698004130488,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8396980041304871,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.839698004130488,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8396980041304871,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.767479919167969,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7674799191679684,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7674799191679689,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7674799191679698,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7674799191679686,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7674799191679695,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.251728501387213,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.318385469624313,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5213745837169788,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5213745837169792,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.521374583716979,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.755821135980652,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7558211359806526,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.755821135980652,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7558211359806524,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.8136218837954567,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.8136218837954563,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.8136218837954567,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ROU",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.4943933414371111,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.4943933414371118,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.424087317902346,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.2587124881024638,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.2587124881024645,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.3715347227930577,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.3715347227930583,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5693992716911431,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7055610327987487,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7055610327987492,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7055610327987485,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.669641255817132,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6696412558171323,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6696412558171316,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5576589004056876,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.602689686741028,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6026896867410276,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.4632779394872475,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5110108355305207,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6187282364802844,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5651020397196889,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.532466357229382,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5414886627467077,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.545054451866528,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5279458720025596,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5494144520606803,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.535686338575828,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5775756164270482,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5542670563838958,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5392652293369318,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5392652293369318,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5392652293369318,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.47261244770264,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6037764976406603,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6037764976406588,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6037764976406588,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6037764976406594,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5051792935219577,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5051792935219568,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5051792935219577,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4674149177925284,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.467414917792528,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.467414917792528,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4450010761806924,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5749769154402065,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.611422074078202,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5039654472082469,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.366256925016771,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6290337507260897,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6290337507260906,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7140086857936672,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7140086857936658,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.714008685793666,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4994480139870188,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4829960877587245,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.482996087758723,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.447588634472145,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.447588634472144,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4475886344721445,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3107707693030772,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6505445388050446,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.650544538805045,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6505445388050448,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6505445388050446,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6505445388050453,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6505445388050448,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6505445388050446,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3758284031255037,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.375828403125504,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.375828403125503,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.375828403125503,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3758284031255037,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.3758284031255037,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4574769764883984,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2606411605239567,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4007715041250053,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.400771504125005,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4007715041250046,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5456768079475347,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5456768079475345,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.545676807947534,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5456768079475345,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.502601493308806,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5026014933088057,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5026014933088057,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "RUS",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.3769340431108543,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.3769340431108557,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4397046238845577,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.0419183726206067,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.041918372620607,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.1308869706700846,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.130886970670084,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.1769797970307236,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.5590780127448212,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.5590780127448216,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.5590780127448216,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6520624842094975,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6520624842094973,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.652062484209497,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.549175114425995,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5980422543492003,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5980422543492006,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.536509384113334,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.4481463252224074,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.630518762295602,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5348950229505882,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5886464953031214,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5075651422731249,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4912093105207316,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.610951989680831,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5295496418739878,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.613190924569642,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.478929910843496,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.602494129045883,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.579965513270541,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5799655132705412,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5799655132705417,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4227255175371605,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.4062065129289834,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.4062065129289831,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.4062065129289838,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.406206512928984,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.596436944105134,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5964369441051338,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5964369441051334,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5003700460342888,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.5003700460342888,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.5003700460342886,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.434620808084744,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.580622179240004,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.4722527404433974,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3860689118846885,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5358976147822845,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.486495362172548,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.4864953621725483,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.5699465137367643,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.5699465137367643,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.5699465137367643,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4383975482928406,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5260756936618585,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.526075693661859,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.2362313873121171,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.236231387312118,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.2362313873121171,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.167353155296343,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.686843073406203,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6868430734062032,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6868430734062037,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6868430734062032,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.686843073406203,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.686843073406204,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6868430734062037,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6770406979402512,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.677040697940251,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6770406979402517,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6770406979402517,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.677040697940251,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6770406979402512,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.206214037389361,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2070307713728925,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4551655694475156,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.4551655694475156,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.4551655694475156,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.3168889452330004,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.316888945233,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.3168889452330004,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.316888945233,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.2507794345921726,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.2507794345921726,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.2507794345921726,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "SAU",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.7513160197518969,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.751316019751896,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.6464759872410482,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.9016919804599164,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.901691980459916,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.901691980459916,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.8347704901814768,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8347704901814772,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8347704901814768,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.656752561128465,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6739214016755304,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6739214016755295,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.5839687698365987,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6393348261568488,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7906466308280116,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6128138685955131,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6943690727376164,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.7245584000641068,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.6725065343821708,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.8789158616829005,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.7254655460054649,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.9356067866544344,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.8618384635644285,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6639369794249266,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.885160085496095,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.885160085496096,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.8851600854960957,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6434328439660797,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.865667954873184,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8656679548731827,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8656679548731825,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.8656679548731827,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.9504767037860011,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.950476703786001,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.950476703786001,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.738722141788509,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.738722141788509,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.7387221417885086,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.7142928736163805,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.7205694105374043,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6724073159282673,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6514692770695374,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5217439184990351,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7560801775733546,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7560801775733543,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 2.0252600176681725,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 2.025260017668172,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 2.025260017668171,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.776029942346503,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 2.0066799564646174,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 2.0066799564646183,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.8016947937270955,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.801694793727096,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.8016947937270968,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.7449321182308404,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.9492304223940689,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.949230422394068,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.9492304223940684,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.9492304223940689,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.949230422394068,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.949230422394068,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.9492304223940689,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7406053331792604,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7406053331792597,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7406053331792593,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.74060533317926,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7406053331792604,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7406053331792604,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.8413535085320225,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4402193783955086,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.650006944505425,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.650006944505425,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6500069445054253,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.714051540035805,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7140515400358054,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7140515400358054,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7140515400358058,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.698873555180028,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6988735551800276,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6988735551800276,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "SGP",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.564407608384715,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5644076083847156,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.616775482817574,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4450461588655787,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.445046158865578,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6431322885508348,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6431322885508348,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.3277724725475364,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.791765580556143,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.791765580556144,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7917655805561434,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.6658507922166426,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6658507922166428,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6658507922166423,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5306721432216024,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7355241902802394,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7355241902802396,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.735816575445763,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.634547730183683,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.735353942100662,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6352057253580816,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6305274994489067,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6233155022710484,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5519565580408254,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.685149580054624,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.607846498754777,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6078484798523496,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6456866449620111,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6294490492330642,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6927712837012372,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6927712837012376,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6927712837012372,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6681235018796263,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7067916974855744,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.706791697485574,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.706791697485574,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.706791697485574,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.7191648439183564,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7191648439183569,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7191648439183569,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.7128783956164375,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.7128783956164375,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.7128783956164373,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5481071886124411,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.5834632372051172,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.91720148992546,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6050525368419637,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.477483816861207,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6515315657551046,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.651531565755105,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.763385273830629,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7633852738306282,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7633852738306288,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.6064070907839672,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6879663880853848,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6879663880853848,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.7463831043445517,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7463831043445517,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.7463831043445517,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4806553386686787,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.8715911877556937,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.8715911877556943,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8715911877556943,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.8715911877556943,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8715911877556943,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8715911877556937,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8715911877556937,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.962214980950545,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.9622149809505447,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.9622149809505447,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.962214980950544,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.962214980950544,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.9622149809505445,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4564924573023024,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3616175736545335,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.52306181658675,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5230618165867509,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5230618165867502,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 2.1291232921387886,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 2.1291232921387886,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 2.1291232921387886,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 2.1291232921387886,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.6753101004849291,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.6753101004849296,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6753101004849291,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "SVK",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.59362410432791,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.593624104327911,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7831027243969042,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4655511261168324,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4655511261168324,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6945732214470477,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6945732214470477,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7909074892508443,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.817442730939575,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.817442730939575,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.817442730939575,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.81295116221312,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8129511622131191,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8129511622131187,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6494021460056765,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7396401589777588,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.739640158977759,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7438807603378208,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.658521860672613,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7823389029014167,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6784795824277492,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.665325221360396,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6953462520229277,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5989412305395274,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.6526653261575472,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.636381993454945,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6356560093370287,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6426540309109092,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6352730927086407,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.66336813353717,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.66336813353717,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.66336813353717,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6568460517304633,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.875530589986516,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.8755305899865156,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.8755305899865156,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.875530589986515,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.819560121649876,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.8195601216498756,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.819560121649876,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.7269540787335529,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.7269540787335529,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.726954078733553,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.58915781974151,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.355521191689047,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.8466582103716187,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5355964089891083,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4903324376857718,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7830848443963945,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7830848443963945,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.9104154305393035,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.910415430539304,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.910415430539304,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5584204424839982,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6074376630657168,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6074376630657166,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6375452810127202,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6375452810127198,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6375452810127198,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3388411282864034,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.860294987796574,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.860294987796573,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.8602949877965744,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.860294987796574,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.8602949877965749,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.8602949877965744,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.8602949877965744,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.753861194648522,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7538611946485216,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7538611946485223,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7538611946485223,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7538611946485223,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.753861194648522,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.543683647688522,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.447111897096381,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5142062156977532,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5142062156977532,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5142062156977532,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.8349357959963448,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.834935795996345,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.8349357959963448,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.8349357959963448,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.7342307939442931,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.7342307939442931,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.7342307939442931,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "SVN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.630380156113935,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6303801561139355,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.43764846247724,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.7480739251694872,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.748073925169487,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.4843971148432131,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.484397114843213,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.8694385492764047,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.74659297999734,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7465929799973394,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.746592979997339,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.640760817387399,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.6407608173873989,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.6407608173873982,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6848226818775784,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6789868728163069,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6789868728163064,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.801311789175739,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.669186705843401,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.7196181944778803,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6140503724412378,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.65470028592248,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.718552564490376,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5639437267448548,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5174103116178166,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.6600720049898416,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.599834344204553,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6890408080313255,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.6132346320620854,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6133623105000847,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6133623105000847,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6133623105000845,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5804111303422463,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7756095257903577,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7756095257903577,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.775609525790358,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.7756095257903577,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.700534520612951,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.7005345206129514,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.7005345206129512,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.623023818933242,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.623023818933241,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6230238189332415,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.545845404437337,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.6343220276758237,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.604842794544991,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.6866831313889985,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5521192954858156,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6725848305364368,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6725848305364366,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6252009097179902,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6252009097179891,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6252009097179905,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5375337653171055,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.662572303598407,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6625723035984072,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.385119179541801,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.3851191795418012,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.3851191795418007,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.571928337891421,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.7392682435048088,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.739268243504809,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7392682435048086,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.7392682435048088,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.73926824350481,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7392682435048092,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7392682435048092,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6823282089895848,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6823282089895848,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6823282089895848,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6823282089895848,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6823282089895846,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.682328208989585,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5074612075049358,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.3680981154213143,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.343294551779641,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.3432945517796413,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3432945517796415,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6628013127794563,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.662801312779456,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.662801312779456,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.662801312779456,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4763434601892893,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.476343460189289,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.476343460189289,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "SWE",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.3988863838922665,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.3988863838922665,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4444280938524128,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.6229878860171998,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.6229878860171991,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.3730434861342165,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.373043486134216,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5956797044280706,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6748735353872324,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6748735353872324,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6748735353872322,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7452768153416467,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7452768153416474,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7452768153416471,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.5391649061104538,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6446088550394844,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6446088550394844,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7064824285887443,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.53991021881444,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5816271757425344,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.57749910081276,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5685658641756473,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5020417816433165,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4766549422187727,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5088177124124988,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.501116385404968,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.485025745249838,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5122434704697507,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4826051751205334,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.6979388793010315,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6979388793010322,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6979388793010315,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5377991041818837,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.393822265337516,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.3938222653375163,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.3938222653375163,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.3938222653375167,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5638794211633946,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5638794211633946,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5638794211633953,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.298559809066136,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.2985598090661363,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2985598090661366,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5069985382937587,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4602055535156802,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6493039043885465,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3845354300790254,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3464569721679749,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6553909747972528,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6553909747972535,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7496969208229325,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7496969208229345,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.749696920822934,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4286339647666717,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.731342547852177,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.7313425478521762,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.4517482874182663,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.4517482874182663,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.4517482874182663,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.320966429352554,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.7958872702231294,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.7958872702231292,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7958872702231292,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.79588727022313,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.79588727022313,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7958872702231286,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7958872702231294,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7249629155142217,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7249629155142217,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7249629155142212,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.724962915514221,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7249629155142212,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.7249629155142212,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4521951697028253,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.306711651884675,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.4299071610087035,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.429907161008704,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.429907161008704,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.5146974725671478,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.5146974725671478,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.514697472567148,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.5146974725671474,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5391274662027894,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5391274662027892,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5391274662027894,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "THA",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.334107038386402,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.3341070383864015,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.2094803704330934,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.43717451219397,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4371745121939703,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5897336979744443,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.589733697974445,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6298705567314375,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6312078417385352,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6312078417385356,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6312078417385352,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7287448366993432,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.728744836699344,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7287448366993436,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.496948327587316,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.5959198040233815,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.595919804023382,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.7155763639533097,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5759140147101096,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.4731534185540731,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5133809568736376,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.4735849675909023,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5504180934192524,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4945357738075815,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5398231693738078,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5285424329577155,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5122283307849351,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.504247802687611,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.509825591018939,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5224446702071506,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.522444670207151,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5224446702071506,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.6506046300625523,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.509559273029078,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5095592730290797,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.5095592730290783,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.50955927302908,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.511035854959793,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.511035854959794,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.511035854959793,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.2230083731926285,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.223008373192629,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.2230083731926285,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.3598940613852786,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.4608259471953162,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5674511011344692,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.3018011886220144,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.2802148047584767,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.4308817714303903,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.4308817714303903,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6000098135048733,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6000098135048737,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6000098135048737,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.2128063510486584,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.4051362106825531,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.405136210682553,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.2449986687426564,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.2449986687426564,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.2449986687426569,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.2668582509188542,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.4972985939385894,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.4972985939385897,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.4972985939385897,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.4972985939385897,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.4972985939385897,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.4972985939385903,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.49729859393859,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4214286822853877,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.421428682285388,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4214286822853874,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.4214286822853863,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4214286822853872,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.4214286822853874,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.1633379525413836,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.2258694907206773,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.3807189288038917,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.3807189288038917,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3807189288038917,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.605200324684479,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6052003246844793,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.605200324684479,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.605200324684479,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4632895430266455,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4632895430266455,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.463289543026646,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "TUN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.5122325540408563,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5122325540408563,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4542009963889782,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4695988297232387,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4695988297232383,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.3276283805298366,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.3276283805298366,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.4908671271851568,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6827801723919509,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.6827801723919509,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6827801723919509,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7024482916666006,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7024482916665995,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7024482916665995,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.538770331604349,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.597342563054358,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.5973425630543578,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.658362599420486,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.4764308890997848,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.591652169218075,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5030254337209443,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.516113934974975,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.624066273854785,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4437133516541028,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.3773579543565486,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5621594407801762,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4763849067888586,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.473605691401711,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.2441011689562511,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5398395638491595,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5398395638491589,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5398395638491589,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5384574808700149,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6537938048370016,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.6537938048370024,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6537938048370011,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.6537938048370011,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.61320561401869,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.61320561401869,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6132056140186897,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.4273247594984535,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.4273247594984542,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.4273247594984544,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.418286654545926,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.3084638842428282,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.4193586959236455,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.2602302792640068,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5122256167213963,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.5276273176805537,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.5276273176805524,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7202958237769244,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.720295823776926,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7202958237769244,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.600724657008717,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.1983800632537782,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.1983800632537784,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.5289071877155778,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.5289071877155778,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.528907187715577,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.426036470536573,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6583148602268534,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6583148602268534,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6583148602268534,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6583148602268534,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.6583148602268527,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6583148602268538,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.6583148602268538,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.4561507057228382,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.4561507057228391,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.4561507057228391,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.456150705722839,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.4561507057228384,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.456150705722839,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4505552839339764,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.1769700333951825,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.587608806638043,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5876088066380427,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5876088066380432,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.651813392596051,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.651813392596051,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.651813392596051,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.651813392596051,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.627338339931101,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.627338339931101,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6273383399311006,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "TUR",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.522398378725226,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.5223983787252264,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.5043876428044336,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4534969540127995,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4534969540127998,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.493040702790982,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.4930407027909824,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5404834487753662,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.6892167679658183,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.689216767965818,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.6892167679658177,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7624906182686757,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.762490618268675,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7624906182686753,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.549887517879957,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6635476508851152,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6635476508851157,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.645226805761827,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.5374574910207726,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5666346984018138,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.5080212180448438,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.5624773370929188,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.5648323787541547,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.4705500241555436,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.4787094270539265,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5120692556911877,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.49159843797544,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.4760145173449273,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4891786165762693,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.546117060853125,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.5461170608531252,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.546117060853125,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.5797228474137948,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.6710733912693785,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.671073391269379,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6710733912693794,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.671073391269379,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.535189867227247,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.5351898672272477,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5351898672272473,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.3213690491994687,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.321369049199469,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.3213690491994683,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.4375458120006552,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.584267794994142,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.5765309492227937,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.4154466261192589,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.3984920153824223,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.512298738938778,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.512298738938778,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.6797603915010777,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.6797603915010764,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6797603915010773,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.4465466457071128,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.375629441905477,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.375629441905478,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.3859474422511864,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.385947442251187,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.3859474422511868,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.3287129797255062,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5291309079329451,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5291309079329451,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5291309079329451,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.5291309079329451,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5291309079329456,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.529130907932945,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.5291309079329456,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.3490914124608813,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.3490914124608813,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.3490914124608813,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.3490914124608815,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.3490914124608806,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.349091412460881,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.2783886852528776,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.183422412027857,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.368241030780744,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.3682410307807444,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.3682410307807442,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.4451227080867208,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.4451227080867206,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.445122708086721,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.4451227080867206,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.4168162616298554,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.4168162616298556,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.4168162616298554,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "TWN",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6601579331379763,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6601579331379772,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.3042574989569429,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.634457238441578,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.6344572384415772,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6469238654576224,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.646923865457622,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.6534847400957928,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.814277267106685,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8142772671066851,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.814277267106685,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.7322073525369537,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7322073525369528,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7322073525369528,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6474514723609952,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.696710666688356,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6967106666883554,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.6779079936733907,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6381342026767691,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.5948122574784895,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6560858737048585,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.6185533213367005,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6663388882231955,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.654756447365351,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.5096898214937742,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5334627794553386,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.6518537926075856,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.6591649312882837,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5530909780209277,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.612733341347948,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.612733341347948,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6127333413479474,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.531788399900223,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.660941426246611,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.660941426246611,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.6609414262466102,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.660941426246611,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.5633427618436515,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.563342761843651,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.5633427618436508,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.6907043195936946,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.690704319593695,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.6907043195936948,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.6659571326633056,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.8785660927507195,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6367749142929044,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.584805493187185,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.4792264300374245,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7318876614004182,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.731887661400419,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.683736711333133,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.683736711333134,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.6837367113331332,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7159933134344088,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6473047026824528,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6473047026824514,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.7283770270986138,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.7283770270986134,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.7283770270986134,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5277536687277893,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.6543208188903258,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.6543208188903258,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.6543208188903251,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.6543208188903256,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.654320818890325,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.6543208188903258,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.654320818890325,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.7061567184064432,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.7061567184064428,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.7061567184064432,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.7061567184064432,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.7061567184064428,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.706156718406444,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5639142709081937,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.358423705231799,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.6452039231350883,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.645203923135088,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.6452039231350883,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6114965528113383,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.6114965528113387,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.611496552811338,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6114965528113374,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.6385279262410348,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.638527926241035,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.6385279262410353,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "USA",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.6654713969247323,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6654713969247317,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.7630972371535685,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.4739712814700536,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.4739712814700534,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.6292489491524154,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.6292489491524154,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.7971899348817846,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.8700094194741927,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.8700094194741927,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.8700094194741936,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.862100920465705,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.8621009204657053,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.8621009204657053,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6936822039732344,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.7485465464134875,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.7485465464134873,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.610575994543348,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.6559677873468912,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.6043832415291448,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.579347557315114,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.558535782531202,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.515107418140677,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5358817547341532,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.540627054287362,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.50094607295892,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.4915227164297606,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.5207622807698509,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.5608405769751157,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.5975592299513204,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.597559229951321,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.5975592299513208,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.4255397453955063,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.5576989123058016,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.5576989123058025,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.557698912305802,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.5576989123058014,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.595764109580902,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.595764109580902,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.595764109580902,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.5527944650932988,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.552794465093298,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.552794465093298,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.522187590252411,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.558337587088777,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6731345739181005,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5595886672852854,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.5470929549286625,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.7418119680326651,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.7418119680326645,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.764241401866247,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7642414018662476,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7642414018662465,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.5299653421148125,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.5096107454787666,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.509610745478766,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.6877538031269113,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6877538031269117,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6877538031269113,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.5649261093906155,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.7069230146175585,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.7069230146175582,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.7069230146175585,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.706923014617559,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.7069230146175578,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.7069230146175585,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.7069230146175585,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6244246318760127,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6244246318760127,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6244246318760136,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6244246318760127,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.624424631876013,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.6244246318760132,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.5838400566386903,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4775688518134644,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5045527152840137,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5045527152840137,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5045527152840137,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.6350878720096598,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.63508787200966,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.6350878720096602,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.6350878720096598,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.5388165609925284,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.5388165609925288,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.5388165609925286,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "VNM",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 1,
		MULT: 1.608238092148806,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 2,
		MULT: 1.6082380921488062,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 3,
		MULT: 1.4461819970316558,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 5,
		MULT: 1.50012470223573,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 6,
		MULT: 1.5001247022357302,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 7,
		MULT: 1.5676426478841043,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 8,
		MULT: 1.567642647884104,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 9,
		MULT: 1.5446220584120256,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 10,
		MULT: 1.7261720307113118,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 11,
		MULT: 1.7261720307113118,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 12,
		MULT: 1.7261720307113122,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 13,
		MULT: 1.711018041003094,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 14,
		MULT: 1.7110180410030935,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 15,
		MULT: 1.7110180410030935,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 16,
		MULT: 1.6448780710038646,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 17,
		MULT: 1.6777717716333085,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 18,
		MULT: 1.6777717716333085,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 19,
		MULT: 1.691786562564255,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 20,
		MULT: 1.646436187999068,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 21,
		MULT: 1.70592646817589,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 22,
		MULT: 1.6446399992735674,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 23,
		MULT: 1.625681551104087,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 24,
		MULT: 1.6525394288467308,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 25,
		MULT: 1.5694628334182634,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 26,
		MULT: 1.61826083043725,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 27,
		MULT: 1.5859148470748428,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 28,
		MULT: 1.5618842542236515,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 29,
		MULT: 1.636718696750509,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 30,
		MULT: 1.4681220451726282,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 31,
		MULT: 1.659480011748711,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 32,
		MULT: 1.6594800117487114,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 33,
		MULT: 1.6594800117487112,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 35,
		MULT: 1.536522481980788,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 36,
		MULT: 1.7968789299926138,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 37,
		MULT: 1.7968789299926142,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 38,
		MULT: 1.796878929992615,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 39,
		MULT: 1.796878929992615,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 41,
		MULT: 1.687381577823447,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 42,
		MULT: 1.6873815778234478,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 43,
		MULT: 1.6873815778234478,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 45,
		MULT: 1.52829883868407,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 46,
		MULT: 1.52829883868407,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 47,
		MULT: 1.52829883868407,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 49,
		MULT: 1.5818813666002591,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 50,
		MULT: 1.3920757313144183,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 51,
		MULT: 1.6391511919197237,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 52,
		MULT: 1.5972980443992075,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 53,
		MULT: 1.7877864109655688,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 55,
		MULT: 1.6041050382866613,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 56,
		MULT: 1.6041050382866613,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 58,
		MULT: 1.7660449800443856,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 59,
		MULT: 1.7660449800443867,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 60,
		MULT: 1.7660449800443858,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 61,
		MULT: 1.7985927483941746,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 62,
		MULT: 1.6094957917211223,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 63,
		MULT: 1.6094957917211228,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 64,
		MULT: 1.676988975347206,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 65,
		MULT: 1.6769889753472058,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 66,
		MULT: 1.6769889753472065,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 68,
		MULT: 1.4548259948420323,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 69,
		MULT: 1.5579920100599989,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 70,
		MULT: 1.5579920100599984,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 71,
		MULT: 1.5579920100599982,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 72,
		MULT: 1.5579920100599982,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 73,
		MULT: 1.5579920100599989,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 74,
		MULT: 1.5579920100599982,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 75,
		MULT: 1.557992010059999,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 77,
		MULT: 1.6227840579872086,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 78,
		MULT: 1.6227840579872077,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 79,
		MULT: 1.6227840579872077,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 80,
		MULT: 1.6227840579872084,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 81,
		MULT: 1.6227840579872084,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 82,
		MULT: 1.622784057987208,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 84,
		MULT: 1.4587180565839784,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 85,
		MULT: 1.4001214341040529,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 86,
		MULT: 1.5474341353109242,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 87,
		MULT: 1.5474341353109249,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 88,
		MULT: 1.5474341353109244,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 90,
		MULT: 1.7472823870700072,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 91,
		MULT: 1.7472823870700076,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 92,
		MULT: 1.7472823870700074,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 93,
		MULT: 1.7472823870700072,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 94,
		MULT: 1.7220756391698242,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 95,
		MULT: 1.7220756391698242,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 96,
		MULT: 1.7220756391698244,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 97,
		MULT: 1,
	},
	{
		LOCATION: "ZAF",
		YEAR: 2018,
		INDUSTRY: 98,
		MULT: 1,
	},
];

export default { Industries, Countries, Regions, Multipliers };
