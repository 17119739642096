import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./Home.jsx";
import NatRegMults from "./NatRegMults.jsx";
import CountryComp from "./CountryComp.jsx";
import LocalMults from "./LocalMults.jsx";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import "../css/index.css";
import icon from "../images/favicon.ico";

function App() {
	useEffect(() => {
		const favicon = document.getElementById("favicon");
		favicon.setAttribute("href", icon);
	}, []);

	return (
		<div>
			<Header />
			<Routes>
				<Route path="*" element={<Home />} />
				<Route
					path="national-regional-multipliers"
					element={<NatRegMults />}
				/>
				<Route path="country-comparison" element={<CountryComp />} />
				<Route path="local-multipliers" element={<LocalMults />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
