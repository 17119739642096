import React from "react";
import ImpactEstimatorLogo from "../images/logos/impact_estimator_wide.svg";
import { useNavigate } from "react-router-dom";
import "../css/Header.css";

function Header() {
	let navigate = useNavigate();

	return (
		<div>
			<div className="header-container">
				<div className="logo-container">
					<a href="/">
						<img
							alt="Impact Estimator Logo"
							src={ImpactEstimatorLogo}
							className="logo-image"
						/>
					</a>
				</div>
				<div className="tabs-container">
					<button
						className={
							!window.location.href.endsWith(
								"/national-regional-multipliers"
							)
								? "header-button"
								: "header-button-active"
						}
						onClick={() =>
							navigate("/national-regional-multipliers")
						}
					>
						National and Regional Multipliers
					</button>

					<button
						className={
							!window.location.href.endsWith(
								"/country-comparison"
							)
								? "header-button"
								: "header-button-active"
						}
						onClick={() => navigate("/country-comparison")}
					>
						Country Comparison
					</button>
					<button
						className={
							!window.location.href.endsWith("/local-multipliers")
								? "header-button"
								: "header-button-active"
						}
						onClick={() => navigate("/local-multipliers")}
					>
						Local Multipliers
					</button>
				</div>
				<div className="header-border" />
				<div className="by-alma">
					<a
						href="https://www.almaeconomics.com/"
						target="_blank"
						rel="noreferrer"
					>
						by alma economics
					</a>
				</div>
			</div>
			<div className="header-container-mobile">
				<a href="/" className="logo-image-mobile">
					<img
						alt="Impact Estimator Logo"
						src={ImpactEstimatorLogo}
					/>
				</a>
				<input className="menu-btn" type="checkbox" id="menu-btn" />
				<label className="menu-icon" for="menu-btn">
					<span className="navicon"></span>
				</label>
				<ul className="menu">
					<li>
						<a href="/national-regional-multipliers">
							National and Regional Multipliers
						</a>
					</li>
					<li>
						<a href="/country-comparison">Country Comparison</a>
					</li>
					<li>
						<a href="/local-multipliers">Local Multipliers</a>
					</li>
				</ul>
				<div className="header-border" />
				<div className="by-alma">by alma economics</div>
			</div>
		</div>
	);
}

export default Header;
