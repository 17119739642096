import React from "react";
import { useNavigate } from "react-router-dom";
import LogoFrame1 from "../images/animated logo/logo slides 01.svg";
import LogoFrame2 from "../images/animated logo/logo slides 02.svg";
import LogoFrame3 from "../images/animated logo/logo slides 03.svg";
import LogoFrame4 from "../images/animated logo/logo slides 04.svg";
import LogoFrame5 from "../images/animated logo/logo slides 05.svg";
import LogoFrame6 from "../images/animated logo/logo slides 06.svg";
import LogoFrame7 from "../images/animated logo/logo slides 07.svg";

import "../css/Home.css";
import "../css/logo_animation.css";

function Home() {
	let navigate = useNavigate();

	return (
		<div className="page-container">
			<div className="top-container">
				<div className="left-container">
					<div id="page-moto">Total effect</div>
					<div id="page-subtitle">
						Impact Estimator is a tool for calculating regional and
						national impacts from projects and activities based on
						input-output multipliers, an approach widely used by
						policymakers and economists.
					</div>
				</div>
				<div className="right-container">
					<img alt="Logo Frame 1" src={LogoFrame1} />
					<img alt="Logo Frame 2" src={LogoFrame2} />
					<img alt="Logo Frame 3" src={LogoFrame3} />
					<img alt="Logo Frame 4" src={LogoFrame4} />
					<img alt="Logo Frame 5" src={LogoFrame5} />
					<img alt="Logo Frame 6" src={LogoFrame6} />
					<img alt="Logo Frame 7" src={LogoFrame7} />
				</div>
			</div>
			<div className="middle-container">
				These multipliers are calculated for individual sectors within
				an economy based on national input-output tables, which quantify
				how sectors produce goods/services and trade with each other. We
				then can use the multipliers to calculate the total economic
				impact of the project or activity, as the multiplier tells us
				how much an increase in one sector's output impacts all other
				sectors: <b> direct effects </b> include the purchase of goods
				and services by the sector itself,
				<b> indirect effects </b> capture spillover effects along the
				sector supply chain and <b>induced effects </b> capture the
				impact of increased household consumption.
			</div>

			<div className="middle-container-mobile">
				These multipliers are calculated for individual sectors within
				an economy based on national input-output tables, which quantify
				how sectors produce goods/services and trade with each other. We
				then can use the multipliers to calculate the total economic
				impact of the project or activity, as the multiplier tells us
				how much an increase in one sector's output impacts all other
				sectors:
				<b> direct effects</b> include the purchase of goods and
				services by the sector itself, <b> indirect effects</b> capture
				spillover effects along the sector supply chain and
				<b> induced effects</b> capture the impact of increased
				household consumption.
			</div>
			<div className="bottom-container">
				<div id="available-tools-title">AVAILABLE TOOLS</div>
				<div className="button-container">
					<button
						onClick={() =>
							navigate("/national-regional-multipliers")
						}
					>
						National and Regional Multipliers
					</button>

					<button onClick={() => navigate("/country-comparison")}>
						Country Comparison
					</button>
					<button onClick={() => navigate("/local-multipliers")}>
						Local Multipliers
					</button>
				</div>
			</div>
		</div>
	);
}

export default Home;
