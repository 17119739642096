import React, { useState, useEffect } from "react";
import {
	TableBody,
	TableCell,
	Table,
	TableRow,
	Paper,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Autocomplete,
} from "@mui/material";
import "../css/LocalMults.css";
import { NumericFormat } from "react-number-format";
import TTWA from "../data/ttwa.json";
import * as XLSX from "xlsx";
import ExportIcon from "../images/icons/export.svg";

function LocalMults() {
	const employmentMultiplierChoices = {
		Tradable: [0.9, 0.4],
		"High tech tradable": 1.9,
		"High skilled tradable": 2.6,
		"Public sector": 0.25,
	};

	const [employmentMultiplierLabel, setEmploymentMultiplierLabel] =
		useState();

	const [percentage, setPercentage] = useState(new Array(2).fill());
	const [numberCreated, setNumberCreated] = useState(new Array(2).fill());
	const [numberSubstituted, setNumberSubstituted] = useState(
		new Array(2).fill()
	);
	const [numberDisplaced, setNumberDisplaced] = useState(new Array(2).fill());

	const [numberCreatedTotal, setNumberCreatedTotal] = useState();
	const [numberSubstitutedTotal, setNumberSubstitutedTotal] = useState();
	const [numberDisplacedTotal, setNumberDisplacedTotal] = useState();
	const [netJobCreation, setNetJobCreation] = useState(new Array(3).fill());
	const [directEmploymentEffects, setDirectEmploymentEffects] = useState(
		new Array(3).fill()
	);
	const [indirectEmploymentEffects, setIndirectEmploymentEffects] = useState(
		new Array(3).fill()
	);
	const [totalEmploymentEffect, setTotalEmploymentEffect] = useState(
		new Array(3).fill()
	);

	const [TTWAUser, setTTWAUser] = useState(new Array(2).fill(""));

	const menuItemsStyle = {
		"&:hover": {
			backgroundColor: "#fcee21 !important",
		},
		"&:focus": {
			backgroundColor: "#fcee21 !important",
		},
		"&.Mui-selected": {
			backgroundColor: "#fcee21 !important",
		},
	};

	const [mobile, setMobile] = useState(() => {
		if (window.innerWidth > 1300) {
			return false;
		}
		return true;
	});

	useEffect(() => {
		const handleWindowResize = () => {
			if (window.innerWidth > 1300) {
				setMobile(false);
			} else {
				setMobile(true);
			}
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	});

	useEffect(() => {
		let totalEmploymentEffectTemp = new Array(3).fill();

		for (let i = 0; i < totalEmploymentEffectTemp.length; i++) {
			totalEmploymentEffectTemp[i] =
				directEmploymentEffects[i] +
				(Array.isArray(indirectEmploymentEffects[i])
					? indirectEmploymentEffects[i][2]
					: indirectEmploymentEffects[i]);
		}
		setTotalEmploymentEffect(totalEmploymentEffectTemp);
	}, [directEmploymentEffects, indirectEmploymentEffects]);

	useEffect(() => {
		let indirectEmploymentEffectsTemp = new Array(3).fill();
		for (let i = 0; i < netJobCreation.length - 1; i++) {
			if (employmentMultiplierLabel !== "Tradable") {
				indirectEmploymentEffectsTemp[i] = !isNaN(
					employmentMultiplierChoices[employmentMultiplierLabel] *
						directEmploymentEffects[i]
				)
					? Math.round(
							employmentMultiplierChoices[
								employmentMultiplierLabel
							] * directEmploymentEffects[i]
					  )
					: 0;
			} else {
				indirectEmploymentEffectsTemp[i] = !isNaN(netJobCreation[i])
					? [
							Math.round(
								employmentMultiplierChoices[
									employmentMultiplierLabel
								][0] * directEmploymentEffects[i]
							),
							Math.round(
								employmentMultiplierChoices[
									employmentMultiplierLabel
								][1] * directEmploymentEffects[i]
							),
							Math.round(
								(employmentMultiplierChoices[
									employmentMultiplierLabel
								][0] +
									employmentMultiplierChoices[
										employmentMultiplierLabel
									][1]) *
									directEmploymentEffects[i]
							),
					  ]
					: [0, 0, 0];
			}
		}

		if (employmentMultiplierLabel !== "Tradable") {
			indirectEmploymentEffectsTemp[
				indirectEmploymentEffectsTemp.length - 1
			] = Math.round(
				indirectEmploymentEffectsTemp[0] +
					indirectEmploymentEffectsTemp[1]
			);
		} else {
			indirectEmploymentEffectsTemp[
				indirectEmploymentEffectsTemp.length - 1
			] = [
				indirectEmploymentEffectsTemp[0][0] +
					indirectEmploymentEffectsTemp[1][0],
				indirectEmploymentEffectsTemp[0][1] +
					indirectEmploymentEffectsTemp[1][1],
				indirectEmploymentEffectsTemp[0][2] +
					indirectEmploymentEffectsTemp[1][2],
			];
		}
		setIndirectEmploymentEffects(indirectEmploymentEffectsTemp);
	}, [employmentMultiplierLabel, directEmploymentEffects]);

	useEffect(() => {
		let directEmploymentEffectsTemp = [];
		for (let i = 0; i < netJobCreation.length - 1; i++) {
			directEmploymentEffectsTemp.push(
				!isNaN(
					Math.round(
						(percentage[0] / 100) * netJobCreation[i] +
							(percentage[1] / 100) *
								netJobCreation[netJobCreation.length - 2 - i]
					)
				)
					? Math.round(
							(percentage[0] / 100) * netJobCreation[i] +
								(percentage[1] / 100) *
									netJobCreation[
										netJobCreation.length - 2 - i
									]
					  )
					: 0
			);
		}
		directEmploymentEffectsTemp.push(
			!isNaN(
				directEmploymentEffectsTemp[0] + directEmploymentEffectsTemp[1]
			)
				? directEmploymentEffectsTemp[0] +
						directEmploymentEffectsTemp[1]
				: 0
		);
		setDirectEmploymentEffects(directEmploymentEffectsTemp);
	}, [percentage, netJobCreation]);

	useEffect(() => {
		let numberCreatedTotalTemp = 0;
		for (let i = 0; i < numberCreated.length; i++) {
			if (!isNaN(numberCreated[i])) {
				numberCreatedTotalTemp =
					numberCreatedTotalTemp + numberCreated[i];
			}
		}
		setNumberCreatedTotal(numberCreatedTotalTemp);

		let numberSubstitutedTotalTemp = 0;
		for (let i = 0; i < numberSubstituted.length; i++) {
			if (!isNaN(numberSubstituted[i])) {
				numberSubstitutedTotalTemp =
					numberSubstitutedTotalTemp + numberSubstituted[i];
			}
		}
		setNumberSubstitutedTotal(numberSubstitutedTotalTemp);

		let numberDisplacedTotalTemp = 0;
		for (let i = 0; i < numberDisplaced.length; i++) {
			if (!isNaN(numberDisplaced[i])) {
				numberDisplacedTotalTemp =
					numberDisplacedTotalTemp + numberDisplaced[i];
			}
		}
		setNumberDisplacedTotal(numberDisplacedTotalTemp);

		let netJobCreationTemp = new Array(3).fill(0);
		for (let i = 0; i < netJobCreation.length - 1; i++) {
			netJobCreationTemp[i] =
				(!isNaN(numberCreated[i]) ? numberCreated[i] : 0) -
				(!isNaN(numberSubstituted[i]) ? numberSubstituted[i] : 0) -
				(!isNaN(numberDisplaced[i]) ? numberDisplaced[i] : 0);
		}
		netJobCreationTemp[netJobCreationTemp.length - 1] =
			numberCreatedTotalTemp -
			numberSubstitutedTotalTemp -
			numberDisplacedTotalTemp;
		setNetJobCreation(netJobCreationTemp);
	}, [numberCreated, numberSubstituted, numberDisplaced]);

	const handleChangeEmploymentMultiplier = (e) => {
		setEmploymentMultiplierLabel(e.target.value);
	};

	return (
		<div className="page-container">
			<div className="page-information-container">
				<div className="page-title">Local Multipliers</div>
				<div className="info-divider" />
				<div className="info-description">
					Use this calculator to estimate the local employment impact
					of economic development spending based on the sector of the
					investment project and adjusted for leakage, displacement
					and substitution. Multipliers align with HM Treasury Green
					Book best practice for place-based analysis and are based on
					the multiplier effects{" "}
					<a
						href="https://whatworksgrowth.org/resource-library/toolkit-local-multipliers/"
						target="_blank"
						rel="noopener noreferrer"
					>
						toolkit
					</a>{" "}
					published by the What Works Centre for Local Economic
					Growth.
				</div>
			</div>

			<div className="user-initial-input">
				<div id="enter-inv-data">
					Enter the data of your investment:
				</div>
				<div className="first-row-input">
					<FormControl size="small">
						<InputLabel id="employment-multipliers-select-label">
							Employment Multiplier
						</InputLabel>

						<Select
							sx={{
								width: mobile ? "100%" : 230,
								backgroundColor: "white !important",
								// ".MuiSvgIcon-root ": {
								// 	borderColor: "black !important",
								// 	fill: "white !important",
								// },
							}}
							label="Employment Multiplier"
							labelId="employment-multipliers-select"
							id="employment-multipliers-select"
							value={employmentMultiplierLabel}
							onClose={(event) =>
								handleChangeEmploymentMultiplier(event)
							}
						>
							{Object.keys(employmentMultiplierChoices).map(
								(choice) => (
									<MenuItem
										sx={menuItemsStyle}
										value={choice}
									>
										{choice}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
					<FormControl size="small" id="middle-input">
						<Autocomplete
							disableClearable
							sx={{
								width: mobile ? "100%" : 230,
								backgroundColor: "white !important",
								"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
									{
										backgroundColor: "#fcee21 !important",
									},
								"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
									{
										backgroundColor: "#fcee21 !important",
									},
							}}
							PaperComponent={({ children }) => (
								<Paper
									sx={{
										"& li": menuItemsStyle,
									}}
								>
									{children}
								</Paper>
							)}
							size="small"
							disablePortal
							id="First_TTWA_selection"
							options={TTWA["TTWA"]}
							renderInput={(params) => (
								<TextField
									{...params}
									sx={{}}
									label="First TTWA"
								/>
							)}
							onClose={(event) => {
								setTTWAUser([
									event.target.innerText,
									TTWAUser[1],
								]);
							}}
						/>
					</FormControl>
					<FormControl size="small">
						<Autocomplete
							disableClearable
							sx={{
								width: mobile ? "100%" : 230,
								backgroundColor: "white !important",

								"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
									{
										backgroundColor: "#fcee21 !important",
									},
								"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
									{
										backgroundColor: "#ffe600 !important",
									},
							}}
							PaperComponent={({ children }) => (
								<Paper
									sx={{
										"& li": menuItemsStyle,
									}}
								>
									{children}
								</Paper>
							)}
							size="small"
							disablePortal
							id="Second_TTWA_selection"
							options={TTWA["TTWA"]}
							renderInput={(params) => (
								<TextField {...params} label="Second TTWA" />
							)}
							onClose={(event) => {
								setTTWAUser([
									TTWAUser[0],
									event.target.innerText,
								]);
							}}
						/>
					</FormControl>
				</div>
				<div className="percentage-container" id="first-percentage">
					<div className="percentage-label">
						Percentage of jobs in{" "}
						{TTWAUser[0] !== "" ? TTWAUser[0] : "..."} filled by{" "}
						{TTWAUser[0] !== "" ? TTWAUser[0] : "..."} residents
					</div>

					<FormControl sx={{ width: mobile ? "100%" : "230px" }}>
						<NumericFormat
							sx={{
								width: mobile ? "100%" : "230px",
								backgroundColor: "white",
							}}
							id="id-percentage-1"
							// label="Percentage of jobs in {TTWAUser[0]} filled by {TTWAUser[0]} residents"
							variant="outlined"
							name="Percentage 1"
							value={percentage[0]}
							onBlur={(e) => {
								setPercentage([
									parseInt(e.target.value),
									100 - parseInt(e.target.value),
								]);
							}}
							size="small"
							suffix=" %"
							inputMode="numeric"
							allowNegative={false}
							customInput={TextField}
							isAllowed={(inputObj) => {
								const { value } = inputObj;
								if (value <= 100) return true;
								return false;
							}}
						/>{" "}
					</FormControl>
				</div>
				<div className="percentage-container" id="second-percentage">
					<div className="percentage-label">
						Percentage of jobs in{" "}
						{TTWAUser[1] !== "" ? TTWAUser[1] : "..."} filled by{" "}
						{TTWAUser[0] !== "" ? TTWAUser[0] : "..."} residents
					</div>
					<FormControl sx={{ width: mobile ? "100%" : "230px" }}>
						<NumericFormat
							sx={{
								backgroundColor: "white",
							}}
							style={{ float: "right" }}
							id="id-percentage-2"
							// label="Percentage of jobs in {TTWAUser[1]} filled by {TTWAUser[0]} residents"
							variant="outlined"
							name="Percentage 2"
							value={percentage[1]}
							onBlur={(e) => {
								setPercentage([
									100 - parseInt(e.target.value),
									parseInt(e.target.value),
								]);
							}}
							size="small"
							suffix=" %"
							inputMode="numeric"
							allowNegative={false}
							customInput={TextField}
							isAllowed={(inputObj) => {
								const { value } = inputObj;
								if (value <= 100) return true;
								return false;
							}}
						/>
					</FormControl>
				</div>
				{/* <FormControl size="small">
						<NumericFormat
							id="id-percentage-1"
							label="Percentage of jobs in {TTWAUser[0]} filled by {TTWAUser[0]} residents"
							variant="outlined"
							name="Percentage 1"
							value={percentage[0]}
							onBlur={(e) => {
								setPercentage([
									parseInt(e.target.value),
									100 - parseInt(e.target.value),
								]);
							}}
							size="small"
							suffix=" %"
							inputMode="numeric"
							allowNegative={false}
							customInput={TextField}
							isAllowed={(inputObj) => {
								const { value } = inputObj;
								if (value <= 100) return true;
								return false;
							}}
						/>{" "}
					</FormControl>

					<FormControl size="small">
						<NumericFormat
							style={{ float: "right" }}
							id="id-percentage-2"
							label="Percentage of jobs in {TTWAUser[1]} filled by {TTWAUser[0]} residents"
							variant="outlined"
							name="Percentage 2"
							value={percentage[1]}
							onBlur={(e) => {
								setPercentage([
									100 - parseInt(e.target.value),
									parseInt(e.target.value),
								]);
							}}
							size="small"
							suffix=" %"
							inputMode="numeric"
							allowNegative={false}
							customInput={TextField}
							isAllowed={(inputObj) => {
								const { value } = inputObj;
								if (value <= 100) return true;
								return false;
							}}
						/>
					</FormControl> */}
			</div>
			<div className="table-container">
				<Paper
					sx={{
						boxShadow: "None !important",
					}}
					className="calc-table"
				>
					<Table
						sx={{
							backgroundColor: "#f4f4f0",
							"& .MuiTableCell-root": {
								border: 0,
							},
						}}
					>
						<TableBody>
							<TableRow sx={{ borderBottom: "1px solid black" }}>
								<TableCell
									colSpan={3}
									style={{
										fontSize: "1.5rem",
										paddingBottom: "0.5rem",
										paddingLeft: 0,
									}}
								>
									<b>Job creation, loss and displacement</b>
								</TableCell>
							</TableRow>
							<TableRow className="align-contect-center">
								<TableCell></TableCell>
								<TableCell>
									<b>
										{TTWAUser[0] !== ""
											? TTWAUser[0]
											: "..."}
									</b>
								</TableCell>
								<TableCell>
									<b>
										{TTWAUser[1] !== ""
											? TTWAUser[1]
											: "..."}
									</b>
								</TableCell>
								<TableCell>
									<b>Total TTWA</b>
								</TableCell>
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									Number of new places (Creation)
								</TableCell>
								<TableCell style={{ textAlign: "center" }}>
									<NumericFormat
										className="table-input"
										id="id-creation"
										variant="outlined"
										name="creation"
										value={numberCreated[0]}
										onBlur={(e) => {
											e.preventDefault();
											let numberCreatedTemp = [
												...numberCreated,
											];
											numberCreatedTemp[0] = parseInt(
												e.target.value
											);
											setNumberCreated(numberCreatedTemp);
										}}
										size="small"
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										inputProps={{
											style: { textAlign: "center" },
										}} // the change is here

										// isAllowed={(inputObj) => {
										// 	const { value } = inputObj;
										// 	if (value <= 100) return true;
										// 	return false;
										// }}
									/>
								</TableCell>
								<TableCell style={{ textAlign: "center" }}>
									<NumericFormat
										className="table-input"
										id="id-creation"
										variant="outlined"
										name="creation"
										value={numberCreated[1]}
										onBlur={(e) => {
											e.preventDefault();
											let numberCreatedTemp = [
												...numberCreated,
											];
											numberCreatedTemp[1] = parseInt(
												e.target.value
											);
											setNumberCreated(numberCreatedTemp);
										}}
										size="small"
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										inputProps={{
											style: { textAlign: "center" },
										}}
										// isAllowed={(inputObj) => {
										// 	const { value } = inputObj;
										// 	if (value <= 100) return true;
										// 	return false;
										// }}
									/>
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{numberCreatedTotal}
								</TableCell>
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									Number of jobs lost within the same firm
									(Substitution)
								</TableCell>
								<TableCell style={{ textAlign: "center" }}>
									<NumericFormat
										className="table-input"
										id="id-substitution"
										variant="outlined"
										name="substitution"
										value={numberSubstituted[0]}
										onBlur={(e) => {
											e.preventDefault();
											let numberSubstitutedTemp = [
												...numberSubstituted,
											];
											numberSubstitutedTemp[0] = parseInt(
												e.target.value
											);
											setNumberSubstituted(
												numberSubstitutedTemp
											);
										}}
										size="small"
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										inputProps={{
											style: { textAlign: "center" },
										}}
										// isAllowed={(inputObj) => {
										// 	const { value } = inputObj;
										// 	if (value <= 100) return true;
										// 	return false;
										// }}
									/>
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									<NumericFormat
										className="table-input"
										id="id-substitution"
										variant="outlined"
										name="substitution"
										value={numberSubstituted[1]}
										onBlur={(e) => {
											e.preventDefault();
											let numberSubstitutedTemp = [
												...numberSubstituted,
											];
											numberSubstitutedTemp[1] = parseInt(
												e.target.value
											);
											setNumberSubstituted(
												numberSubstitutedTemp
											);
										}}
										size="small"
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										inputProps={{
											style: { textAlign: "center" },
										}}
										// isAllowed={(inputObj) => {
										// 	const { value } = inputObj;
										// 	if (value <= 100) return true;
										// 	return false;
										// }}
									/>
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{numberSubstitutedTotal}
								</TableCell>
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									Number of jobs moved elsewhere
									(Displacement)
								</TableCell>
								<TableCell style={{ textAlign: "center" }}>
									<NumericFormat
										className="table-input"
										id="id-creation"
										variant="outlined"
										name="creation"
										value={numberDisplaced[0]}
										onBlur={(e) => {
											e.preventDefault();
											let numberDisplacedTemp = [
												...numberDisplaced,
											];
											numberDisplacedTemp[0] = parseInt(
												e.target.value
											);
											setNumberDisplaced(
												numberDisplacedTemp
											);
										}}
										size="small"
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										inputProps={{
											style: { textAlign: "center" },
										}}
										// isAllowed={(inputObj) => {
										// 	const { value } = inputObj;
										// 	if (value <= 100) return true;
										// 	return false;
										// }}
									/>
								</TableCell>
								<TableCell style={{ textAlign: "center" }}>
									<NumericFormat
										className="table-input"
										id="id-creation"
										variant="outlined"
										name="creation"
										value={numberDisplaced[1]}
										onBlur={(e) => {
											e.preventDefault();
											let numberDisplacedTemp = [
												...numberDisplaced,
											];
											numberDisplacedTemp[1] = parseInt(
												e.target.value
											);
											setNumberDisplaced(
												numberDisplacedTemp
											);
										}}
										size="small"
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										inputProps={{
											style: { textAlign: "center" },
										}}
										// isAllowed={(inputObj) => {
										// 	const { value } = inputObj;
										// 	if (value <= 100) return true;
										// 	return false;
										// }}
									/>
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{numberDisplacedTotal}
								</TableCell>
							</TableRow>{" "}
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									<b>Net direct job creation</b>
								</TableCell>
								{netJobCreation.map((item) => (
									<TableCell sx={{ textAlign: "center" }}>
										<b>{item}</b>
									</TableCell>
								))}
							</TableRow>{" "}
							<TableRow sx={{ height: "60px !important" }}>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid black" }}>
								<TableCell
									colSpan={3}
									sx={{ padding: 0, fontSize: "1.2rem" }}
								>
									<b>Direct employment effects</b>
								</TableCell>
							</TableRow>
							<TableRow className="align-contect-center">
								<TableCell></TableCell>
								<TableCell>
									<b>
										{TTWAUser[0] !== ""
											? TTWAUser[0]
											: "..."}
									</b>
								</TableCell>
								<TableCell>
									<b>
										{TTWAUser[1] !== ""
											? TTWAUser[1]
											: "..."}
									</b>
								</TableCell>
								<TableCell>
									<b>Total TTWA</b>
								</TableCell>
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									<b>Net direct employment effects</b>
								</TableCell>
								{directEmploymentEffects.map((item) => (
									<TableCell sx={{ textAlign: "center" }}>
										<b>{item}</b>
									</TableCell>
								))}
							</TableRow>
							<TableRow sx={{ height: "60px !important" }}>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid black" }}>
								<TableCell
									colSpan={3}
									sx={{ padding: 0, fontSize: "1.2rem" }}
								>
									<b>Indirect employment effects</b>
								</TableCell>
							</TableRow>
							<TableRow className="align-contect-center">
								<TableCell></TableCell>
								<TableCell>
									<b>
										{TTWAUser[0] !== ""
											? TTWAUser[0]
											: "..."}
									</b>
								</TableCell>
								<TableCell>
									<b>
										{TTWAUser[1] !== ""
											? TTWAUser[1]
											: "..."}
									</b>
								</TableCell>
								<TableCell>
									<b>Total TTWA</b>
								</TableCell>
							</TableRow>
							{employmentMultiplierLabel === "Tradable" && (
								<React.Fragment>
									<TableRow
										sx={{
											borderBottom: "1px solid white",
											padding: 0,
										}}
									>
										<TableCell sx={{ padding: 0 }}>
											Indirect employment creation in the
											non-tradable sector
										</TableCell>
										{indirectEmploymentEffects.map(
											(item) => (
												<TableCell
													sx={{ textAlign: "center" }}
												>
													{item[0]}
												</TableCell>
											)
										)}
									</TableRow>
									<TableRow
										sx={{
											borderBottom: "1px solid white",
											padding: 0,
										}}
									>
										<TableCell sx={{ padding: 0 }}>
											Indirect employment creation in the
											tradable sector
										</TableCell>
										{indirectEmploymentEffects.map(
											(item) => (
												<TableCell
													sx={{ textAlign: "center" }}
												>
													{item[1]}
												</TableCell>
											)
										)}
									</TableRow>
								</React.Fragment>
							)}
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									<b>Net indirect employment effects</b>
								</TableCell>
								{indirectEmploymentEffects.map((item) => (
									<TableCell sx={{ textAlign: "center" }}>
										<b>
											{Array.isArray(item)
												? item[2]
												: item}
										</b>
									</TableCell>
								))}
							</TableRow>
							<TableRow sx={{ borderBottom: "1px solid white" }}>
								<TableCell sx={{ padding: 0 }}>
									<b>Total net employment in each area</b>
								</TableCell>
								{totalEmploymentEffect.map((item) => (
									<TableCell sx={{ textAlign: "center" }}>
										<b>{item}</b>
									</TableCell>
								))}
							</TableRow>
						</TableBody>
					</Table>
				</Paper>
			</div>
			<div
				className="export-table-button"
				onClick={(event) => {
					event.preventDefault();
					let spreadsheetData = [
						["Employment Multiplier", employmentMultiplierLabel],
						[
							"Percentage of jobs in " + TTWAUser[0] !== ""
								? TTWAUser[0]
								: "..." + " filled by " + TTWAUser[0] !== ""
								? TTWAUser[0]
								: "..." + " residents",
							percentage[0],
						],
						[
							"Percentage of jobs in " + TTWAUser[1] !== ""
								? TTWAUser[1]
								: "..." + " filled by " + TTWAUser[0] !== ""
								? TTWAUser[0]
								: "..." + " residents",
							percentage[1],
						],
						[],
						["Job creation, loss and displacement"],
						[
							"",
							TTWAUser[0] !== "" ? TTWAUser[0] : "...",
							TTWAUser[1] !== "" ? TTWAUser[1] : "...",
							"Total TTWA",
						],
						["Number of new places (Creation)"],
						[
							"Number of jobs lost within the same firm (Substitution)",
						],
						["Number of jobs moved elsewhere (Displacement)"],
						["Net direct job creation	"],
						["Direct employment effects	"],
						[
							"",
							TTWAUser[0] !== "" ? TTWAUser[0] : "...",
							TTWAUser[1] !== "" ? TTWAUser[1] : "...",
							"Total TTWA",
						],
						["Net direct employment effects"],
						["Indirect employment effects"],
						[
							"",
							TTWAUser[0] !== "" ? TTWAUser[0] : "...",
							TTWAUser[1] !== "" ? TTWAUser[1] : "...",
							"Total TTWA",
						],
						["Net indirect employment effects"],
						["Total net employment in each area"],
					];
					if (employmentMultiplierLabel === "Tradable") {
						spreadsheetData.splice(spreadsheetData.length - 2, 0, [
							"Indirect employment creation in the non-tradable sector",
						]);
						spreadsheetData.splice(spreadsheetData.length - 2, 0, [
							"Indirect employment creation in the tradable sector",
						]);
					}
					numberCreated.forEach((number) => {
						spreadsheetData[6].push(number);
					});
					numberSubstituted.forEach((number) => {
						spreadsheetData[7].push(number);
					});
					numberDisplaced.forEach((number) => {
						spreadsheetData[8].push(number);
					});
					spreadsheetData[6].push(numberCreatedTotal);
					spreadsheetData[7].push(numberSubstitutedTotal);
					spreadsheetData[8].push(numberDisplacedTotal);
					netJobCreation.forEach((number) => {
						spreadsheetData[9].push(number);
					});
					directEmploymentEffects.forEach((number) => {
						spreadsheetData[12].push(number);
					});
					if (employmentMultiplierLabel === "Tradable") {
						indirectEmploymentEffects.forEach((effect, index) => {
							effect.forEach((number) => {
								spreadsheetData[15 + index].push(number);
							});
						});
					} else {
						indirectEmploymentEffects.forEach((number) => {
							spreadsheetData[15].push(number);
						});
					}

					totalEmploymentEffect.forEach((number) => {
						spreadsheetData[spreadsheetData.length - 1].push(
							number
						);
					});
					let worksheet = XLSX.utils.json_to_sheet(spreadsheetData, {
						skipHeader: 1,
					});
					worksheet["!cols"] = [
						{ wch: 70 },
						{ wch: 30 },
						{ wch: 30 },
						{ wch: 30 },
					];

					const workbook = XLSX.utils.book_new();

					XLSX.utils.book_append_sheet(workbook, worksheet);

					XLSX.writeFile(workbook, "Local Multipliers.xlsx");
				}}
			>
				EXPORT
				<img alt="Export Table" src={ExportIcon} />
			</div>
		</div>
	);
}

export default LocalMults;
