import axios from "axios";
import {
	FormControl,
	TextField,
	Paper,
	Box,
	Autocomplete,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Industries, Countries, Regions } from "../data/Data.js";
import { NumericFormat } from "react-number-format";
import Highcharts from "highcharts";
import highchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import {
	DataGrid,
	GridToolbarExport,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import "../css/NatRegMults.css";
import * as XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import Currencies from "../data/Currencies.json";
import RemoveIcon from "../images/icons/remove.svg";
import AddIcon from "../images/icons/add.svg";
import ExportIcon from "../images/icons/export.svg";

const MAX_PROJECTS_NUMBER = 50;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
if (process.env.NODE_ENV !== "development")
	axios.defaults.withCredentials = true;
function NatRegMults() {
	const MAX_INDUSTRY_CHARS = 55;

	// form variables to calculate economic impact
	const [selectedCountries, setSelectedCountries] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill("")
	);
	const [selectedRegions, setSelectedRegions] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill("")
	);
	const [selectedIndustries, setSelectedIndustries] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill("")
	);
	const [selectedYears, setSelectedYears] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill("2018")
	);
	const [insertedInvAmounts, setInsertedInvAmounts] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill("£ 1")
	);
	const [economicImpacts, setEconomicImpacts] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill(null)
	);
	const [insertedNames, setInsertedNames] = useState(
		new Array(MAX_PROJECTS_NUMBER)
			.fill(null)
			.map((_, index) => `Project ${index + 1}`)
	);
	const [topIndustriesNames, setTopIndustriesNames] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill(null)
	);
	const [topIndustriesImpacts, setTopIndustriesImpacts] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill(null)
	);

	const [emptyRows, setEmptyRows] = useState(
		new Array(MAX_PROJECTS_NUMBER).fill(false)
	);

	const currenciesOptions = [];
	Object.keys(Currencies).forEach((c) =>
		currenciesOptions.push({ label: c + " - " + Currencies[c] })
	);

	const countriesOptions = [];
	Object.keys(Countries).forEach((country) =>
		countriesOptions.push({ label: Countries[country] })
	);

	const industriesOptions = [];
	Object.keys(Industries).forEach((industry) =>
		industriesOptions.push({ label: Industries[industry] })
	);

	const regionsOptions = [];
	Object.keys(Regions).forEach((region) =>
		regionsOptions.push({ label: Regions[region] })
	);

	// user can not set an investment amount over 1 billion
	const withValueCap = (inputObj) => {
		const { value } = inputObj;
		if (value <= 1000000000) return true;
		return false;
	};

	const [currency, setCurrency] = useState("GBP");
	const [formTotalRows, setFormTotalRows] = useState(1);
	const [datagridRows, setDatagridRows] = useState([]);
	const datagridColumns = [
		{
			field: "id",
			headerName: "ID",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			width: 7,
		},
		{
			field: "name",
			headerName: "Project Name",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 1,
		},
		{
			field: "countryColumn",
			headerName: "Country",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 1,
		},
		{
			field: "regionColumn",
			headerName: "Region",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 1,
		},
		{
			field: "yearColumn",
			headerName: "Year",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 1,
		},
		{
			field: "industryColumn",
			headerName: "Project Industry",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 2,
		},
		{
			field: "invAmountColumn",
			headerName: "Project Value",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 1,
		},
		{
			field: "expectedIncomeColumn",
			headerName: "Total Output",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 1,
		},
		{
			field: "mostAffectedIndustryColumn",
			headerName: "Top Industry by Output",
			headerClassName: "custom-header-class",
			cellClassName: "custom-cell-class",
			flex: 2,
		},
		// {
		// 	field: "higherEconomicImpactColumn",
		// 	headerName: "Higher Impact",
		// 	flex: 1,
		// 	headerClassName: "custom-header-class",
		// 	cellClassName: "custom-cell-class",
		// },
	];

	// chart variables
	const [chartOptions, setChartOptions] = useState({});
	const [topExpectedIncomeValues, setTopExpectedIncomeValues] = useState({});
	const [topExpectedIncomeKeys, setTopExpectedIncomeKeys] = useState([]);

	const [chart_filename, setChartfilename] = useState("eimt-graph");
	const chart = useRef();
	const GRAPH_TOP_INDUSTRIES_TOTAL = 5;
	const FULL_GRAPH_TOP_INDUSTRIES_TOTAL = GRAPH_TOP_INDUSTRIES_TOTAL * 2;
	highchartsExporting(Highcharts);
	Highcharts.setOptions({
		lang: {
			thousandsSep: ",",
		},
	});

	const menuItemsStyle = {
		"&:hover": {
			backgroundColor: "#fcee21 !important",
		},
		"&:focus": {
			backgroundColor: "#fcee21 !important",
		},
		"&.Mui-selected": {
			backgroundColor: "#fcee21 !important",
		},
	};

	function updateTotal(
		updatedTopIndustriesNames,
		updatedTopIndustriesImpacts
	) {
		let topExpectedIncomeTemp = {};
		updatedTopIndustriesImpacts.forEach((impact, index) => {
			if (impact !== null) {
				for (let i = 0; i < impact.length; i++) {
					if (
						!Object.keys(topExpectedIncomeTemp).includes(
							updatedTopIndustriesNames[index][i]
						)
					) {
						topExpectedIncomeTemp[
							updatedTopIndustriesNames[index][i]
						] = impact[i];
					} else {
						topExpectedIncomeTemp[
							updatedTopIndustriesNames[index][i]
						] =
							topExpectedIncomeTemp[
								updatedTopIndustriesNames[index][i]
							] + impact[i];
					}
				}
			}
		});
		let items = Object.keys(topExpectedIncomeTemp).map((key) => {
			return [key, topExpectedIncomeTemp[key]];
		});
		items.sort((first, second) => {
			return second[1] - first[1];
		});
		let keys = items.map((e) => {
			return e[0];
		});
		let values = items.map((e) => {
			return e[1];
		});
		setTopExpectedIncomeValues(values);
		setTopExpectedIncomeKeys(keys);
	}

	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	// here we communicate with django to get the calculated economic impact from our DB
	function createEconomicModel(
		projectNumber,
		projectName,
		country,
		region,
		industry,
		year,
		invAmount,
		currency
	) {
		if (
			(invAmount !== "") &
			(country !== "") &
			(industry !== "") &
			(year !== "")
		) {
			invAmount = "£ " + invAmount.split(" ")[1];
			// creating the filename of the chart
			if (region !== "" && region !== "-") {
				setChartfilename(
					year +
						"-" +
						country +
						"-" +
						region +
						"-" +
						invAmount.replace("£ ", "£").replaceAll(",", "")
				);
			} else {
				setChartfilename(
					year +
						"-" +
						country +
						"-" +
						invAmount.replace("£ ", "£").replaceAll(",", "")
				);
			}
			axios
				.post(
					process.env.NODE_ENV === "development"
						? "http://localhost:8000/economic_model/"
						: "/server/economic_model/",
					{
					id: 1,
					inv_amount: parseFloat(
						invAmount.replace("£ ", "").replaceAll(",", "")
					),
					country: country,
					region: region,
					industry: industry,
					year: year,
					economic_impact: null,
				}
				)
				.then((response) => {
					let updatedEconomicImpacts = economicImpacts;
					updatedEconomicImpacts[projectNumber] =
						response.data.economic_impact;
					setEconomicImpacts(updatedEconomicImpacts);
					const top_industries_data = JSON.parse(
						response.data.top_industries_data
					);
					var top_industries_mults =
						top_industries_data["mults"].split("|");
					var currentTopIndustriesNames = [];
					var currentTopIndustriesImpacts = [];
					top_industries_data["ids"]
						.split("|")
						.forEach((element, index) => {
							currentTopIndustriesNames.push(
								element + ": " + Industries[element]
							);
							if (
								parseFloat(
									invAmount
										.replace("£ ", "")
										.replaceAll(",", "")
								) > 100
							) {
								currentTopIndustriesImpacts.push(
									Math.round(
										top_industries_mults[index] *
											parseFloat(
												invAmount
													.replace("£ ", "")
													.replaceAll(",", "")
											)
									)
								);
							} else {
								currentTopIndustriesImpacts.push(
									Math.round(
										top_industries_mults[index] *
											parseFloat(
												invAmount
													.replace("£ ", "")
													.replaceAll(",", "")
											) *
											100
									) / 100
								);
							}
						});

					let updatedTopIndustriesNames = topIndustriesNames;
					updatedTopIndustriesNames[projectNumber] =
						currentTopIndustriesNames;
					setTopIndustriesNames(updatedTopIndustriesNames);

					let updatedTopIndustriesImpacts = topIndustriesImpacts;
					updatedTopIndustriesImpacts[projectNumber] =
						currentTopIndustriesImpacts;
					setTopIndustriesImpacts(updatedTopIndustriesImpacts);
					updateTotal(
						updatedTopIndustriesNames,
						updatedTopIndustriesImpacts
					);
					// updating the chart
					let updatedChartOptions = chartOptions;
					updatedChartOptions[projectNumber] = {
						chart: {
							height: 330,
							backgroundColor: "transparent",
						},

						colors: new Array(MAX_PROJECTS_NUMBER).fill("black"),
						exporting: {
							filename:
								chart_filename +
								"-top" +
								GRAPH_TOP_INDUSTRIES_TOTAL.toString(),
							credits: {
								enabled: false,
							},
							chartOptions: {
								chart: {
									backgroundColor: "#f4f4f0",
								},
							},
							buttons: {
								contextButton: {
									menuItems: [
										{
											text: "Export as PNG",
											onclick: function () {
												const chartId =
													parseInt(
														this.title.textStr
															.split(" - ")[0]
															.replace(
																"Project ",
																""
															)
													) - 1;

												let chartName =
													"ImpactEstimator - " +
													selectedCountries[chartId] +
													" " +
													selectedRegions[chartId] +
													" " +
													selectedIndustries[
														chartId
													] +
													" " +
													selectedYears[chartId];
												chartName = chartName.replace(
													"  ",
													" "
												);
												this.exportChart({
													filename: chartName,
												});
											},
										},
									],
								},
							},
						},
						title: {
							text:
								projectName +
								" - " +
								"Top " +
								GRAPH_TOP_INDUSTRIES_TOTAL +
								" industries affected",
						},
						subtitle: {
							text:
								region !== "" && region !== "-"
									? year +
									  " - " +
									  Countries[country] +
									  " - " +
									  Regions[region] +
									  " - " +
									  invAmount.replace(
											"£",
											Currencies[currency]
									  )
									: year +
									  " - " +
									  Countries[country] +
									  " - " +
									  invAmount.replace(
											"£",
											Currencies[currency]
									  ),
						},
						xAxis: {
							lineColor: "black",
							categories: currentTopIndustriesNames.slice(
								0,
								GRAPH_TOP_INDUSTRIES_TOTAL
							),
							labels: {
								style: {
									//whiteSpace: "normal",
									color: "#000000",
									fontSize: "10px",
								},
								// rotation: 0,
								formatter: function () {
									var label =
										this.axis.defaultLabelFormatter.call(
											this
										);
									if (label.length > MAX_INDUSTRY_CHARS) {
										return (
											label.substring(
												0,
												MAX_INDUSTRY_CHARS
											) + "..."
										);
									} else {
										return label;
									}
								},
							},
						},
						yAxis: {
							title: {
								text:
									"Calculated Economic Impact (" +
									Currencies[currency] +
									")",
							},
						},
						legend: {
							enabled: false,
						},
						series: [
							{
								type: "column",
								name: "Economic Impact",
								colorByPoint: true,
								data: currentTopIndustriesImpacts.slice(
									0,
									GRAPH_TOP_INDUSTRIES_TOTAL
								),
								showInLegend: false,
								borderColor: "black",
							},
						],

						navigation: {
							buttonOptions: {
								theme: {
									"stroke-width": 0,
									fill: "transparent",
									r: 0,
								},
							},
						},
					};

					setChartOptions(updatedChartOptions);
					// updating the datagrid
					let updatedDatagridRows = [...datagridRows];
					// removing the current project data if its already added
					updatedDatagridRows = updatedDatagridRows.filter(
						(project) => {
							return project.id !== parseInt(projectNumber) + 1;
						}
					);
					const rowData = {
						id: parseInt(projectNumber) + 1,
						name: projectName,
						countryColumn: Countries[country],
						regionColumn: region === "" ? "-" : Regions[region],
						industryColumn: Industries[industry],
						yearColumn: year,
						invAmountColumn:
							Currencies[currency] +
							" " +
							invAmount.split(" ")[1],
						expectedIncomeColumn:
							Currencies[currency] +
							" " +
							response.data.economic_impact,
						mostAffectedIndustryColumn: currentTopIndustriesNames[0]
							.slice(
								currentTopIndustriesNames[0].lastIndexOf(":") +
									1
							)
							.trim(), //default format: 13: Manufacture ...
						higherEconomicImpactColumn:
							Currencies[currency] +
							" " +
							numberWithCommas(currentTopIndustriesImpacts[0]),
					};

					// sorting based on the project id
					updatedDatagridRows.push(rowData);
					updatedDatagridRows.sort((a, b) => {
						return a.id - b.id;
					});
					setDatagridRows(updatedDatagridRows);
				})
				.catch((error) => {
					console.log(error);
					if (error.response) {
						console.log(error.response);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
					console.log(error.config);
				});
		}
	}

	function MyExportButton() {
		return (
			<GridToolbarContainer>
				<GridToolbarExport id="hide-this" />
			</GridToolbarContainer>
		);
	}

	const StyledGridOverlay = styled("div")(({ theme }) => ({
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		"& .ant-empty-img-1": {
			fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
		},
		"& .ant-empty-img-2": {
			fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
		},
		"& .ant-empty-img-3": {
			fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
		},
		"& .ant-empty-img-4": {
			fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
		},
		"& .ant-empty-img-5": {
			fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
			fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
		},
	}));

	function MyNoRowsOverlay() {
		return (
			<StyledGridOverlay style={{ backgroundColor: "white" }}>
				<Box sx={{ mt: 1 }}>
					Start entering data to get a summary of project outputs.
				</Box>
			</StyledGridOverlay>
		);
	}

	function cleanForm(rowId) {
		let updatedSelectedCountries = selectedCountries;
		let updatedSelectedRegions = selectedRegions;
		let updatedSelectedIndustries = selectedIndustries;
		let updatedSelectedYears = selectedYears;
		let updatedInsertedInvAmounts = insertedInvAmounts;
		let updatedEconomicImpacts = economicImpacts;
		let updatedTopIndustriesImpacts = topIndustriesImpacts;
		let updatedTopIndustriesNames = topIndustriesNames;
		let updatedInsertedNames = insertedNames;

		//we remove the element in the specific index/rowId
		updatedSelectedCountries.splice(rowId, 1);
		updatedSelectedRegions.splice(rowId, 1);
		updatedSelectedIndustries.splice(rowId, 1);
		updatedSelectedYears.splice(rowId, 1);
		updatedInsertedInvAmounts.splice(rowId, 1);
		updatedEconomicImpacts.splice(rowId, 1);
		updatedTopIndustriesImpacts.splice(rowId, 1);
		updatedTopIndustriesNames.splice(rowId, 1);
		updatedInsertedNames.splice(rowId, 1);

		const lastRow = MAX_PROJECTS_NUMBER - 1;
		updatedSelectedCountries[lastRow] = "";
		updatedSelectedRegions[lastRow] = "";
		updatedSelectedIndustries[lastRow] = "";
		updatedSelectedYears[lastRow] = "2018";
		updatedInsertedInvAmounts[lastRow] = "£ 1";
		updatedEconomicImpacts[lastRow] = null;
		updatedTopIndustriesImpacts[lastRow] = null;
		updatedTopIndustriesNames[lastRow] = null;
		// updatedInsertedNames[lastRow] = null;

		function containsOnlyNumbers(str) {
			return /^\d+$/.test(str);
		}

		for (let i = 0; i < lastRow - 1; i++) {
			if (updatedInsertedNames[i].startsWith("Project ")) {
				const possibleProjectNumber =
					updatedInsertedNames[i].split("Project ")[1];
				if (
					containsOnlyNumbers(possibleProjectNumber) &&
					!possibleProjectNumber.startsWith("0")
				) {
					if (parseInt(possibleProjectNumber) !== i + 1) {
						updatedInsertedNames[i] = "Project " + (i + 1);
					}
				}
			}
		}

		updateTotal(updatedTopIndustriesNames, updatedTopIndustriesImpacts);
		setSelectedCountries(updatedSelectedCountries);
		setSelectedRegions(updatedSelectedRegions);
		setSelectedIndustries(updatedSelectedIndustries);
		setSelectedYears(updatedSelectedYears);
		setInsertedInvAmounts(updatedInsertedInvAmounts);
		setEconomicImpacts(updatedEconomicImpacts);
		setInsertedNames(updatedInsertedNames);

		// the project name changes only when it has its default value "project rowid"

		updateChartsCurrency(currency);
	}

	const removeRow = (e) => {
		const rowNumber = e.currentTarget.id.slice(
			e.currentTarget.id.lastIndexOf("-") + 1
		);

		// deleting the chart
		let updatedChartOptions = chartOptions;

		for (let i = rowNumber; i < Object.keys(updatedChartOptions) - 1; i++) {
			updatedChartOptions[i] = updatedChartOptions[i + 1];
		}
		delete updatedChartOptions[Object.keys(updatedChartOptions).length - 1];
		setChartOptions(updatedChartOptions);

		// setTopExpectedIncome(removeRowFromTopExpectedIncome(rowNumber));

		// clean the form data
		cleanForm(rowNumber);
		setFormTotalRows(formTotalRows - 1);

		//deleting the current datagrid row and shifting the rest
		let updatedDatagridRows = [...datagridRows];
		updatedDatagridRows.splice(rowNumber, 1);

		let index = 1;
		updatedDatagridRows.forEach((datagridRow) => {
			datagridRow.id = index;
			datagridRow.name = insertedNames[index - 1];
			index++;
		});
		setDatagridRows(updatedDatagridRows);

		//marking row as non empty, to apply the style from the beginning next time it will be added
		let currentEmptyRows = emptyRows;
		for (let row = MAX_PROJECTS_NUMBER - 1; row >= 0; row--) {
			if (currentEmptyRows[row]) {
				currentEmptyRows[row] = false;
				break;
			}
		}
		setEmptyRows(currentEmptyRows);
	};

	function removeEmptyStyle(fieldName, currentRow) {
		document
			.getElementById("id-field-" + fieldName + "-row-" + currentRow)
			.classList.remove("empty-field");
	}

	function markRowAsEmpty(rowNumber) {
		// if not already marked as empty
		if (!emptyRows[rowNumber]) {
			["country", "region", "industry"].forEach((fieldName) => {
				if (selectedRegions[rowNumber] === "") {
					document
						.getElementById(
							"id-field-" + fieldName + "-row-" + rowNumber
						)
						.classList.add("empty-field");
				}
			});

			const currentEmptyRows = emptyRows;
			currentEmptyRows[rowNumber] = true;
			setEmptyRows(currentEmptyRows);
		}
	}

	function updateChartsCurrency(currency) {
		let updatedChartOptions = chartOptions;
		Object.entries(updatedChartOptions).map(([currentRow, _]) => {
			updatedChartOptions[currentRow] = {
				chart: {
					backgroundColor: "transparent",

					height: 330,
				},
				colors: new Array(MAX_PROJECTS_NUMBER).fill("black"),
				exporting: {
					credits: {
						enabled: false,
					},
					chartOptions: {
						chart: {
							backgroundColor: "#f4f4f0",
						},
					},
					filename:
						chart_filename +
						"-top" +
						GRAPH_TOP_INDUSTRIES_TOTAL.toString(),
					buttons: {
						contextButton: {
							menuItems: [
								{
									text: "Export as PNG",
									onclick: function () {
										const chartId =
											parseInt(
												this.title.textStr
													.split(" - ")[0]
													.replace("Project ", "")
											) - 1;

										let chartName =
											"ImpactEstimator - " +
											selectedCountries[chartId] +
											" " +
											selectedRegions[chartId] +
											" " +
											selectedIndustries[chartId] +
											" " +
											selectedYears[chartId];
										chartName = chartName.replace(
											"  ",
											" "
										);
										this.exportChart({
											filename: chartName,
										});
									},
								},
							],
						},
					},
				},
				title: {
					text:
						insertedNames[currentRow] +
						" - " +
						"Top " +
						GRAPH_TOP_INDUSTRIES_TOTAL +
						" industries affected",
				},
				subtitle: {
					text:
						selectedRegions[currentRow] !== "" &&
						selectedRegions[currentRow] !== "-"
							? selectedYears[currentRow] +
							  " - " +
							  Countries[selectedCountries[currentRow]] +
							  " - " +
							  Regions[selectedRegions[currentRow]] +
							  " - " +
							  insertedInvAmounts[currentRow].replace(
									"£",
									Currencies[currency]
							  )
							: selectedYears[currentRow] +
							  " - " +
							  Countries[selectedCountries[currentRow]] +
							  " - " +
							  insertedInvAmounts[currentRow].replace(
									"£",
									Currencies[currency]
							  ),
				},
				xAxis: {
					categories: topIndustriesNames[currentRow].slice(
						0,
						GRAPH_TOP_INDUSTRIES_TOTAL
					),
					labels: {
						style: {
							//whiteSpace: "normal",
							color: "#000000",
							fontSize: "10px",
						},
						formatter: function () {
							var label =
								this.axis.defaultLabelFormatter.call(this);
							if (label.length > MAX_INDUSTRY_CHARS) {
								return (
									label.substring(0, MAX_INDUSTRY_CHARS) +
									"..."
								);
							} else {
								return label;
							}
						},
					},
					lineColor: "black",
				},
				yAxis: {
					title: {
						text:
							"Calculated Economic Impact (" +
							Currencies[currency] +
							")",
					},
				},
				legend: {
					enabled: false,
				},
				series: [
					{
						type: "column",
						name: "Economic Impact",
						colorByPoint: true,
						data: topIndustriesImpacts[currentRow].slice(
							0,
							GRAPH_TOP_INDUSTRIES_TOTAL
						),
						showInLegend: false,

						borderColor: "black",
					},
				],

				navigation: {
					buttonOptions: {
						theme: {
							"stroke-width": 0,
							fill: "transparent",
							r: 0,
						},
					},
				},
			};
		});

		setChartOptions(updatedChartOptions);
	}
	function getKeyByValue(object, value) {
		return Object.keys(object).find((key) => object[key] === value);
	}
	useEffect(() => {
		let datagridRowTemp = [...datagridRows];
		for (let i = 0; i < datagridRowTemp.length; i++) {
			datagridRowTemp[i]["expectedIncomeColumn"] =
				Currencies[currency] +
				" " +
				datagridRowTemp[i]["expectedIncomeColumn"].split(" ")[1];
			datagridRowTemp[i]["higherEconomicImpactColumn"] =
				Currencies[currency] +
				" " +
				datagridRowTemp[i]["higherEconomicImpactColumn"].split(" ")[1];
			datagridRowTemp[i]["invAmountColumn"] =
				Currencies[currency] +
				" " +
				datagridRowTemp[i]["invAmountColumn"].split(" ")[1];
		}

		setDatagridRows(datagridRowTemp);
	}, [currency]);

	const exportData = () => {
		if (datagridRows.length) {
			const workbook = XLSX.utils.book_new();
			let worksheet;

			// Writing Inputs sheet
			const InputsSheet = [];
			for (let i = 0; i < formTotalRows; i++) {
				InputsSheet.push({
					ID: i + 1,
					"Project Name": insertedNames[i],
					Country: Countries[selectedCountries[i]],
					Region:
						selectedRegions[i] !== ""
							? Regions[selectedRegions[i]]
							: "-",
					Year: selectedYears[i],
					Industry: Industries[selectedIndustries[i]],
					"Investment Amount": insertedInvAmounts[i],
				});
			}
			worksheet = XLSX.utils.json_to_sheet(InputsSheet);
			worksheet["!cols"] = [
				{ wch: 5 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 50 },
				{ wch: 20 },
			];
			XLSX.utils.book_append_sheet(workbook, worksheet, "Inputs");

			//Writing Impacts Table sheet
			const ImpactsTableSheet = [];
			for (let i = 0; i < formTotalRows; i++) {
				ImpactsTableSheet.push({
					ID: datagridRows[i]["id"],
					"Project Name": datagridRows[i]["name"],
					Country: datagridRows[i]["countryColumn"],
					Region: datagridRows[i]["regionColumn"],
					Year: datagridRows[i]["yearColumn"],
					"Project Industry": datagridRows[i]["industryColumn"],
					"Project Value": datagridRows[i]["invAmountColumn"],
					"Total Output": datagridRows[i]["expectedIncomeColumn"],
					"Top Industry by Output":
						datagridRows[i]["mostAffectedIndustryColumn"],
				});
			}
			worksheet = XLSX.utils.json_to_sheet(ImpactsTableSheet);
			worksheet["!cols"] = [
				{ wch: 5 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 50 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 50 },
			];
			XLSX.utils.book_append_sheet(workbook, worksheet, "Impacts Table");

			//Writing each Input sheet
			for (let input_id = 0; input_id < formTotalRows; input_id++) {
				const CurrentInputSheet = [
					["ID", (input_id + 1).toString()],
					["Project Name", insertedNames[input_id]],
					["Country", Countries[selectedCountries[input_id]]],
					[
						"Region",
						selectedRegions[input_id] !== ""
							? Regions[selectedRegions[input_id]]
							: "-",
					],
					["Year", selectedYears[input_id]],
					["Industry", Industries[selectedIndustries[input_id]]],
					["Investment Amount", insertedInvAmounts[input_id]],
					[],
					[],
					["Industry", "Potential Output"],
				];

				for (let i = 0; i < topIndustriesNames[input_id].length; i++) {
					CurrentInputSheet.push([
						topIndustriesNames[input_id][i]
							.slice(
								topIndustriesNames[input_id][i].lastIndexOf(
									":"
								) + 1
							)
							.trim(),
						Currencies[currency] +
							" " +
							numberWithCommas(topIndustriesImpacts[input_id][i]),
					]);
				}

				worksheet = XLSX.utils.aoa_to_sheet(CurrentInputSheet);
				worksheet["!cols"] = [{ wch: 50 }, { wch: 50 }];
				XLSX.utils.book_append_sheet(
					workbook,
					worksheet,
					insertedNames[input_id] + " - Outputs"
				);
			}
			const timestamp = new Date()
				.toISOString()
				.replace(/[-:.]/g, "")
				.split("T")[0];
			const filename = `impactestimator-export-${timestamp}.xlsx`;
			XLSX.writeFile(workbook, filename);
		} else {
			alert("Start entering some data.");
		}
	};

	const updateSelectedCurrency = (selectedCurrency)=>{
		if (selectedCurrency) {
			const isoCurrencyCode = selectedCurrency!=="-"? selectedCurrency.split(" - ")[0]:"";
			setCurrency(
				isoCurrencyCode
			);
			updateChartsCurrency(
				isoCurrencyCode
			);
		}
	}

	return (
		<div className="page-container">
			<div className="page-information-container">
				<div className="page-title">
					National & Regional Multipliers
				</div>
				<div className="info-divider" />
				<div className="info-description">
					To calculate the total economic impact of a investment
					project across a region or country, select the appropriate
					industry (based on the ISIC system) and country for the
					investment project (you can also enter more than one
					project, with an industry and country needed for each
					project).
					<p>
						<br />
						Multipliers for national impact analysis are calculated
						from the OECD harmonised national input-output tables.
						<br />
						Regional impact analysis is only available for the
						United Kingdom, with regional multipliers based on the
						Office for National Statistics' supply-use tables and
						the FLQ formula developed by Flegg and Webber (1997).
					</p>
				</div>
			</div>
			<div className="form-container">
				<div className="form-top-row">
					<div
						className="enter-inv-data-label"
						id="nat-reg-enter-inv-data-label"
					>
						Enter the data of your investment:
					</div>
					<div className="form-currency-select">
						<FormControl
							style={{ marginLeft: "auto" }}
							size="small"
						>
							<Autocomplete
								sx={{
									"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
										{
											backgroundColor:
												"#fcee21 !important",
										},
									"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
										{
											backgroundColor:
												"#fcee21 !important",
										},
								}}
								size="small"
								disablePortal
								id="currency-autocomplete"
								options={currenciesOptions}
								disableClearable
								value={currency + " - " + Currencies[currency]}
								PaperComponent={({ children }) => (
									<Paper
										sx={{
											"& li": menuItemsStyle,
										}}
									>
										{children}
									</Paper>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										sx={{
											backgroundColor: "white !important",
										}}
										labelId="currency-select-label"
										id="currency-simple-select"
										value={currency}
										label="Currency"
									/>
								)}										
								onClose={(e) => {
									const selectedCurrency = e.target.innerText;
									updateSelectedCurrency(selectedCurrency);
								}}

								onKeyDown={(e) => {
									if(e.code!=="Enter") return;
									const selectedCurrency = document.querySelector(".Mui-focusVisible").innerText;
									updateSelectedCurrency(selectedCurrency);
								}}
							/>
						</FormControl>
					</div>
				</div>
				<div className="form-main">
					{Array.from(
						{ length: formTotalRows },
						(_, index) => index
					).map((row) => (
						<div id={"form-container-row-" + row}>
							<form className={"form-row-" + row} id="form-row">
								<div className="form-row-mobile-buttons">
									<div className="form-row-number-mobile">
										{row + 1}
									</div>
									<div
										className="form-row-remove-action-mobile"
										aria-label="remove project"
										id={"id-remove-row-button-row-" + row}
										onClick={removeRow}
										disabled={formTotalRows === 1}
									>
										<img
											alt="Remove Row"
											src={RemoveIcon}
										/>
									</div>
								</div>
								<div className="form-row-number">{row + 1}</div>
								<FormControl
									size="small"
									id={"id-field-name-row-" + row}
									className="form-cell-first"
								>
									<TextField
										sx={{
											backgroundColor: "white !important",
										}}
										id={"id-name-row-" + row}
										name={"id-name-row-" + row}
										label="Project Name"
										variant="outlined"
										size="small"
										value={insertedNames[row]}
										defaultValue={insertedNames[row]}
										onChange={(e) => {
											const currentRow =
												e.target.name.slice(
													e.target.name.lastIndexOf(
														"-"
													) + 1
												);
											document.getElementById(
												"id-name-row-" + currentRow
											).value = e.target.value;
											let updatedInsertedNames =
												JSON.parse(
													JSON.stringify(
														insertedNames
													)
												);
											updatedInsertedNames[currentRow] =
												e.target.value;
											setInsertedNames(
												updatedInsertedNames
											);
											createEconomicModel(
												currentRow,
												updatedInsertedNames[
													currentRow
												],
												selectedCountries[currentRow],
												selectedRegions[currentRow],
												selectedIndustries[currentRow],
												selectedYears[currentRow],
												insertedInvAmounts[currentRow],
												currency
											);
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												const currentRow =
													e.target.name.slice(
														e.target.name.lastIndexOf(
															"-"
														) + 1
													);

												let updatedInsertedNames =
													insertedNames;
												updatedInsertedNames[
													currentRow
												] = e.target.value;
												setInsertedNames(
													updatedInsertedNames
												);
												createEconomicModel(
													currentRow,
													updatedInsertedNames[
														currentRow
													],
													selectedCountries[
														currentRow
													],
													selectedRegions[currentRow],
													selectedIndustries[
														currentRow
													],
													selectedYears[currentRow],
													insertedInvAmounts[
														currentRow
													],
													currency
												);
											}
										}}
									/>
								</FormControl>

								<FormControl
									size="small"
									id={"id-field-country-row-" + row}
									className="form-cell"
								>
									{/* <InputLabel
										id={"id-field-country-label-row-" + row}
									>
										Country
									</InputLabel> */}
									{selectedCountries[row].length > 0 ? (
										<div>
											<Autocomplete
												sx={{
													"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
														{
															backgroundColor:
																"#fcee21 !important",
														},
													"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
														{
															backgroundColor:
																"#fcee21 !important",
														},
												}}
												size="small"
												disablePortal
												id={
													"id-country-autocomplete-" +
													row
												}
												options={countriesOptions}
												disableClearable
												PaperComponent={({
													children,
												}) => (
													<Paper
														sx={{
															"& li": menuItemsStyle,
														}}
													>
														{children}
													</Paper>
												)}
												value={
													Countries[
														selectedCountries[row]
													]
												}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															backgroundColor:
																"white !important",
														}}
														labelId="country-select-label"
														id="country-simple-select"
														value={
															selectedCountries[
																row
															]
														}
														label="Country"
													/>
												)}
												onClose={(e) => {
													const currentRow =
														e.target.id
															.split(
																"autocomplete-"
															)[1]
															.split(
																"-option"
															)[0];
													const selectedCountry =
														getKeyByValue(
															Countries,
															e.target.innerText
														);
													if (
														typeof selectedCountry !==
														"undefined"
													) {
														// the row is active, so we have to enable the empty-field class
														markRowAsEmpty(
															currentRow
														);
														let updatedSelectedCountries =
															selectedCountries;
														updatedSelectedCountries[
															currentRow
														] = selectedCountry;
														setSelectedCountries(
															updatedSelectedCountries
														);

														// disabling/enabling region field
														if (
															selectedCountry ===
															"GBR"
														) {
															document
																.getElementById(
																	"id-field-region-row-" +
																		currentRow
																)
																.querySelectorAll(
																	"*"
																)
																.forEach(
																	(
																		element
																	) => {
																		element.classList.remove(
																			"Mui-disabled"
																		);
																	}
																);
															document.getElementById(
																"id-field-region-row-" +
																	currentRow
															).style.pointerEvents =
																"auto";

															if (
																selectedRegions[
																	currentRow
																] === ""
															) {
																// adding region empty field style if not empty
																document
																	.getElementById(
																		"id-field-region-row-" +
																			currentRow
																	)
																	.classList.add(
																		"empty-field"
																	);
															}
														} else {
															document
																.getElementById(
																	"id-field-region-row-" +
																		currentRow
																)
																.querySelectorAll(
																	"*"
																)
																.forEach(
																	(
																		element
																	) => {
																		element.classList.add(
																			"Mui-disabled"
																		);
																	}
																);
															document.getElementById(
																"id-field-region-row-" +
																	currentRow
															).style.pointerEvents =
																"none";

															document.getElementById(
																"id-region-autocomplete-" +
																	currentRow +
																	"-label"
															).style.color =
																"#B56666 !important";

															// removing region empty field style
															removeEmptyStyle(
																"region",
																currentRow
															);
														}

														//changing the region back to ""
														let updatedSelectedRegions =
															[
																...selectedRegions,
															];
														updatedSelectedRegions[
															currentRow
														] = "";
														setSelectedRegions(
															updatedSelectedRegions
														);

														if (
															selectedRegions[
																currentRow
															] !== ""
														) {
															document.getElementById(
																"id-region-row-" +
																	currentRow
															).innerHTML = "-";
														}

														// removing empty field style once it got a value
														removeEmptyStyle(
															"country",
															currentRow
														);

														createEconomicModel(
															currentRow,
															insertedNames[
																currentRow
															],
															selectedCountry,
															selectedRegions[
																currentRow
															],
															selectedIndustries[
																currentRow
															],
															selectedYears[
																currentRow
															],
															insertedInvAmounts[
																currentRow
															],
															currency
														);
													}
												}}
											/>
										</div>
									) : (
										<Autocomplete
											sx={{
												"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
													{
														backgroundColor:
															"#fcee21 !important",
													},
												"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
													{
														backgroundColor:
															"#fcee21 !important",
													},
											}}
											size="small"
											disablePortal
											id={
												"id-country-autocomplete-" + row
											}
											options={countriesOptions}
											disableClearable
											PaperComponent={({ children }) => (
												<Paper
													sx={{
														"& li": menuItemsStyle,
													}}
												>
													{children}
												</Paper>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														backgroundColor:
															"white !important",
													}}
													labelId="country-select-label"
													id="country-simple-select"
													value={
														selectedCountries[row]
													}
													label="Country"
												/>
											)}
											onClose={(e) => {
												const currentRow = e.target.id
													.split("autocomplete-")[1]
													.split("-option")[0];
												const selectedCountry =
													getKeyByValue(
														Countries,
														e.target.innerText
													);
												if (
													typeof selectedCountry !==
													"undefined"
												) {
													// the row is active, so we have to enable the empty-field class
													markRowAsEmpty(currentRow);
													let updatedSelectedCountries =
														selectedCountries;
													updatedSelectedCountries[
														currentRow
													] = selectedCountry;
													setSelectedCountries(
														updatedSelectedCountries
													);

													// disabling/enabling region field
													if (
														selectedCountry ===
														"GBR"
													) {
														document
															.getElementById(
																"id-field-region-row-" +
																	currentRow
															)
															.querySelectorAll(
																"*"
															)
															.forEach(
																(element) => {
																	element.classList.remove(
																		"Mui-disabled"
																	);
																}
															);
														document.getElementById(
															"id-field-region-row-" +
																currentRow
														).style.pointerEvents =
															"auto";

														if (
															selectedRegions[
																currentRow
															] === ""
														) {
															// adding region empty field style if not empty
															document
																.getElementById(
																	"id-field-region-row-" +
																		currentRow
																)
																.classList.add(
																	"empty-field"
																);
														}
													} else {
														document
															.getElementById(
																"id-field-region-row-" +
																	currentRow
															)
															.querySelectorAll(
																"*"
															)
															.forEach(
																(element) => {
																	element.classList.add(
																		"Mui-disabled"
																	);
																}
															);
														document.getElementById(
															"id-field-region-row-" +
																currentRow
														).style.pointerEvents =
															"none";

														document.getElementById(
															"id-region-autocomplete-" +
																currentRow +
																"-label"
														).style.color =
															"#B56666 !important";

														// removing region empty field style
														removeEmptyStyle(
															"region",
															currentRow
														);
													}

													//changing the region back to ""
													let updatedSelectedRegions =
														selectedRegions;
													updatedSelectedRegions[
														currentRow
													] = "";
													setSelectedRegions(
														updatedSelectedRegions
													);

													if (
														selectedRegions[
															currentRow
														] !== ""
													) {
														document.getElementById(
															"id-region-row-" +
																currentRow
														).innerHTML = "-";
													}

													// removing empty field style once it got a value
													removeEmptyStyle(
														"country",
														currentRow
													);

													createEconomicModel(
														currentRow,
														insertedNames[
															currentRow
														],
														selectedCountry,
														selectedRegions[
															currentRow
														],
														selectedIndustries[
															currentRow
														],
														selectedYears[
															currentRow
														],
														insertedInvAmounts[
															currentRow
														],
														currency
													);
												}
											}}
										/>
									)}
								</FormControl>
								<FormControl
									size="small"
									id={"id-field-region-row-" + row}
									className="form-cell"
								>
									{selectedRegions[row].length > 0 ? (
										<div>
											<Autocomplete
												sx={{
													"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
														{
															backgroundColor:
																"#fcee21 !important",
														},
													"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
														{
															backgroundColor:
																"#fcee21 !important",
														},
												}}
												size="small"
												disablePortal
												id={
													"id-region-autocomplete-" +
													row
												}
												options={regionsOptions}
												disableClearable
												PaperComponent={({
													children,
												}) => (
													<Paper
														sx={{
															"& li": menuItemsStyle,
														}}
													>
														{children}
													</Paper>
												)}
												value={
													Regions[
														selectedRegions[row]
													]
												}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															backgroundColor:
																"white !important",
														}}
														labelId="region-select-label"
														id="region-simple-select"
														value={
															selectedRegions[row]
														}
														label="Region"
													/>
												)}
												onClose={(e) => {
													const currentRow =
														e.target.id
															.split(
																"autocomplete-"
															)[1]
															.split(
																"-option"
															)[0];
													const selectedRegion =
														getKeyByValue(
															Regions,
															e.target.innerText
														);
													if (
														typeof selectedRegion !==
														"undefined"
													) {
														// the row is active, so we have to enable the empty-field class
														markRowAsEmpty(
															currentRow
														);
														let updatedSelectedRegions =
															selectedRegions;
														updatedSelectedRegions[
															currentRow
														] = selectedRegion;
														setSelectedRegions(
															updatedSelectedRegions
														);
														let updatedSelectedCountries =
															[
																...selectedCountries,
															];
														updatedSelectedCountries[
															currentRow
														] = "GBR";
														setSelectedCountries(
															updatedSelectedCountries
														);
														createEconomicModel(
															currentRow,
															insertedNames[
																currentRow
															],
															updatedSelectedCountries[
																currentRow
															],
															selectedRegion,
															selectedIndustries[
																currentRow
															],
															selectedYears[
																currentRow
															],
															insertedInvAmounts[
																currentRow
															],
															currency
														);
														// removing empty field style once it got a value
														removeEmptyStyle(
															"region",
															currentRow
														);
														// removing empty field style once it got a value, also for country because it will be uk
														removeEmptyStyle(
															"country",
															currentRow
														);
													}
												}}
											/>
										</div>
									) : (
										<Autocomplete
											sx={{
												"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
													{
														backgroundColor:
															"#fcee21 !important",
													},
												"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
													{
														backgroundColor:
															"#fcee21 !important",
													},
											}}
											size="small"
											disablePortal
											id={"id-region-autocomplete-" + row}
											options={regionsOptions}
											disableClearable
											PaperComponent={({ children }) => (
												<Paper
													sx={{
														"& li": menuItemsStyle,
													}}
												>
													{children}
												</Paper>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														backgroundColor:
															"white !important",
													}}
													labelId="region-select-label"
													id="region-simple-select"
													value={selectedRegions[row]}
													label="Region"
												/>
											)}
											onClose={(e) => {
												const currentRow = e.target.id
													.split("autocomplete-")[1]
													.split("-option")[0];
												const selectedRegion =
													getKeyByValue(
														Regions,
														e.target.innerText
													);
												if (
													typeof selectedRegion !==
													"undefined"
												) {
													// the row is active, so we have to enable the empty-field class
													markRowAsEmpty(currentRow);
													let updatedSelectedRegions =
														selectedRegions;
													updatedSelectedRegions[
														currentRow
													] = selectedRegion;
													setSelectedRegions(
														updatedSelectedRegions
													);
													let updatedSelectedCountries =
														[...selectedCountries];

													updatedSelectedCountries[
														currentRow
													] = "GBR";
													setSelectedCountries(
														updatedSelectedCountries
													);
													createEconomicModel(
														currentRow,
														insertedNames[
															currentRow
														],
														updatedSelectedCountries[
															currentRow
														],
														selectedRegion,
														selectedIndustries[
															currentRow
														],
														selectedYears[
															currentRow
														],
														insertedInvAmounts[
															currentRow
														],
														currency
													);
													// removing empty field style once it got a value
													removeEmptyStyle(
														"region",
														currentRow
													);
													// removing empty field style once it got a value, also for country because it will be uk
													removeEmptyStyle(
														"country",
														currentRow
													);
												}
											}}
										/>
									)}
								</FormControl>
								<FormControl
									size="small"
									id={"id-field-industry-row-" + row}
									className="form-cell"
								>
									{selectedIndustries[row] ? (
										<div>
											<Autocomplete
												sx={{
													"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
														{
															backgroundColor:
																"#fcee21 !important",
														},
													"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
														{
															backgroundColor:
																"#fcee21 !important",
														},
												}}
												style={{
													backgroundColor:
														"white !important",
												}}
												size="small"
												disablePortal
												id={
													"id-industry-autocomplete-" +
													row
												}
												options={industriesOptions}
												disableClearable
												PaperComponent={({
													children,
												}) => (
													<Paper
														sx={{
															"& li": menuItemsStyle,
														}}
													>
														{children}
													</Paper>
												)}
												value={
													Industries[
														selectedIndustries[row]
													]
												}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															backgroundColor:
																"white !important",
														}}
														labelId="industry-select-label"
														id="industry-simple-select"
														value={
															selectedIndustries[
																row
															]
														}
														label="Industry"
													/>
												)}
												onClose={(e) => {
													const currentRow =
														e.target.id
															.split(
																"autocomplete-"
															)[1]
															.split(
																"-option"
															)[0];

													const selectedIndustry =
														getKeyByValue(
															Industries,
															e.target.innerText
														);
													if (
														typeof selectedIndustry !==
														"undefined"
													) {
														// the row is active, so we have to enable the empty-field class
														markRowAsEmpty(
															currentRow
														);
														let updatedSelectedIndustries =
															selectedIndustries;
														updatedSelectedIndustries[
															currentRow
														] = selectedIndustry;
														setSelectedIndustries(
															updatedSelectedIndustries
														);
														createEconomicModel(
															currentRow,
															insertedNames[
																currentRow
															],
															selectedCountries[
																currentRow
															],
															selectedRegions[
																currentRow
															],
															selectedIndustry,
															selectedYears[
																currentRow
															],
															insertedInvAmounts[
																currentRow
															],
															currency
														);
														// removing empty field style once it got a value
														removeEmptyStyle(
															"industry",
															currentRow
														);
													}
												}}
											/>
										</div>
									) : (
										<Autocomplete
											sx={{
												"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
													{
														backgroundColor:
															"#fcee21 !important",
													},
												"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
													{
														backgroundColor:
															"#fcee21 !important",
													},
											}}
											size="small"
											disablePortal
											id={
												"id-industry-autocomplete-" +
												row
											}
											options={industriesOptions}
											disableClearable
											PaperComponent={({ children }) => (
												<Paper
													sx={{
														"& li": menuItemsStyle,
													}}
												>
													{children}
												</Paper>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														backgroundColor:
															"white !important",
													}}
													labelId="industry-select-label"
													id="industry-simple-select"
													value={
														selectedIndustries[row]
													}
													label="Industry"
												/>
											)}
											onClose={(e) => {
												const currentRow = e.target.id
													.split("autocomplete-")[1]
													.split("-option")[0];

												const selectedIndustry =
													getKeyByValue(
														Industries,
														e.target.innerText
													);
												if (
													typeof selectedIndustry !==
													"undefined"
												) {
													// the row is active, so we have to enable the empty-field class
													markRowAsEmpty(currentRow);
													let updatedSelectedIndustries =
														selectedIndustries;
													updatedSelectedIndustries[
														currentRow
													] = selectedIndustry;
													setSelectedIndustries(
														updatedSelectedIndustries
													);
													createEconomicModel(
														currentRow,
														insertedNames[
															currentRow
														],
														selectedCountries[
															currentRow
														],
														selectedRegions[
															currentRow
														],
														selectedIndustry,
														selectedYears[
															currentRow
														],
														insertedInvAmounts[
															currentRow
														],
														currency
													);
													// removing empty field style once it got a value
													removeEmptyStyle(
														"industry",
														currentRow
													);
												}
											}}
										/>
									)}
								</FormControl>
								<FormControl
									size="small"
									id={"id-field-invamount-row-" + row}
									className="form-cell-last"
								>
									<NumericFormat
										id={"id-invamount-row-" + row}
										label="Investment Amount"
										variant="outlined"
										name={"id-invamount-row-" + row}
										value={insertedInvAmounts[row]}
										sx={{
											backgroundColor: "white !important",
										}}
										onChange={(e) => {
											const currentRow =
												e.target.name.slice(
													e.target.name.lastIndexOf(
														"-"
													) + 1
												);
											// the row is active, so we have to enable the empty-field class
											markRowAsEmpty(currentRow);
											if (e.target.value === "") {
												if (formTotalRows > 1) {
													removeRow(e);
												}
												document
													.getElementById(
														"id-field-invamount-row-" +
															currentRow
													)
													.classList.add(
														"empty-field"
													);
											}

											let updatedInsertedInvAmounts =
												insertedInvAmounts;
											updatedInsertedInvAmounts[
												currentRow
											] = e.target.value;
											setInsertedInvAmounts(
												updatedInsertedInvAmounts
											);
											createEconomicModel(
												currentRow,
												insertedNames[currentRow],
												selectedCountries[currentRow],
												selectedRegions[currentRow],
												selectedIndustries[currentRow],
												selectedYears[currentRow],
												e.target.value,
												currency
											);
											if (e.target.value !== "") {
												removeEmptyStyle(
													"invamount",
													currentRow
												);
											}
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												const currentRow =
													e.target.name.slice(
														e.target.name.lastIndexOf(
															"-"
														) + 1
													);
												// the row is active, so we have to enable the empty-field class
												markRowAsEmpty(currentRow);
												if (e.target.value === "") {
													if (formTotalRows > 1) {
														removeRow(e);
													}
													document
														.getElementById(
															"id-field-invamount-row-" +
																currentRow
														)
														.classList.add(
															"empty-field"
														);
												}

												let updatedInsertedInvAmounts =
													insertedInvAmounts;
												updatedInsertedInvAmounts[
													currentRow
												] = e.target.value;
												setInsertedInvAmounts(
													updatedInsertedInvAmounts
												);
												createEconomicModel(
													currentRow,
													insertedNames[currentRow],
													selectedCountries[
														currentRow
													],
													selectedRegions[currentRow],
													selectedIndustries[
														currentRow
													],
													selectedYears[currentRow],
													e.target.value,
													currency
												);
												if (e.target.value !== "") {
													removeEmptyStyle(
														"invamount",
														currentRow
													);
												}
											}
										}}
										size="small"
										thousandSeparator={true}
										prefix={Currencies[currency] + " "}
										inputMode="numeric"
										allowNegative={false}
										customInput={TextField}
										isAllowed={withValueCap}
									/>
								</FormControl>

								<div
									className="form-row-remove-action"
									aria-label="remove project"
									id={"id-remove-row-button-row-" + row}
									onClick={removeRow}
									disabled={formTotalRows === 1}
								>
									<img alt="Remove Row" src={RemoveIcon} />
								</div>
							</form>
						</div>
					))}
				</div>
				{formTotalRows < MAX_PROJECTS_NUMBER && (
					<div
						className="form-bottom-row"
						onClick={() => {
							setFormTotalRows(formTotalRows + 1);
						}}
					>
						<div className="form-add-row-icon">
							<img alt="Add Row" src={AddIcon} />
						</div>
						<div className="form-add-row-text">Add Project</div>
					</div>
				)}
			</div>
			<div className="table-container">
				<div className="form-top-row" style={{ marginBottom: 0 }}>
					<div className="enter-inv-data-label">Project impacts</div>
				</div>
				<Box
					className="datagrid-container"
					sx={{
						"& .custom-header-class": {
							backgroundColor: "#f4f4f0",
							border: "none !important",
							"& > .MuiDataGrid-columnSeparator": {
								visibility: "hidden",
							},
						},
						"& .custom-cell-class": {
							backgroundColor: "white",
							// border: "1px solid black !important",
							border: "2px solid #f4f4f0 !important",
							// borderRadius: "6px",
							// margin: "2px",
							outline: "none",
						},
						"& .custom-row-class": {
							border: "none !important",
						},
					}}
				>
					<DataGrid
						rows={datagridRows}
						rowHeight={50}
						columns={datagridColumns}
						pageSize={MAX_PROJECTS_NUMBER}
						rowsPerPageOptions={[MAX_PROJECTS_NUMBER]}
						disableColumnFilter
						disableColumnMenu
						disableColumnSelector
						disableDensitySelector
						disableExtendRowFullWidth
						disableIgnoreModificationsIfProcessingProps
						disableSelectionOnClick
						disableVirtualization
						autoHeight
						// hideFooter={true}
						components={{
							Footer: MyExportButton,
							NoRowsOverlay: MyNoRowsOverlay,
						}}
						getRowClassName={() => "custom-row-class"}
						sx={{
							fontSize: "12px",
							border: "none !important",
						}}
					/>
				</Box>
				<div className="table-tools">
					<div className="table-summed-data">
						<span>
							Total Value of all Projects:{" "}
							<b>
								{Currencies[currency] +
									" " +
									numberWithCommas(
										insertedInvAmounts
											.slice(0, formTotalRows)
											.reduce((acc, val) => {
												if (datagridRows.length) {
													const number = Number(
														val
															.replace(
																/[^0-9,-.]+/g,
																""
															)
															.replace(/,/g, "")
													);
													return acc + number;
												} else {
													return 0;
												}
											}, 0)
									)}
							</b>
						</span>
						<span>
							Total Output of all Projects:{" "}
							<b>
								{Currencies[currency] +
									" " +
									numberWithCommas(
										economicImpacts
											.slice(0, formTotalRows)
											.reduce((acc, val) => {
												if (val) {
													const number = Number(
														val
															.replace(
																/[^0-9,-.]+/g,
																""
															)
															.replace(/,/g, "")
													);
													return acc + number;
												} else {
													return 0;
												}
											}, 0)
									)}
							</b>
						</span>
					</div>
					<div className="export-button" onClick={exportData}>
						EXPORT
						<img alt="Export Table" src={ExportIcon} />
					</div>
				</div>
			</div>
			{/* Eric, 16/03/23 The graph for "Top 5 industries affected" and the
			right-most column "Higher Impact" aren't the correct numbers (this
			is my fault entirely for not catching this sooner) - we need to read
			the numbers off the "I-A matrix" (line 70) of
			calculate_multipliers.pySo I'm inclined to drop the graph and
			rightmost column for now, until we can fix this. */}
			{/* thats why we use "false" */}
			{false && Object.entries(chartOptions).length > 0 && (
				<div className="charts-container">
					<h2>
						Error with width of small chart rotating two times
						(mobile)
					</h2>
					<h2>
						Second chart didn't appear when setting three projects
					</h2>

					<h2>
						{
							"Fix number of digits on All projects graph when <100 CHARTS REMOVED "
						}
					</h2>
					{Object.keys(topExpectedIncomeValues).length > 0 &&
						formTotalRows > 1 && (
							<div className="wide-chart">
								<HighchartsReact
									ref={chart}
									highcharts={Highcharts}
									options={{
										chart: {
											backgroundColor: "transparent",
											height: 330,
										},
										colors: new Array(
											FULL_GRAPH_TOP_INDUSTRIES_TOTAL
										).fill("white"),

										exporting: {
											chartOptions: {
												chart: {
													backgroundColor: "#f4f4f0",
												},
											},
											credits: {
												enabled: false,
											},
											filename:
												chart_filename +
												"-top" +
												FULL_GRAPH_TOP_INDUSTRIES_TOTAL.toString(),
											buttons: {
												contextButton: {
													menuItems: [
														{
															text: "Export as PNG",
															onclick:
																function () {
																	this.exportChart(
																		{
																			filename:
																				"ImpactEstimator - All Projects",
																		}
																	);
																},
														},
													],
												},
											},
										},

										navigation: {
											buttonOptions: {
												theme: {
													"stroke-width": 0,
													fill: "transparent",
													r: 0,
												},
											},
										},
										title: {
											text:
												"All Projects: Top " +
												FULL_GRAPH_TOP_INDUSTRIES_TOTAL +
												" industries affected",
										},

										xAxis: {
											lineColor: "black",
											categories:
												topExpectedIncomeKeys.slice(
													0,
													FULL_GRAPH_TOP_INDUSTRIES_TOTAL
												),
											labels: {
												style: {
													//whiteSpace: "normal",
													color: "#000000",
													fontSize: "10px",
												},
												formatter: function () {
													var label =
														this.axis.defaultLabelFormatter.call(
															this
														);
													if (
														label.length >
														MAX_INDUSTRY_CHARS
													) {
														return (
															label.substring(
																0,
																MAX_INDUSTRY_CHARS
															) + "..."
														);
													} else {
														return label;
													}
												},
											},
										},
										yAxis: {
											title: {
												text:
													"Calculated Economic Impact (" +
													Currencies[currency] +
													")",
											},
										},
										legend: {
											enabled: false,
										},
										series: [
											{
												type: "column",
												name: "Economic Impact",
												colorByPoint: true,
												data: topExpectedIncomeValues.slice(
													0,
													FULL_GRAPH_TOP_INDUSTRIES_TOTAL
												),
												showInLegend: false,
												borderColor: "black",
											},
										],
									}}
								/>
							</div>
						)}
					<div className="small-charts-container">
						{Object.entries(chartOptions).map(([index, value]) => {
							return (
								<div className="chart">
									<div>
										<HighchartsReact
											ref={chart}
											highcharts={Highcharts}
											options={value}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}
export default NatRegMults;
