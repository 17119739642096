import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

const THEME = createTheme({
	typography: {
		fontFamily: `"Archia", sans-serif`,
	},
	palette: {
		primary: {
			main: "#000000",
		},
		secondary: {
			main: "#fcee21",
		},
	},
});
ReactDOM.render(
	<ThemeProvider theme={THEME}>
		<BrowserRouter>
			<App />
		</BrowserRouter>{" "}
	</ThemeProvider>,
	document.getElementById("root")
);
