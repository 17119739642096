import {
	FormControl,
	Paper,
	MenuItem,
	TextField,
	Typography,
	Popover,
	MenuList,
	Tooltip,
	Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Industries, Countries, Regions, Multipliers } from "../data/Data.js";
import { NumericFormat } from "react-number-format";
import UkJson from "../data/Uk.topo.json";
import WorldMap from "../data/WorldMap.json";
import "../css/CountryComp.css";
import { scaleLinear } from "d3-scale";
import * as htmlToImage from "html-to-image";
import XLSX from "sheetjs-style";
import ExportIcon from "../images/icons/export.svg";
import * as FileSaver from "file-saver";
import Currencies from "../data/Currencies.json";

function CountryComp() {
	const [currency, setCurrency] = useState("GBP");
	const [ukMapWidth, setUkMapWidth] = React.useState(450);
	const [anchorElUk, setAnchorElUk] = React.useState(null);
	const [anchorElWorld, setAnchorElWorld] = React.useState(null);
	function handleClick(event, map) {
		if (map === "uk") {
			setAnchorElUk(event.currentTarget);
		} else if (map === "world") {
			setAnchorElWorld(event.currentTarget);
		}
	}
	function handleClose(map) {
		if (map === "uk") {
			setAnchorElUk(null);
		} else if (map === "world") {
			setAnchorElWorld(null);
		}
	}

	const industriesOptions = [];
	Object.keys(Industries).forEach((industry) =>
		industriesOptions.push({ label: Industries[industry] })
	);

	const openUk = Boolean(anchorElUk);
	const openWorld = Boolean(anchorElWorld);
	const idUk = openUk ? "uk-popover" : undefined;
	const idWorld = openWorld ? "world-popover" : undefined;

	const menuItemsStyle = {
		"&:hover": {
			backgroundColor: "#fcee21 !important",
		},
		"&:focus": {
			backgroundColor: "#fcee21 !important",
		},
		"&.Mui-selected": {
			backgroundColor: "#fcee21 !important",
		},
	};

	// form variables to calculate economic impact
	const [inv_amount, setInvAmount] = useState("100");
	const [invAmountNum, setInvAmountNum] = useState(100);
	const [industry, setIndustry] = useState("");
	const year = "2018";

	// user can not set an investment amount over 1 billion
	const withValueCap = (inputObj) => {
		const { value } = inputObj;
		if (value <= 1000000000) return true;
		return false;
	};

	const [worldScale, setWorldScale] = useState(() => {
		if (window.innerWidth > 1370) {
			return 140;
		}
		return 120;
	});

	const currenciesOptions = [];
	Object.keys(Currencies).forEach((c) =>
		currenciesOptions.push({ label: c + " - " + Currencies[c] })
	);

	useEffect(() => {
		const handleWindowResize = () => {
			if (window.innerWidth > 1370) {
				setWorldScale(140);
				setUkMapWidth(
					document
						.getElementsByClassName("info-description")[0]
						.getBoundingClientRect().left -
						document
							.getElementsByClassName(
								"country-comp-main-container"
							)[0]
							.getBoundingClientRect().left
				);
			} else {
				setWorldScale(120);
				setUkMapWidth(
					document.getElementsByClassName(
						"country-comp-main-container"
					)[0].offsetWidth
				);
			}
		};

		if (window.innerWidth > 1370) {
			setUkMapWidth(
				document
					.getElementsByClassName("info-description")[0]
					.getBoundingClientRect().left -
					document
						.getElementsByClassName(
							"country-comp-main-container"
						)[0]
						.getBoundingClientRect().left
			);
		} else {
			setUkMapWidth(
				document.getElementsByClassName(
					"country-comp-main-container"
				)[0].offsetWidth
			);
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	});

	function getKeyByValue(object, value) {
		return Object.keys(object).find((key) => object[key] === value);
	}

	// here we update the form values every time any field changes
	function handleChange(event, field) {
		const fieldValue = event.target.innerText

		// If the field remains unchanged or empty, we do not re-render.
		// This is to ensure that we do not set the states with an empty string, which may
		// cause some unexpected issues down the line. E.g. with the dropdown input "industry"
		// if you unfocus (click away) without selecting anything, the application will crash.
		if(!fieldValue){
			return;
		}
		if (field === "inv_amount") {
			const { value } = event.target;
			setInvAmountNum(
				parseFloat(
					value
						.replace(Currencies[currency], "")
						.replace(" ", "")
						.replaceAll(",", "")
				)
			);
			setInvAmount(value);
		} else if (field === "industry") {
			setIndustry(getKeyByValue(Industries, event.target.innerText));
		}
		// else if (field === "year") {
		// 	setYear(value);
		// }
	}

	let dataset = {};

	// We need to colorize every country based on "numberOfWhatever"
	// colors should be uniq for every value.
	// For this purpose we create palette(using min/max this.props.data-value)
	var minValueRegions = Number.POSITIVE_INFINITY;
	var maxValueRegions = Number.NEGATIVE_INFINITY;
	var minValueWorld = Number.POSITIVE_INFINITY;
	var maxValueWorld = Number.NEGATIVE_INFINITY;
	var tmp;
	Multipliers.forEach((item) => {
		if (
			item.YEAR === parseInt(year) &&
			item.INDUSTRY === parseInt(industry) &&
			inv_amount !== ""
		) {
			tmp = item.MULT;
			if (Object.keys(Regions).includes(item.LOCATION)) {
				if (tmp < minValueRegions) minValueRegions = tmp;
				if (tmp > maxValueRegions) maxValueRegions = tmp;
			} else {
				if (tmp < minValueWorld) minValueWorld = tmp;
				if (tmp > maxValueWorld) maxValueWorld = tmp;
			}
		}
	});

	// create color palette function
	// color can be whatever you wish

	// let paletteScaleRegions = scaleLinear()
	// 	.domain([minValueRegions, maxValueRegions])
	// 	.range(["#83D0E2", "#006837"]);
	// let paletteScaleWorld = scaleLinear()
	// 	.domain([minValueWorld, maxValueWorld])
	// 	.range(["#83D0E2", "#006837"]);
	let paletteScaleRegions = scaleLinear()
		.domain([minValueRegions, maxValueRegions])
		.range(["#ffd4a6", "#f46700"]);
	let paletteScaleWorld = scaleLinear()
		.domain([minValueWorld, maxValueWorld])
		.range(["#ffd4a6", "#f46700"]);
	// fill dataset in appropriate format
	Multipliers.forEach(function (item) {
		if (
			item.YEAR === parseInt(year) &&
			item.INDUSTRY === parseInt(industry) &&
			inv_amount !== ""
		) {
			// item example value ["USA", 70]
			let iso = item.LOCATION,
				value = item.MULT;
			if (Object.keys(Countries).includes(iso)) {
				dataset[iso] = {
					numberOfThings: value,
					fillColor: paletteScaleWorld(value),
				};
			} else {
				dataset[iso] = {
					numberOfThings: value,
					fillColor: paletteScaleRegions(value),
				};
			}
			if (inv_amount !== "") {
				let multiplier = inv_amount.replace(/[^0-9.]/g, "");
				while (true) {
					if (multiplier.startsWith(".")) {
						multiplier = multiplier.slice(1);
					} else {
						break;
					}
				}
				while (true) {
					if (multiplier.endsWith(".")) {
						multiplier = multiplier.slice(0, -1);
					} else {
						break;
					}
				}
				console.log(multiplier);
				console.log(parseInt(multiplier));
				dataset[iso]["profit"] = value * parseFloat(multiplier);
			}
		}
	});

	const toolTipTitleRegion = (region) => {
		if (
			typeof dataset[
				Object.keys(Regions).find((key) => Regions[key] === region)
			] === "undefined"
		)
			return <div>Region: {region}</div>;
		else {
			if (
				typeof dataset[
					Object.keys(Regions).find((key) => Regions[key] === region)
				]["profit"] === "undefined"
			) {
				return <div>Region: {region}</div>;
			} else {
				return (
					<div>
						Region: {region}
						<br />
						Total Income: {Currencies[currency] + " "}
						{invAmountNum > 100
							? Math.round(
									dataset[
										Object.keys(Regions).find(
											(key) => Regions[key] === region
										)
									]["profit"]
							  ).toLocaleString(undefined, {
									maximumFractionDigits: 0,
							  })
							: (
									Math.round(
										dataset[
											Object.keys(Regions).find(
												(key) => Regions[key] === region
											)
										]["profit"] * 100
									) / 100
							  ).toLocaleString(undefined, {
									maximumFractionDigits: 2,
							  })}
					</div>
				);
			}
		}
	};

	const toolTipTitleCountry = (countryId, countryName) => {
		if (typeof dataset[countryId] === "undefined")
			return <div>Country: {countryName}</div>;
		else {
			if (typeof dataset[countryId]["profit"] === "undefined") {
				return <div>Country: {countryName}</div>;
			} else {
				return (
					<div>
						Country: {countryName}
						<br />
						Total Income: {Currencies[currency] + " "}
						{invAmountNum > 100
							? Math.round(
									dataset[countryId]["profit"]
							  ).toLocaleString(undefined, {
									maximumFractionDigits: 0,
							  })
							: (
									Math.round(
										dataset[countryId]["profit"] * 100
									) / 100
							  ).toLocaleString(undefined, {
									maximumFractionDigits: 2,
							  })}
					</div>
				);
			}
		}
	};

	const handleDownloadImage = (e, map) => {
		e.preventDefault();
		let element = document.getElementById("world-map");
		let fileName = "world-map.png";
		if (map === "uk") {
			element = document.getElementById("uk-map");
			fileName = "uk-map.png";
		}
		htmlToImage.toPng(element).then(function (dataUrl) {
			saveAs(dataUrl, fileName);
		});
	};

	const saveAs = (blob, fileName) => {
		var elem = window.document.createElement("a");
		elem.href = blob;
		elem.download = fileName;
		elem.style = "display:none;";
		(document.body || document.documentElement).appendChild(elem);
		if (typeof elem.click === "function") {
			elem.click();
		} else {
			elem.target = "_blank";
			elem.dispatchEvent(
				new MouseEvent("click", {
					view: window,
					bubbles: true,
					cancelable: true,
				})
			);
		}
		URL.revokeObjectURL(elem.href);
		elem.remove();
	};

	function exportExcel(e, type) {
		e.preventDefault();
		const fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileName = type + ".xlsx";
		let ukRegions = [];
		let countries = [];
		let regionChars = 10;
		let countriesChars = 12;
		let industryChars = 11;
		let yearChars = 7;
		let expectedIncomeChars = 16;

		Object.keys(dataset).forEach((key) => {
			if (type === "uk") {
				if (Object.keys(Regions).includes(key)) {
					if (Regions[key].length > regionChars) {
						regionChars = Regions[key].length;
					}
					if (Industries[industry].length > industryChars) {
						industryChars = Industries[industry].length;
					}
					ukRegions.push({
						Region: Regions[key],
						Year: year,
						Industry: Industries[industry],
						"Total Income": dataset[key]["profit"].toLocaleString(
							undefined,
							{
								maximumFractionDigits: 0,
							}
						),
					});
				}
			} else {
				if (Object.keys(Countries).includes(key)) {
					if (Countries[key].length > countriesChars) {
						countriesChars = Countries[key].length;
					}
					if (Industries[industry].length > industryChars) {
						industryChars = Industries[industry].length;
					}
					countries.push({
						Country: Countries[key],
						Year: year,
						Industry: Industries[industry],
						"Total Income": dataset[key]["profit"].toLocaleString(
							undefined,
							{
								maximumFractionDigits: 0,
							}
						),
					});
				}
			}
		});
		let ws;
		let wscols;
		if (type === "uk") {
			ws = XLSX.utils.json_to_sheet(ukRegions);
			wscols = [
				{ wch: regionChars + 3 },
				{ wch: yearChars },
				{ wch: industryChars + 3 },
				{ wch: expectedIncomeChars },
			];
		} else {
			ws = XLSX.utils.json_to_sheet(countries);
			wscols = [
				{ wch: countriesChars + 3 },
				{ wch: yearChars },
				{ wch: industryChars + 3 },
				{ wch: expectedIncomeChars },
			];
		}

		ws["!cols"] = wscols;
		ws["A1"].s = { font: { bold: true } };
		ws["B1"].s = { font: { bold: true } };
		ws["C1"].s = { font: { bold: true } };
		ws["D1"].s = { font: { bold: true } };

		const wb = {
			Sheets: type === "uk" ? { UK: ws } : { World: ws },
			SheetNames: type === "uk" ? ["UK"] : ["World"],
		};
		const excelBuffer = XLSX.write(wb, {
			bookTyoe: "xlsx",
			type: "array",
		});
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName);
	}

	const updateSelectedCurrency = (selectedCurrency)=>{
		if (selectedCurrency) {
			const isoCurrencyCode = selectedCurrency!=="-"? selectedCurrency.split(" - ")[0]:"";
			setCurrency(
				isoCurrencyCode
			);
		}
	}

	return (
		<div className="page-container">
			<div className="page-information-container">
				<div className="page-title">Country Comparison</div>
				<div className="info-divider" />
				<div className="info-description">
					Select an industry and project investment amount, and see
					how the total economic impact of the project (measured by
					contribution to GVA) varies across countries and UK regions.
				</div>
			</div>

			<div className="country-comp-main-container">
				<div className="enter-inv-data-label">
					Enter the data of your investment:
				</div>
				<div className="fields-container">
					<FormControl
						className="input-fields"
						size="small"
						id="id-field-industries"
					>
						<Autocomplete
							style={{
								backgroundColor: "white !important",
							}}
							size="small"
							disablePortal
							id="industry_select"
							options={industriesOptions}
							disableClearable
							value={Industries[industry]}
							name="industry_select"
							label="Industry"
							sx={{
								"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
									{
										backgroundColor: "#fcee21 !important",
									},
								"& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
									{
										backgroundColor: "#fcee21 !important",
									},
							}}
							PaperComponent={({ children }) => (
								<Paper
									sx={{
										"& li": menuItemsStyle,
									}}
								>
									{children}
								</Paper>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									sx={{
										backgroundColor: "white !important",
									}}
									labelId="industry-select-label"
									id="industry-simple-select"
									value={Industries[industry]}
									label="Industry"
								/>
							)}
							onClose={(e) => {
								handleChange(e, "industry");
							}}
						/>
					</FormControl>
					<FormControl
						className="input-fields"
						size="small"
						id="id-field-invamount"
					>
						<NumericFormat
							id="inv_amount"
							label="Investment Amount"
							variant="outlined"
							name="inv_amount"
							value={inv_amount || ""}
							onChange={(e) => {
								handleChange(e, "inv_amount");
							}}
							size="small"
							thousandSeparator={true}
							prefix={Currencies[currency] + " "}
							inputmode="numeric"
							allowNegative={false}
							customInput={TextField}
							isAllowed={withValueCap}
						/>
					</FormControl>
					<FormControl
						className="currency-form"
						style={{ marginLeft: "auto" }}
						size="small"
					>
						<Autocomplete
							className="currency-input"
							size="small"
							disablePortal
							options={currenciesOptions}
							disableClearable
							value={currency + " - " + Currencies[currency]}
							renderInput={(params) => (
								<TextField
									{...params}
									sx={{
										backgroundColor: "white !important",
									}}
									labelId="currency-select-label"
									id="currency-simple-select"
									value={currency}
									label="Currency"
								/>
							)}
							onClose={(e) => {
								const selectedCurrency = e.target.innerText;
								updateSelectedCurrency(selectedCurrency);
							}}

							onKeyDown={(e) => {
								if(e.code!=="Enter") return;
								const selectedCurrency = document.querySelector(".Mui-focusVisible").innerText;
								updateSelectedCurrency(selectedCurrency);
							}}
						/>
					</FormControl>
				</div>

				<div className="maps-container">
					<div className="uk-map-container">
						<ComposableMap
							id="uk-map"
							projection="geoMercator"
							projectionConfig={{
								scale: 3800,
								rotate: [3, -55, -10],
								center: [0, 0],
								// scale: 130,
								// rotate: [-10, 0, 0],
							}}
							width={ukMapWidth}
						>
							<Geographies geography={UkJson}>
								{({ geographies }) =>
									geographies.map((geo) => {
										// const cur = data.find(
										// 	(s) => s.id === geo.id
										// );
										return (
											<Tooltip
												title={toolTipTitleRegion(
													geo.properties.AREANM
												)}
												followCursor={true}
												arrow
											>
												<Geography
													key={geo.rsmKey}
													geography={geo}
													stroke="#f4f4f0"
													strokeWidth={0.2} // fill={
													// 	Object.keys(Regions).find(
													// 		(key) =>
													// 			Regions[key] ===
													// 			geo.properties.AREANM
													// 	) === region
													// 		? "#768293"
													// 		: "#FFF"
													// }
													fill={
														industry !== "" &&
														inv_amount !== ""
															? dataset[
																	Object.keys(
																		Regions
																	).find(
																		(key) =>
																			Regions[
																				key
																			] ===
																			geo
																				.properties
																				.AREANM
																	)
															  ].fillColor
															: "#768293"
													}
													style={{
														default: {
															outline: "none",
														},
														hover: {
															outline: "none",
														},
														pressed: {
															outline: "none",
														},
													}}
													// onClick={() => {
													// 	setRegion(
													// 		Object.keys(Regions).find(
													// 			(key) =>
													// 				Regions[key] ===
													// 				geo.properties.AREANM
													// 		)
													// 	);
													// 	createEconomicModel();
													// }}
												/>
											</Tooltip>
										);
									})
								}
							</Geographies>
						</ComposableMap>
						<div
							className="map-tools-container"
							style={{
								visibility:
									Object.keys(dataset).length === 0
										? "hidden"
										: "visible",
							}}
						>
							<div className="color-scale-container">
								<div id="color-scale" />
								<div id="minimum-value">
									{Currencies[currency] +
										" " +
										(Math.floor(
											minValueRegions *
												parseFloat(
													inv_amount.replace(
														/[^0-9.]/g,
														""
													)
												)
										).toString().length < 4
											? (
													minValueRegions *
													parseFloat(
														inv_amount.replace(
															/[^0-9.]/g,
															""
														)
													)
											  ).toLocaleString(undefined, {
													maximumFractionDigits: 0,
											  })
											: Math.floor(
													minValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 6
											? Math.floor(
													(minValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100
											  ) /
													10 +
											  " K"
											: Math.floor(
													minValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 9
											? Math.floor(
													(minValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000
											  ) /
													10 +
											  " M"
											: Math.floor(
													(minValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000000
											  ) /
													10 +
											  " B")}
								</div>
								<div id="maximum-value">
									{Currencies[currency] +
										" " +
										(Math.ceil(
											maxValueRegions *
												parseFloat(
													inv_amount.replace(
														/[^0-9.]/g,
														""
													)
												)
										).toString().length < 4
											? (
													maxValueRegions *
													parseFloat(
														inv_amount.replace(
															/[^0-9.]/g,
															""
														)
													)
											  ).toLocaleString(undefined, {
													maximumFractionDigits: 0,
											  })
											: Math.ceil(
													maxValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 6
											? Math.ceil(
													(maxValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100
											  ) /
													10 +
											  " K"
											: Math.ceil(
													maxValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 9
											? Math.ceil(
													(maxValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000
											  ) /
													10 +
											  " M"
											: Math.ceil(
													(maxValueRegions *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000000
											  ) /
													10 +
											  " B")}
								</div>
							</div>
							<div
								className="export-map-button"
								onClick={(e) => handleClick(e, "uk")}
							>
								<img alt="Export Map" src={ExportIcon} />
							</div>
						</div>
						<Popover
							id={idUk}
							open={openUk}
							anchorEl={anchorElUk}
							onClose={() => handleClose("uk")}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
						>
							<MenuList>
								<MenuItem
									disabled={
										inv_amount && industry ? false : true
									}
								>
									<Typography
										variant="body2"
										onClick={(e) => {
											handleDownloadImage(e, "uk");
											handleClose("uk");
										}}
									>
										Download image as PNG
									</Typography>
								</MenuItem>
								<MenuItem
									disabled={
										inv_amount && industry ? false : true
									}
								>
									<Typography
										variant="body2"
										onClick={(e) => {
											exportExcel(e, "uk");
											handleClose("uk");
										}}
									>
										Export image data as CSV
									</Typography>
								</MenuItem>
							</MenuList>
						</Popover>
					</div>
					<div className="world-map-container">
						<ComposableMap
							id="world-map"
							projection="geoMercator"
							projectionConfig={{
								scale: worldScale,
								rotate: [-10, 0, 0],
								center: [-7, 45],
							}}
						>
							<Geographies geography={WorldMap}>
								{({ geographies }) =>
									geographies.map((geo) => {
										// const cur = data.find(
										// 	(s) => s.id === geo.id
										// );
										return (
											<Tooltip
												title={toolTipTitleCountry(
													geo.id,
													geo.properties.name
												)}
												followCursor={true}
												arrow
											>
												<Geography
													key={geo.rsmKey}
													geography={geo}
													stroke="#f4f4f0"
													strokeWidth={0.2}
													// fill={
													// 	Object.keys(Regions).find(
													// 		(key) =>
													// 			Regions[key] ===
													// 			geo.properties.AREANM
													// 	) === region
													// 		? "#768293"
													// 		: "#FFF"
													// }
													fill={
														industry !== "" &&
														inv_amount !== ""
															? typeof dataset[
																	geo.id
															  ] !== "undefined"
																? dataset[
																		geo.id
																  ].fillColor
																: "#768293"
															: "#768293"
													}
													style={{
														default: {
															outline: "none",
														},
														hover: {
															// cursor: "pointer",
															outline: "none",
														},
														pressed: {
															outline: "none",
														},
													}}
													// onClick={() => {
													// 	console.log(geo.properties.name);
													// }}
												/>
											</Tooltip>
										);
									})
								}
							</Geographies>
						</ComposableMap>
						<div
							className="map-tools-container"
							style={{
								visibility:
									Object.keys(dataset).length === 0
										? "hidden"
										: "visible",
							}}
						>
							<div className="color-scale-container">
								<div id="color-scale" />
								<div id="minimum-value">
									{Currencies[currency] +
										" " +
										(Math.floor(
											minValueWorld *
												parseFloat(
													inv_amount.replace(
														/[^0-9.]/g,
														""
													)
												)
										).toString().length < 4
											? (
													minValueWorld *
													parseFloat(
														inv_amount.replace(
															/[^0-9.]/g,
															""
														)
													)
											  ).toLocaleString(undefined, {
													maximumFractionDigits: 0,
											  })
											: Math.floor(
													minValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 6
											? Math.floor(
													(minValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100
											  ) /
													10 +
											  " K"
											: Math.floor(
													minValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 9
											? Math.floor(
													(minValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000
											  ) /
													10 +
											  " M"
											: Math.floor(
													(minValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000000
											  ) /
													10 +
											  " B")}
								</div>
								<div id="maximum-value">
									{Currencies[currency] +
										" " +
										(Math.ceil(
											maxValueWorld *
												parseFloat(
													inv_amount.replace(
														/[^0-9.]/g,
														""
													)
												)
										).toString().length < 4
											? (
													maxValueWorld *
													parseFloat(
														inv_amount.replace(
															/[^0-9.]/g,
															""
														)
													)
											  ).toLocaleString(undefined, {
													maximumFractionDigits: 0,
											  })
											: Math.ceil(
													maxValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 6
											? Math.ceil(
													(maxValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100
											  ) /
													10 +
											  " K"
											: Math.ceil(
													maxValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)
											  ).toString().length <= 9
											? Math.ceil(
													(maxValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000
											  ) /
													10 +
											  " M"
											: Math.ceil(
													(maxValueWorld *
														parseFloat(
															inv_amount.replace(
																/[^0-9.]/g,
																""
															)
														)) /
														100000000
											  ) /
													10 +
											  " B")}
								</div>{" "}
							</div>

							<div
								className="export-map-button"
								onClick={(e) => handleClick(e, "world")}
							>
								<img alt="Export Map" src={ExportIcon} />
							</div>
						</div>

						<Popover
							id={idWorld}
							open={openWorld}
							anchorEl={anchorElWorld}
							onClose={() => handleClose("world")}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
						>
							<MenuList>
								<MenuItem
									disabled={
										inv_amount && industry ? false : true
									}
								>
									<Typography
										variant="body2"
										onClick={(e) => {
											handleDownloadImage(e, "world");
											handleClose("uk");
										}}
									>
										Download image as PNG
									</Typography>
								</MenuItem>
								<MenuItem
									disabled={
										inv_amount && industry ? false : true
									}
								>
									<Typography
										variant="body2"
										onClick={(e) => {
											exportExcel(e, "world");
											handleClose("uk");
										}}
									>
										Export image data as CSV
									</Typography>
								</MenuItem>
							</MenuList>
						</Popover>
					</div>
				</div>
			</div>
		</div>
	);
}
export default CountryComp;
