import React from "react";
import ImpactEstimatorLogo from "../images/logos/impact_estimator_wide.svg";
import AlmaEconomicsLogo from "../images/logos/alma_economics.svg";
import "../css/Footer.css";

function Header() {
	const copyrightYear = new Date().getFullYear();
	return (
		<div>
			<footer className="footer-container">
				<div>
					<div className="footer-left-section">
						<a href="/">
							<img
								alt="Impact Estimator Logo"
								src={ImpactEstimatorLogo}
							/>
						</a>
					</div>
					<div className="footer-middle-section">
						<span>
							Copyright © {copyrightYear} All rights reserved <br />
							Impact Estimator is an Alma Economics product <br />
						</span>
						<span>
							<span>
								<b>
									Let's connect:
									<br />
								</b>
							</span>
							<div id="socials-container">
								<a
									href="mailto:info@almaeconomics.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									Email
								</a>
								<a
									href="https://www.almaeconomics.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									Website
								</a>
								<a
									href="https://www.linkedin.com/company/alma-economics/"
									target="_blank"
									rel="noopener noreferrer"
								>
									LinkedIn
								</a>
								<a
									href="https://twitter.com/almaeconomics"
									target="_blank"
									rel="noopener noreferrer"
								>
									Twitter
								</a>
								<a
									href="https://www.facebook.com/people/Alma-Economics/100083371470933/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Facebook
								</a>
								<a
									href="https://www.instagram.com/alma_economics/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Instagram
								</a>
								<br />
							</div>
						</span>
					</div>
					<div className="footer-right-section">
						<a href="https://www.almaeconomics.com/">
							<img
								src={AlmaEconomicsLogo}
								alt="Alma Economics Logo"
							/>
						</a>
					</div>
				</div>
			</footer>
			<footer className="footer-container-mobile">
				<div className="footer-logo-section">
					<div className="footer-left-section">
						<a href="/">
							<img
								alt="Impact Estimator Logo"
								src={ImpactEstimatorLogo}
							/>
						</a>
					</div>

					<div className="footer-right-section">
						<a href="https://www.almaeconomics.com/">
							<img
								src={AlmaEconomicsLogo}
								alt="Alma Economics Logo"
							/>
						</a>
					</div>
				</div>
				<div className="footer-middle-section">
					<span>
						Copyright © 2023 All rights reserved <br />
						Impact Estimator is an Alma Economics product <br />
					</span>
					<span>
						<span>
							<b>
								Let's connect:
								<br />
							</b>
						</span>
						<div id="socials-container">
							<a
								href="mailto:info@almaeconomics.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Email
							</a>
							<a
								href="https://www.almaeconomics.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Website
							</a>
							<a
								href="https://www.linkedin.com/company/alma-economics/"
								target="_blank"
								rel="noopener noreferrer"
							>
								LinkedIn
							</a>
							<a
								href="https://twitter.com/almaeconomics"
								target="_blank"
								rel="noopener noreferrer"
							>
								Twitter
							</a>
							<a
								href="https://www.facebook.com/people/Alma-Economics/100083371470933/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Facebook
							</a>
							<a
								href="https://www.instagram.com/alma_economics/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Instagram
							</a>
							<br />
						</div>{" "}
					</span>
				</div>
			</footer>
		</div>
	);
}

export default Header;
